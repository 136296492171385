export enum MeasureDataSourceTitle {
  mintti = 'mintti',
  manual = 'manual',
  actimiApp = 'actimi-app',
  googleFit = 'google-fit',
  viatom = 'viatom',
  beurer = 'Beurer',
  stethoscope = 'stethoscope',
  mood = 'mood',
  gratitude = 'gratitude',
}

export enum MeasureDataSourceId {
  ACTIMI_MANUAL = -1,
  ACTIMI_MINNTI_VISION = -2,
  ACTIMI_MINTTI_VIATOM = -3,
  ACTIMI_MINTTI_STETHOSCOPE = -4,
  ACTIMI_GOOGLE_FIT_CLOUD = -5,
  TAIDOC_BLOOD_GLUCOSE_METER_TD4216 = -6,
  SHENZEN_PPL_SCALE = -7,
  BEURER_PO_60 = -8,
  BEURER_BM_96 = -9,
  BEURER_BM_77 = -10,
  BEURER_BF_720_SCALE = -11,
  FITBIT = 1,
  GARMIN = 2,
  POLAR = 3,
  APPLE = 5,
  SAMSUNG = 6,
  MISFIT = 7,
  WITHINGS = 8,
  THRYVE_CONNECTOR = 9,
  THRYVE_WEARABLE_CONNECTOR = 10,
  STRAVA = 11,
  GOOGLE_FIT_REST = 12,
  OMRON_CONNECT = 16,
  SUUNTO = 17,
  OURA = 18,
  IHEALTH = 21,
  SLEEP_AS_ANDROID = 24,
  HUAWEI_HEALTH = 38,
  AMAZFIT = 39,
  GOOGLE_FIT_NATIVE = 40,
  MULTIPLE = 1000,
  CUSTOMER_APP = 10001,
}

export enum MeasureResourceTitle {
  manual = 'Manual Measurement',
  mintti = 'Mintti Health',
  actimiApp = 'Actimi App',
  googleFit = 'Google Fit',
  viatom = 'Viatom',
  stethoscope = 'Stethoscope',
  mood = 'Mood',
  gratitude = 'Gratitude',
  bloodGlucoseMeter = 'Blood Glucose Meter',
  shenzhenPpScale = 'Shenzhen PP Scale',
  beurerPo60 = 'Beurer PO-60 ',
  beurerBm77 = 'Beurer BM-77 ',
  beurerBm96 = 'Beurer BM-96 ',
  beurerBf720Scale = "Beurer BF-720 Scale"
}

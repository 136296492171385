import React from 'react';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import { Field } from 'formik';
import type { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/Base/TextInput';
import { Calendar } from 'iconsax-react';

interface Props {
  name: string;
  label: string;
  required?: boolean;
}

export const Date: React.FC<Props> = ({ name, label, required }) => {
  const { t } = useTranslation();
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <Field name={name}>
        {({
          field, // { name, value, onChange, onBlur }
          form, // */: { touched, errors, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
          meta
        }) => (
          <DatePicker
            disableFuture
            label={label}
            openTo="year"
            components={{ OpenPickerIcon: Calendar }}
            OpenPickerButtonProps={{ style: { color: '#8B92AB' } }}
            views={['year', 'month', 'day']}
            {...field}
            onChange={(m: Moment) => {
              let newVal = '';
              if (m?.isValid()) newVal = m?.format('YYYY-MM-DD') ?? '';
              form.setFieldValue(name, newVal);
            }}
            renderInput={(params) => (
              <TextInput
                {...field}
                {...params}
                size="small"
                error={meta.touched && !!meta.error}
                validationInput
                value={field.value}
                helperText={
                  meta.touched && !!meta.error
                    ? t(meta.error)
                    : required
                    ? 'Required *'
                    : ' '
                }
                rightIcon={<Calendar />}
              />
            )}
          />
        )}
      </Field>
    </LocalizationProvider>
  );
};

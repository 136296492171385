import { useContext } from 'react';

import {
  Box,
  IconButton,
  Tooltip,
  styled,
  Typography
} from '@material-ui/core';
import MenuTwoToneIcon from '@material-ui/icons/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';

// import HeaderMenu from './Menu';
import HeaderUserbox from './Userbox';
import useAuth from 'src/hooks/useAuth';
import JustCardioTextLogo from 'src/components/JustCardioTextLogo';
import { useSelector } from 'src/store';
import axiosAidboxInt from 'src/utils/axios';
import { InvitePatientModal } from 'src/content/dashboards/PatientDetails/PatientsTable';
import React from 'react';
import {
  ErrorMessage,
  SuccessMessage
} from 'src/content/dashboards/Database/utils';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { PatientService } from '@actimi/core-migration';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        top: 0;
        z-index: 10;
        background-color: ${theme.header.background};
        border-bottom: 1px solid #DCDFEA;
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.md}px) {
            left: 0;
            width: auto;
        }
`
);

const TopSection = styled(Box)(
  ({ theme }) => `
        display: flex;
        height: 88px;,
        align-items: center;
        border-bottom: ${theme.sidebar.dividerBg} solid 1px;
        margin-top:10%;
`
);

const RightContentWrapper = styled(Box)(
  ({ theme }) => `
        display: flex;
        flexDirection:row;
        align-items: center;
        justify-content:center
       
`
);

function Header() {
  const auth = useAuth();
  const { checkEmail } = new PatientService();
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const { practitionerInfo } = useSelector((state) => state.clinic);
  const [open, setOpen] = React.useState(false);
  const [isMounted, setIsMounted] = React.useState<boolean>(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const { t } = useTranslation();
  const practitioner = useSelector((state) => state.clinic.practitionerInfo);
  const currentLanguage = window.localStorage.getItem('i18nextLng');
  const handleSendInvitation = async (
    email: string,
    patientName: string,
    patientSurname: string
  ) => {
    setIsMounted(true);
    try {
      const isValidEmail = await checkEmail(email);
      if (isValidEmail) {
        await axios.post(
          'https://staging.multi.aidbox.actimi.health/user-account/send-registration-token-to-patient',

          {
            receiverEmail: email,
            practitionerId: practitioner.id,
            organizationId: auth.user.clinic.id,
            extra: {
              name: patientName,
              surname: patientSurname
            }
          },
          {
            headers: {
              product: 'mifysio',
              language: currentLanguage
            }
          }
        );
        setIsMounted(false);
        handleClose();
        SuccessMessage(`Email sent to ${email} for verification`, 'top-center');
      } else {
        setIsMounted(false);
        handleClose();
        ErrorMessage(
          'Email is already in use, please check again.',
          'top-center'
        );
      }
    } catch (error) {
      setIsMounted(false);
      ErrorMessage(
        'An error occured while sending verification email',
        'top-center'
      );
    }
  };

  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Box mt={1.5} display="flex" alignItems="center">
        <Box component="span">
          <TopSection>
            <JustCardioTextLogo />
          </TopSection>
        </Box>
        {auth.user.clinic && (
          <Box
            display="flex"
            alignItems={'flex-start'}
            flexDirection={'column'}
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'flex'
              }
            }}
          >
            <Typography
              sx={{
                color: '#232F48',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 14
              }}
              variant="h4"
            >
              {practitionerInfo?.clinic?.name}
            </Typography>
            <Typography variant="subtitle1">
              Clinic ID: {auth.user.clinic.identifier[0].value}
            </Typography>
          </Box>
        )}
        {/* <Box
          component="span"
          sx={{
            display: { xs: 'none', md: 'inline-block' }
          }}
        >
          <HeaderMenu />
        </Box> */}
      </Box>
      <RightContentWrapper>
        <Button
          startIcon={<AddCircleOutlineOutlinedIcon fontSize="medium" />}
          variant="contained"
          color="primary"
          onClick={handleOpen}
          style={{
            marginRight: 12,
            color: '#FFF'
          }}
        >
          {t('Invite New Patient')}
        </Button>
        <InvitePatientModal
          open={open}
          handleClose={handleClose}
          handleSubmit={handleSendInvitation}
          isMounted={isMounted}
        />
        <Box display="flex" alignItems="center">
          {/* <HeaderButtons /> */}
          <HeaderUserbox />
          <Box
            component="span"
            sx={{
              display: { md: 'none', xs: 'inline-block' }
            }}
          >
            <Tooltip arrow title="Toggle Menu">
              <IconButton color="primary" onClick={toggleSidebar}>
                {!sidebarToggle ? <MenuTwoToneIcon /> : <CloseTwoToneIcon />}
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </RightContentWrapper>
    </HeaderWrapper>
  );
}

export default Header;

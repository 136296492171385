import { Patient } from '../models/patient/Patient';
import { CommunicationRequest } from '../slices/communication-request';
import { socket } from './socket';
import { ITableItem } from 'src/models/dashboard-table';
setInterval(() => {
  if (!socket.connected) {
    socket.connect();
  }
}, 5000);

const startListeningCommunicationRequest = (
  organizationId: string,
  onCommunicationRequestEvent: (
    communicationRequest: CommunicationRequest
  ) => void
) => {
  const isListeningCommunicationRequest = socket.hasListeners(
    `communication-request-${organizationId}`
  );
  if (!isListeningCommunicationRequest) {
    socket.on(`communication-request-${organizationId}`, (ev) => {
      onCommunicationRequestEvent(ev);
    });
  }
};

const startListening = (
  userIds: string[],
  onPatientRiskScoreEvent: (obs: ITableItem) => void,
  onPatientEvent: (patient: Patient) => void
) => {
  for (const userId of userIds) {
    const isListeningPatientRiskScore = socket.hasListeners(
      `patient-risk-score-${userId}`
    );
    const isListeningPatient = socket.hasListeners(`patient-${userId}`);

    if (!socket.connected) {
      socket.connect();
    }
    if (!isListeningPatientRiskScore) {
      socket.on(`patient-risk-score-${userId}`, (ev) => {
        onPatientRiskScoreEvent(ev);
      });
    }
    if (!isListeningPatient) {
      socket.on(`patient-${userId}`, (ev) => {
        onPatientEvent(ev);
      });
    }
  }
  const isListeningNewPatientRiskScore = socket.hasListeners(
    `patient-risk-score-new`
  );
  if (!isListeningNewPatientRiskScore) {
    socket.on(`patient-risk-score-new`, (ev) => {
      onPatientRiskScoreEvent(ev);
    });
  }
};

const removeListeners = (userIds: string[]) => {
  userIds.forEach((userId) => {
    socket.off(`patient-risk-score-${userId}`);
    socket.off(`patient-${userId}`);
  });
};

const cleanUp = () => {
  socket.removeAllListeners();
};

export const UpdateSubscriptionSocket = {
  startListening,
  removeListeners,
  startListeningCommunicationRequest,
  cleanUp
};

import { combineReducers } from '@reduxjs/toolkit';
import {
  reducer as calendarReducer,
  reset as resetCalendar
} from 'src/slices/calendar';
import {
  reducer as measurementReducer,
  reset as resetMeasurement
} from 'src/slices/measurement';
import {
  reducer as careteamReducer,
  reset as resetCareteam
} from 'src/slices/careteam';
import {
  reducer as mainDashboardReducer,
  reset as resetMainDashboard
} from 'src/slices/main-dashboard';
import {
  reducer as analyticsReducer,
  reset as resetAnalytics
} from 'src/slices/analytics';
import {
  reducer as userNotifications,
  reset as resetUserNotifications
} from '../slices/user-notifications';
import {
  reducer as limitValuesReducer,
  reset as resetLimitValues
} from '../slices/limit-values';
import {
  reducer as communicationRequestReducer,
  reset as resetCommunicationRequest
} from '../slices/communication-request';
import {
  reducer as patientsProfileReducer,
  reset as resetPatientsProfile
} from '../slices/patients-profile';
import {
  reducer as patientsDetailReducer,
  reset as resetPatientsDetail
} from '../slices/patients-detail';
import {
  reducer as logHistoryReducer,
  reset as resetLogHistory
} from '../slices/log-history';
import {
  reducer as clinicReducer,
  reset as resetClinic
} from '../slices/clinic';

import { reducer as chartReducer, reset as resetChart } from '../slices/chart';

import { reducer as exerciseReducer } from '../slices/exercise';

import { chatApi } from '../slices/chat-api';

const rootReducer = combineReducers({
  chart: chartReducer,
  exercise: exerciseReducer,
  calendar: calendarReducer,
  measurement: measurementReducer,
  careteam: careteamReducer,
  mainDashboard: mainDashboardReducer,
  analytics: analyticsReducer,
  userNotifications: userNotifications,
  limitValues: limitValuesReducer,
  communicationRequest: communicationRequestReducer,
  patientsProfile: patientsProfileReducer,
  clinic: clinicReducer,
  logHistory: logHistoryReducer,
  patientsDetail: patientsDetailReducer,
  [chatApi.reducerPath]: chatApi.reducer
});

//TODO: Refactor maybe?
export const resetActions = [
  resetCalendar,
  resetMeasurement,
  resetCareteam,
  resetChart,
  resetMainDashboard,
  resetAnalytics,
  resetUserNotifications,
  resetLimitValues,
  resetCommunicationRequest,
  resetPatientsProfile,
  resetClinic,
  resetPatientsDetail,
  resetLogHistory
];

export default rootReducer;

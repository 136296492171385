export enum MeasureUnit {
  imperialSystem = "Imperial System",
  metricSystem = "Metric System",
}
export enum ImperialSystem {
  weight = "lb",
  height = "inch",
  temperature = "°F",
  distance = "m",
  bloodSugar = "mmol/L",
}
export enum MetricSystem {
  weight = "Kg",
  height = "Cm",
  temperature = "°C",
  distance = "",
  bloodSugar = "mg/dL",
}

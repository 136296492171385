import React from 'react';
import { Box } from '@mui/material';
import { experimentalStyled, Typography } from '@material-ui/core';
import moment from 'moment';

interface TableUserBoxProps {
  readonly photo?: string;
  readonly name?: string;
  readonly gender?: string;
  readonly birthdate?: string;
  readonly onClick?: () => void;
}

const TableUserBox: React.FC<TableUserBoxProps> = ({
  photo,
  name,
  gender,
  birthdate,
  onClick
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        cursor: 'pointer'
      }}
      onClick={onClick}
    >
      <Box display="flex" flexDirection="column">
        <H4Header sx={{ ml: 1 }}>{name}</H4Header>
        <P1Text sx={{ ml: 1, color: '#8B92AB' }}>{`${
          gender?.charAt(0).toUpperCase() + gender?.slice(1) ?? 'Male'
        }, ${birthdate ? moment().diff(birthdate, 'years') : '27'} yo`}</P1Text>
      </Box>
    </Box>
  );
};

export default TableUserBox;

const P1Text = experimentalStyled(Typography)(
  ({ theme }) => `
    display: flex; 
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  `
);

export const H4Header = experimentalStyled(Typography)(
  ({ theme }) => `
    display: flex; 
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color:#232F48;

    `
);

import { TimeSpan } from '../models/observation/enums/TimeSpan';
import { MeasurementTypeKey } from '../models/measure/MeasurementType';
import { MiddlewareService } from '../services/middleware/MiddlewareService';
import { ObservationCode } from '../models/observation/enums/ObservationCode';
import {
  IGetGraphicsInformationResponse,
  IGraphicInformation,
  MultipleGraphDataResponse,
  SingleGraphDataResponse
} from '../models/graphic/IGraphic';
import { IGraphicWaveValues } from '../models/graphic/IGraphicWaveValues';
import {
  ILatestMeasures,
  ILatestMeasureWithCodeInfo
} from '../models/graphic/ILatestMeasures';
import { IBloodPressureCode } from '../models/graphic/IBloodPressureGraphic';
import { getObservationCodeWithMeasureTypeKey } from './Measure';
import { formatGraphicData } from './GraphicData';
import { QuestionnaireTypes } from 'src/models/symptom-questionnaire/QuestionnaireTypes';
import { IQuestionnaireResponse } from 'src/models/symptom-questionnaire/IQuestionnaireResponse';
import _ from 'lodash';

export class MeasureService {
  public static async getAllGraphicData(
    selectedTimeSpan: TimeSpan,
    selectedDateTime: string,
    _createdAt?: string,
    userId?: string
  ): Promise<
    SingleGraphDataResponse | MultipleGraphDataResponse | IGraphicInformation[]
  > {
    const fetchedGraphicData: IGraphicInformation[] =
      await MiddlewareService.getAllGraphicInformation([
        {
          code: ObservationCode.SYSTOLIC_BLOOD_PRESSURE,
          timespan: selectedTimeSpan,
          subject: `Patient/${userId}`,
          _createdAt
        },
        {
          code: ObservationCode.DIASTOLIC_BLOOD_PRESSURE,
          timespan: selectedTimeSpan,
          subject: `Patient/${userId}`,
          _createdAt
        },
        {
          code: ObservationCode.HEART_RATE,
          timespan: selectedTimeSpan,
          subject: `Patient/${userId}`,
          _createdAt
        },
        {
          code: ObservationCode.OXYGEN_SATURATION,
          timespan: selectedTimeSpan,
          subject: `Patient/${userId}`,
          _createdAt
        },
        {
          code: ObservationCode.BODY_WEIGHT,
          timespan: selectedTimeSpan,
          subject: `Patient/${userId}`,
          _createdAt
        },
        {
          code: ObservationCode.SYMPTOM,
          timespan: selectedTimeSpan,
          subject: `Patient/${userId}`,
          _createdAt
        },
        {
          code: ObservationCode.ECG,
          timespan: selectedTimeSpan,
          subject: `Patient/${userId}`,
          _createdAt
        }
      ]);

    return [
      formatGraphicData(
        fetchedGraphicData?.[0],
        selectedTimeSpan,
        selectedDateTime
      ),
      formatGraphicData(
        fetchedGraphicData?.[1],
        selectedTimeSpan,
        selectedDateTime
      ),
      formatGraphicData(
        fetchedGraphicData?.[2],
        selectedTimeSpan,
        selectedDateTime
      ),
      formatGraphicData(
        fetchedGraphicData?.[3],
        selectedTimeSpan,
        selectedDateTime
      ),
      formatGraphicData(
        fetchedGraphicData?.[4],
        selectedTimeSpan,
        selectedDateTime
      ),
      formatGraphicData(
        fetchedGraphicData?.[5],
        selectedTimeSpan,
        selectedDateTime
      ),
      formatGraphicData(
        fetchedGraphicData?.[6],
        selectedTimeSpan,
        selectedDateTime
      )
    ];
  }

  public static getGraphicInformationLatestMeasurements(
    data: IGetGraphicsInformationResponse
  ): ILatestMeasures {
    return {
      bodyTemperature: {
        bodyTemperature: Number(
          data.temperatureGraphicData.latestMeasuredValue
        ),
        lastMeasuredTime: data.temperatureGraphicData.latestMeasuredDateTime,
        device: data.temperatureGraphicData.latestMeasuredDevice,
        note: data.temperatureGraphicData.latestMeasuredNote,
        riskScore: undefined
      },
      heartRate: {
        heartRate: Number(data.ecgHeartRateGraphicData.latestMeasuredValue),
        lastMeasuredTime: data.ecgHeartRateGraphicData.latestMeasuredDateTime,
        device: data.ecgHeartRateGraphicData.latestMeasuredDevice,
        note: data.ecgHeartRateGraphicData.latestMeasuredNote,
        riskScore: undefined
      },
      bodyWeight: {
        bodyWeight: Number(data.bodyWeightGraphicData.latestMeasuredValue),
        lastMeasuredTime: data.bodyWeightGraphicData.latestMeasuredDateTime,
        note: data.bodyWeightGraphicData.latestMeasuredNote,
        device: data.bodyWeightGraphicData.latestMeasuredDevice,
        riskScore: undefined
      },
      bloodGlucose: {
        bloodGlucose: Number(data.bloodGlucoseGraphicData.latestMeasuredValue),
        lastMeasuredTime: data.bloodGlucoseGraphicData.latestMeasuredDateTime,
        note: data.bloodGlucoseGraphicData.latestMeasuredNote,
        device: data.bloodGlucoseGraphicData.latestMeasuredDevice,
        riskScore: undefined
      },
      bodyHeight: {
        bodyHeight: Number(data.bodyHeightGraphicData.latestMeasuredValue),
        lastMeasuredTime: data.bodyHeightGraphicData.latestMeasuredDateTime,
        note: data.bodyHeightGraphicData.latestMeasuredNote,
        device: data.bodyHeightGraphicData.latestMeasuredDevice
      },
      // symptoms: {
      //   symptoms: Number(data.bodyHeightGraphicData.latestMeasuredValue),
      //   lastMeasuredTime: data.bodyHeightGraphicData.latestMeasuredDateTime,
      //   note: data.bodyHeightGraphicData.latestMeasuredNote,
      //   device: data.bodyHeightGraphicData.latestMeasuredDevice
      // },
      bloodPressure: {
        heartRate:
          data.bloodPressureGraphicData !== undefined
            ? Number(data.bloodPressureGraphicData[2].latestMeasuredValue)
            : 0,
        systolic:
          data.bloodPressureGraphicData !== undefined
            ? Number(data.bloodPressureGraphicData[0].latestMeasuredValue)
            : 0,
        diastolic:
          data.bloodPressureGraphicData !== undefined
            ? Number(data.bloodPressureGraphicData[0].latestMeasuredValue)
            : 0,
        note: data.bloodPressureGraphicData[0].latestMeasuredNote,
        device: data.bloodPressureGraphicData[0].latestMeasuredDevice,
        lastMeasuredTime:
          data.bloodPressureGraphicData[0].latestMeasuredDateTime,
        riskScore: undefined
      },
      spO2: {
        spo2: Number(data.bloodOxygenGraphicData.latestMeasuredValue),
        lastMeasuredTime: data.bloodOxygenGraphicData.latestMeasuredDateTime,
        note: data.bloodOxygenGraphicData.latestMeasuredNote,
        device: data.bloodOxygenGraphicData.latestMeasuredDevice,
        riskScore: undefined
      },
      // mood: {
      //   mood:
      //     data.moodGraphicData !== undefined
      //       ? data.moodGraphicData.latestMeasuredValue !== null
      //         ? data.moodGraphicData.latestMeasuredValue.toString()
      //         : ''
      //       : '',
      //   lastMeasuredTime:
      //     data.moodGraphicData !== undefined
      //       ? data.moodGraphicData.latestMeasuredDateTime
      //       : null,
      //   feelings: undefined,
      //   activities: undefined,
      //   note: data.moodGraphicData?.latestMeasuredNote,
      //   device: data.moodGraphicData?.latestMeasuredDevice
      // },
      ecg: {
        heartRate:
          data.ecgHeartRateGraphicData !== undefined
            ? Number(data.ecgHeartRateGraphicData.latestMeasuredValue)
            : 0,
        hrv:
          data.hrvGraphicData !== undefined
            ? Number(data.hrvGraphicData.latestMeasuredValue)
            : 0,
        lastMeasuredTime:
          data.hrvGraphicData !== undefined
            ? data.hrvGraphicData.latestMeasuredDateTime
            : null,
        rrMax: 0,
        rrMin: 0,
        breathRate: 0,
        mood: 0,
        riskScore: undefined,
        note: data.ecgHeartRateGraphicData.latestMeasuredNote,
        device: data.ecgHeartRateGraphicData.latestMeasuredDevice
      }
    };
  }

  public static async getLatestMeasuredWithMeasureKey(
    measurementTypeKey: MeasurementTypeKey
  ): Promise<ILatestMeasureWithCodeInfo | undefined> {
    const observationCode =
      getObservationCodeWithMeasureTypeKey(measurementTypeKey);
    if (measurementTypeKey === MeasurementTypeKey.bloodPressure) {
      const sysData = await MiddlewareService.getLatestMeasurementWithCode(
        // must be fixed
        'UserState.currentUser.id',
        (observationCode as IBloodPressureCode).sys
      );
      const diasData = await MiddlewareService.getLatestMeasurementWithCode(
        // must be fixed
        'UserState.currentUser.id',
        (observationCode as IBloodPressureCode).dias
      );
      const bpHeartRateData =
        await MiddlewareService.getLatestMeasurementWithCode(
          // must be fixed
          'UserState.currentUser.id',
          (observationCode as IBloodPressureCode).heartRate
        );
      if (
        sysData !== undefined &&
        diasData !== undefined &&
        bpHeartRateData !== undefined
      ) {
        return {
          lastMeasuredValue: {
            sys:
              sysData.lastMeasuredValue === null
                ? 0
                : sysData.lastMeasuredValue,
            heartRate:
              bpHeartRateData.lastMeasuredValue === null
                ? 0
                : bpHeartRateData.lastMeasuredValue,
            dias:
              diasData.lastMeasuredValue === null
                ? 0
                : diasData.lastMeasuredValue
          },
          lastMeasuredDevice: sysData.lastMeasuredDevice,
          lastMeasuredNote: sysData.lastMeasuredNote,
          lastMeasuredTime: sysData.lastMeasuredTime
        };
      }
    } else {
      return await MiddlewareService.getLatestMeasurementWithCode(
        // must be fixed
        'UserState.currentUser.id',
        observationCode as ObservationCode
      );
    }
  }

  public static async getEcgGraphWaveValues(
    selectedTimeSpan: TimeSpan,
    _createdAt: string,
    // must be fixed
    userId: string = 'UserState.selectedUser?.id || UserState.currentUser.id'
  ): Promise<IGraphicWaveValues[]> {
    const [ecgGraphWaveInfo] = await Promise.all([
      MiddlewareService.getGraphicInformation({
        code: ObservationCode.ECG,
        timespan: selectedTimeSpan,
        _createdAt,
        subject: `Patient/${userId}`
      })
    ]);
    return ecgGraphWaveInfo as IGraphicWaveValues[];
  }

  public static getQuestionnaireResponse = async (
    userId: string,
    questionnaireType: QuestionnaireTypes
  ): Promise<IQuestionnaireResponse> => {
    const response = await MiddlewareService.getQuestionnaireResponse({
      userId,
      lang: 'en',
      questionnaire: questionnaireType
    });
    return _.uniqBy(
      response.sort(
        (a, b) =>
          new Date(Number(b.authored)).getTime() -
          new Date(Number(a.authored)).getTime()
      ),
      'id'
    )[0];
  };

  // public static getQuestionnaireResponse = async (
  //   userId: string,
  //   questionnaireType: QuestionnaireTypes,
  // ): Promise<IQuestionnaireResponse> => {
  //   const response = await MiddlewareService.getLatestQuestionnaireResponse({
  //     userId,
  //     questionnaire: questionnaireType,
  //     lang: "en_EN",
  //   });
  //   return response;
  // };
}

/* eslint-disable eqeqeq */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import { MiddlewareService } from '../services/middleware/MiddlewareService';
import { UserRole } from '../models/user';
import { ITaskDetails, TaskStatus } from '../models/tasks/ITasks';
import * as uuid from 'uuid';

export enum TaskDetailStatusReason {
  OUT_OF_RANGE = 'measurement out of range (comment)',
  MEASUREMENT_MISSING = 'measurement missing'
}

export enum TaskDetailWith {
  Observation = 'about{Observation}'
}

export type CommunicationRequest = {
  id: string;
  payload: Array<{
    content: { string: string; Attachment?: { data: string } };
  }>;
  statusReason: { coding: Array<{ code: string }> };
  authoredOn: string;
  note: Array<{ text: string; author: { string: string }; time: string }>;
  priority: string;
  status: string;
  recipient: Array<{ id: string }>;
  category: Array<{ coding: Array<{ code: string }> }>;
  subject: { id: string };
};

interface ICommunicationRequestState {
  requests: CommunicationRequest[];
  filteredRequests: CommunicationRequest[];
  taskDetails?: ITaskDetails[];
}

const initialState: ICommunicationRequestState = {
  requests: [],
  filteredRequests: [],
  taskDetails: []
};

const slice = createSlice({
  name: 'communication-request',
  initialState,
  reducers: {
    communicationRequestChanged(
      state: ICommunicationRequestState,
      action: PayloadAction<{
        requests: CommunicationRequest[];
      }>
    ) {
      const { requests } = action.payload;
      requests.forEach((x) => {
        const index = state.requests.findIndex((y) => y.id === x.id);
        const filterIndex = state.filteredRequests.findIndex(
          (y) => y.id === x.id
        );
        if (index > -1) {
          state.requests.splice(index, 1, x);
        } else {
          state.requests.push(x);
        }
        if (filterIndex > -1) {
          if (
            state.filteredRequests[filterIndex].recipient.length !==
            x.recipient.length
          ) {
            state.filteredRequests.splice(filterIndex, 1);
          } else {
            state.filteredRequests.splice(index, 1, x);
          }
        }
      });
    },
    changeTaskDetailStatus(
      state: ICommunicationRequestState,
      action: PayloadAction<{
        id: string;
        status: TaskStatus;
      }>
    ) {
      const { status, id } = action.payload;
      const index = state.taskDetails.findIndex((x) => x.id === id);
      state.taskDetails[index].status = status;
    },
    addTaskDetailNote(
      state: ICommunicationRequestState,
      action: PayloadAction<{
        id: string;
        comment: string;
        userId: string;
        role: string;
      }>
    ) {
      const { id, comment, userId, role } = action.payload;
      const index = state.taskDetails.findIndex((x) => x.id === id);
      if (index > -1) {
        state.taskDetails[index].comments.push({
          id,
          comment,
          commenterId: userId,
          role,
          dateTime: new Date().toISOString()
        });
      }
    },
    getCommunicationRequest(
      state: ICommunicationRequestState,
      action: PayloadAction<{
        requests: CommunicationRequest[];
      }>
    ) {
      const { requests } = action.payload;
      requests.forEach((x) => {
        const index = state.requests.findIndex((y) => y.id === x.id);
        if (index > -1) {
          state.requests.splice(index, 1, x);
        } else {
          state.requests.push(x);
        }
      });
    },
    getTaskDetails(
      state: ICommunicationRequestState,
      action: PayloadAction<{
        tasks: ITaskDetails[];
      }>
    ) {
      const { tasks } = action.payload;
      tasks.forEach((x) => {
        const index = state.taskDetails.findIndex((y) => y.id === x.id);
        if (index > -1) {
          state.taskDetails.splice(index, 1, x);
        } else {
          state.taskDetails.push(x);
        }
      });
    },
    addCommunicationRequestRecipient(
      state: ICommunicationRequestState,
      action: PayloadAction<{
        id: string;
        recipient: string;
      }>
    ) {
      const { recipient, id } = action.payload;
      const index = state.requests.findIndex((y) => y.id === id);
      const filteredIndex = state.filteredRequests.findIndex(
        (y) => y.id === id
      );
      const [resourceType, _id] = recipient.split('/');
      // @ts-ignore
      state.requests[index].recipient.push({ resourceType, id: _id });
      if (filteredIndex > -1) {
        state.filteredRequests.splice(index, 1, {
          ...state.requests[index]
        });
      }
      if (index > -1) {
        state.requests.splice(index, 1, {
          ...state.requests[index]
        });
      } else {
        state.requests.push(state.requests[index]);
      }
    },
    getFilteredCommunicationRequest(
      state: ICommunicationRequestState,
      action: PayloadAction<{
        subjectId?: string;
        status?: 'draft' | 'active' | 'completed' | string;
        recipient: string | string[];
      }>
    ) {
      const { subjectId, recipient, status } = action.payload;
      if (status != undefined && status.toLowerCase() === 'draft') {
        state.filteredRequests = state.requests.filter(
          (x) => x.recipient.length === 1
        );
      } else {
        state.filteredRequests = state.requests.filter(
          (x) =>
            (subjectId !== undefined && x.subject.id === subjectId) ||
            (x.recipient.some((y) =>
              Array.isArray(recipient)
                ? recipient.find((w) => {
                    return y.id === w.split('/')[1];
                  }) !== undefined
                : y.id === recipient.split('/')[1]
            ) && status != undefined
              ? x.status.toLowerCase() === status.toLowerCase()
              : x.status != undefined)
        );
      }
    },
    changeCommunicationRequestNote(
      state: ICommunicationRequestState,
      action: PayloadAction<{
        id: string;
        note: string;
      }>
    ) {
      const { note, id } = action.payload;
      const requestIndex = state.requests.findIndex((x) => x.id === id);
      const filteredRequestIndex = state.filteredRequests.findIndex(
        (x) => x.id === id
      );
      if (requestIndex > -1) {
        state.requests.splice(requestIndex, 1, {
          ...state.requests[requestIndex],
          note: [
            {
              text: note,
              author: {
                string:
                  state.requests[requestIndex].note != undefined
                    ? state.requests[requestIndex].note[0].author.string
                    : 'Actimi GmbH'
              },
              time: new Date().toISOString()
            }
          ]
        });
      }
      if (filteredRequestIndex > -1) {
        state.filteredRequests.splice(filteredRequestIndex, 1, {
          ...state.requests[requestIndex],
          note: [
            {
              text: note,
              author: {
                string:
                  state.requests[requestIndex].note != undefined
                    ? state.requests[requestIndex].note[0].author.string
                    : 'Actimi GmbH'
              },
              time: new Date().toISOString()
            }
          ]
        });
      }
    },
    addCommunicationRequestNote(
      state: ICommunicationRequestState,
      action: PayloadAction<{
        id: string;
        note: string;
        author: string;
        role: string;
      }>
    ) {
      const { note, id, author, role } = action.payload;
      const requestIndex = state.requests.findIndex((x) => x.id === id);
      const filteredRequestIndex = state.filteredRequests.findIndex(
        (x) => x.id === id
      );
      const taskDetailIndex = state.taskDetails.findIndex((x) => x.id === id);
      if (requestIndex > -1) {
        state.requests[requestIndex].note == undefined
          ? (state.requests[requestIndex].note = [
              {
                text: note,
                author: {
                  string: author
                },
                time: new Date().toISOString()
              }
            ])
          : state.requests[requestIndex].note.push({
              text: note,
              author: {
                string: author
              },
              time: new Date().toISOString()
            });
      }
      if (filteredRequestIndex > -1) {
        state.requests[requestIndex].note == undefined
          ? (state.requests[requestIndex].note = [
              {
                text: note,
                author: {
                  string: author
                },
                time: new Date().toISOString()
              }
            ])
          : state.requests[requestIndex].note.push({
              text: note,
              author: {
                string: author
              },
              time: new Date().toISOString()
            });
      }
      if (taskDetailIndex > -1) {
        state.taskDetails[taskDetailIndex].comments == undefined
          ? (state.taskDetails[taskDetailIndex].comments = [
              {
                id: uuid.v4(),
                commenterId: author,
                role,
                comment: note,
                dateTime: new Date().toISOString()
              }
            ])
          : state.taskDetails[taskDetailIndex].comments.push({
              id: uuid.v4(),
              commenterId: author,
              role,
              comment: note,
              dateTime: new Date().toISOString()
            });
      }
      console.log(
        state.requests[requestIndex].note,
        'state.requests[requestIndex]'
      );
    },

    changeCommunicationRequestCategory(
      state: ICommunicationRequestState,
      action: PayloadAction<{
        id: string;
        category: string;
      }>
    ) {
      const { category, id } = action.payload;
      const requestIndex = state.requests.findIndex((x) => x.id === id);
      const filteredRequestIndex = state.filteredRequests.findIndex(
        (x) => x.id === id
      );
      if (requestIndex > -1) {
        state.requests.splice(requestIndex, 1, {
          ...state.requests[requestIndex],
          category: [{ coding: [{ code: category.toLowerCase() }] }]
        });
      }
      if (filteredRequestIndex > -1) {
        state.filteredRequests.splice(filteredRequestIndex, 1, {
          ...state.requests[requestIndex],
          category: [{ coding: [{ code: category.toLowerCase() }] }]
        });
      }
    },
    changeCommunicationRequestStatus(
      state: ICommunicationRequestState,
      action: PayloadAction<{
        id: string;
        status: string;
        statusReason: string;
      }>
    ) {
      const { id, status, statusReason } = action.payload;
      const requestIndex = state.requests.findIndex((x) => x.id === id);
      const filteredRequestIndex = state.filteredRequests.findIndex(
        (x) => x.id === id
      );
      if (requestIndex > -1) {
        state.requests.splice(requestIndex, 1, {
          ...state.requests[requestIndex],
          status: status.toLowerCase(),
          statusReason: { coding: [{ code: statusReason.toLowerCase() }] }
        });
      }
      if (filteredRequestIndex > -1) {
        state.filteredRequests.splice(filteredRequestIndex, 1, {
          ...state.requests[requestIndex],
          status: status.toLowerCase(),
          statusReason: { coding: [{ code: statusReason.toLowerCase() }] }
        });
      }
    },
    reset(state: ICommunicationRequestState) {
      Object.assign(state, initialState);
    }
  }
});

export const reducer = slice.reducer;

export const reset = (): AppThunk => async (dispatch) =>
  dispatch(slice.actions.reset());

export const getTaskDetails =
  (
    statusReason?: TaskDetailStatusReason,
    count?: number,
    sort?: string,
    _with?: TaskDetailWith,
    subject?: string,
    recipient?: string,
    status?: string[]
  ): AppThunk =>
  async (dispatch) => {
    const tasks: ITaskDetails[] = await MiddlewareService.getTaskDetails(
      statusReason,
      count,
      sort,
      _with,
      subject,
      recipient,
      status
    );
    dispatch(slice.actions.getTaskDetails({ tasks }));
  };

export const getCommunicationRequest =
  (
    recipient: string | string[],
    status?: 'draft' | 'active' | 'completed',
    subjectId?: string,
    whenDone?: () => void
  ): AppThunk =>
  async (dispatch) => {
    const requests = await MiddlewareService.getCommunicationRequest(
      recipient,
      subjectId,
      status
    );
    dispatch(slice.actions.getCommunicationRequest({ requests }));
    dispatch(
      slice.actions.getFilteredCommunicationRequest({
        recipient,
        status: 'draft',
        subjectId
      })
    );
    if (whenDone) {
      whenDone();
    }
  };
export const getFilteredCommunicationRequest =
  (
    recipient: string | string[],
    status: 'draft' | 'active' | 'completed',
    subjectId?: string,
    whenDone?: () => void
  ): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.getFilteredCommunicationRequest({
        recipient,
        subjectId,
        status
      })
    );
    if (whenDone) {
      whenDone();
    }
  };

export const addCommunicationRequestRecipient =
  (recipient: string, id: string): AppThunk =>
  async (dispatch) => {
    await MiddlewareService.addCommunicationRequestRecipient(id, recipient);
    dispatch(
      slice.actions.addCommunicationRequestRecipient({
        recipient,
        id
      })
    );
  };

export const changeCommunicationRequestNote =
  (id: string, note: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.changeCommunicationRequestNote({ id, note }));
  };

export const addCommunicationRequestNote =
  (
    id: string,
    note: string,
    author: string,
    role: string,
    whenDone?: () => void
  ): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.addCommunicationRequestNote({ id, note, author, role })
    );
    if (whenDone) {
      whenDone();
    }
  };

export const changeCommunicationRequestCategory =
  (id: string, category: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.changeCommunicationRequestCategory({ id, category })
    );
  };

export const changeCommunicationRequestStatus =
  (
    id: string,
    status: string,
    statusReason: string,
    whenDone?: () => void
  ): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.changeCommunicationRequestStatus({
        id,
        status,
        statusReason
      })
    );
    if (whenDone) {
      whenDone();
    }
  };

export const communicationRequestChanged =
  (communicationRequest: CommunicationRequest): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.communicationRequestChanged({
        requests: [communicationRequest]
      })
    );
  };

export const addTaskDetailNote =
  (id: string, comment: string, userId: string, role: string): AppThunk =>
  async (dispatch) => {
    await MiddlewareService.addTaskDetailNote(id, {
      comment,
      dateTime: new Date().toISOString(),
      commenterId: userId,
      id: id,
      role
    });
    dispatch(
      slice.actions.addTaskDetailNote({
        id,
        comment,
        userId,
        role
      })
    );
  };

export const updateCommunicationRequest =
  (communicationRequest: CommunicationRequest, status: string): AppThunk =>
  async (dispatch) => {
    const data = await MiddlewareService.updateCommunicationRequest(
      communicationRequest
    );
    dispatch(slice.actions.getCommunicationRequest({ requests: [data] }));
    dispatch(
      slice.actions.getFilteredCommunicationRequest({
        status,
        recipient: communicationRequest.recipient.map(
          (x: { resourceType: string; id: string }) =>
            `${x.resourceType}/${x.id}`
        )
      })
    );
  };

export const changeTaskDetailStatus =
  (
    id: string,
    userId: string,
    role: UserRole,
    comment: string,
    status: TaskStatus
  ): AppThunk =>
  async (dispatch) => {
    await MiddlewareService.addTaskDetailNote(id, {
      comment,
      dateTime: new Date().toISOString(),
      commenterId: userId,
      id: id,
      role
    });
    dispatch(
      slice.actions.addTaskDetailNote({
        id,
        comment,
        userId,
        role
      })
    );
    await MiddlewareService.changeTaskDetailStatus(id, status);
    dispatch(
      slice.actions.changeTaskDetailStatus({
        id,
        status
      })
    );
  };

export const completeCommunicationRequest =
  (
    communicationRequest: CommunicationRequest,
    status: string,
    roles: UserRole
  ): AppThunk =>
  async (dispatch) => {
    let data;
    if (roles === 'doctor') {
      data = await MiddlewareService.updateCommunicationRequest({
        ...communicationRequest,
        status: 'completed'
      });
    } else if (roles === 'Assistant') {
      data = await MiddlewareService.updateCommunicationRequest({
        ...communicationRequest,
        status: 'doctor'
      });
    }
    dispatch(slice.actions.getCommunicationRequest({ requests: [data] }));
    dispatch(
      slice.actions.getFilteredCommunicationRequest({
        status,
        recipient: communicationRequest.recipient.map(
          (x: { resourceType: string; id: string }) =>
            `${x.resourceType}/${x.id}`
        )
      })
    );
  };

export default slice;

/* eslint-disable eqeqeq */
import moment from 'moment';
import { DEFAULT_LIMIT_VALUES } from 'src/defaults/limit-values';
import { ITableItem } from 'src/models/dashboard-table';
import { ILimitValueSet } from 'src/slices/limit-values';
import { DefaultRiskScoreValues } from './RiskScores';

export enum DashboardTableFilter {
  ALL_PATIENTS = 'All Patients',
  FOR_NURSE = 'For Nurse',
  FOR_DOCTOR = 'For Doctor'
}

export interface IRisk {
  text: string;
  riskScore: number;
  color?: string;
}

/*const RiskColorsDebug = {
  NORMAL: '#c1e4d3',
  LOW: '#fffce5',
  MEDIUM: '#fde9cc',
  HIGH: '#fdccd5'
}*/

const RiskColors = {
  NORMAL: '#FFFFFF',
  LOW: '#FFF5AA',
  MEDIUM: '#FFC979',
  HIGH: '#FF9DAF'
};

export const getRiskChangeArrow = ({
  prev,
  current
}: {
  prev: IRisk;
  current: IRisk;
}) => {
  const prevScore = prev?.riskScore;
  const currentScore = current?.riskScore;
  if (prevScore === currentScore) return 'none';
  return prevScore > currentScore ? 'down' : 'up';
};

export const getRiskScoreSymptoms = (
  val: number,
  limitValues: ILimitValueSet = DEFAULT_LIMIT_VALUES.symptoms
): IRisk => {
  const { low, medium, high } = limitValues;

  if (val < low.max) {
    return {
      text: DefaultRiskScoreValues.normal,
      riskScore: low?.riskScore - 1,
      color: '#FFFFFF'
    };
  }
  if (low.max <= val && val < medium.max) {
    return {
      text: DefaultRiskScoreValues.lowSeverity,
      riskScore: low?.riskScore,
      color: RiskColors.LOW
    };
  }
  if (medium.max <= val && val < high.max) {
    return {
      text: DefaultRiskScoreValues.mediumSeverity,
      riskScore: medium?.riskScore,
      color: RiskColors.MEDIUM
    };
  }
  if (high.max <= val) {
    return {
      text: DefaultRiskScoreValues.highSeverity,
      riskScore: high?.riskScore,
      color: RiskColors.HIGH
    };
  }
  return {
    text: DefaultRiskScoreValues.undefinedRange,
    riskScore: 0,
    color: '#FFFFFF'
  };
};

export const getRiskScoreBloodOxygen = (
  val: number,
  limitValues: ILimitValueSet
): IRisk => {
  throw Error('Blood oxygen not being used!');
};

export const getRiskScoreBodyWeight = (riskScore: number): IRisk => {
  if (riskScore === undefined) {
    return {
      text: DefaultRiskScoreValues.undefinedRange,
      riskScore: riskScore,
      color: '#DEDEDE'
    };
  }

  if (riskScore === 0) {
    return {
      text: DefaultRiskScoreValues.normal,
      riskScore: riskScore,
      color: RiskColors.NORMAL
    };
  }
  if (riskScore === 2) {
    return {
      text: DefaultRiskScoreValues.lowSeverity,
      riskScore: riskScore,
      color: RiskColors.LOW
    };
  }
  if (riskScore === 4) {
    return {
      text: DefaultRiskScoreValues.highSeverity,
      riskScore: riskScore,
      color: RiskColors.HIGH
    };
  }
};

// export const getRiskScoreBodyWeight = (
//   delta: number,
//   limitValues: IPeriodLimitValue[] = DEFAULT_LIMIT_VALUES.bodyWeight,
//   dateTime: string,
//   prevDateTime: string
// ): IRisk => {
//   const _dateTime = new Date(dateTime);
//   const _prevDateTime = new Date(prevDateTime);

//   const utc1 = Date.UTC(
//     _dateTime.getFullYear(),
//     _dateTime.getMonth(),
//     _dateTime.getDate()
//   );
//   const utc2 = Date.UTC(
//     _prevDateTime.getFullYear(),
//     _prevDateTime.getMonth(),
//     _prevDateTime.getDate()
//   );
//   const diff = Math.floor(((utc2 - utc1) / 1000) * 60 * 60 * 24);
//   let low;
//   let medium;
//   let high;
//   if (diff > 0 && diff < 3) {
//     const _lm = limitValues.find((x) => x.timespan == 1);
//     if (_lm != undefined) {
//       low = _lm.limit.low;
//       medium = _lm.limit.medium;
//       high = _lm.limit.high;
//     }
//   } else if (diff > 2 && diff < 7) {
//     const _lm = limitValues.find((x) => x.timespan == 3);
//     if (_lm != undefined) {
//       low = _lm.limit.low;
//       medium = _lm.limit.medium;
//       high = _lm.limit.high;
//     } else if (diff > 6 && diff < 10) {
//       const _lm = limitValues.find((x) => x.timespan == 7);
//       if (_lm != undefined) {
//         low = _lm.limit.low;
//         medium = _lm.limit.medium;
//         high = _lm.limit.high;
//       }
//     }

//     delta = Math.abs(delta);

//     if (isNaN(delta)) {
//       return {
//         text: DefaultRiskScoreValues.undefinedRange,
//         riskScore: 0,
//         color: RiskColors.NORMAL
//       };
//     }
//     if (low.min <= delta && delta <= low.max) {
//       return {
//         text: DefaultRiskScoreValues.normal,
//         riskScore: low?.riskScore - 2
//       };
//     }
//     if (medium.min <= delta && delta <= medium.max) {
//       return {
//         text: DefaultRiskScoreValues.lowSeverity,
//         riskScore: low?.riskScore,
//         color: RiskColors.LOW
//       };
//     }
//     if (high.min <= delta && delta <= high.max) {
//       return {
//         text: DefaultRiskScoreValues.mediumSeverity,
//         riskScore: medium?.riskScore,
//         color: RiskColors.MEDIUM
//       };
//     }
//     if (high.max < delta) {
//       return {
//         text: DefaultRiskScoreValues.highSeverity,
//         riskScore: high?.riskScore,
//         color: RiskColors.HIGH
//       };
//     }

//     return {
//       text: DefaultRiskScoreValues.undefinedRange,
//       riskScore: 0,
//       color: '#FFFFFF'
//     };
//   }
// };

export const getRiskScoreLastMeasuredTime = (date: string) => {
  const a = moment();
  const b = moment(date);

  if (a.diff(b, 'hours') <= 24) {
    return {
      text: DefaultRiskScoreValues.normal,
      displayText:
        b.format('DD') === a.format('DD') ? 'Today' : 'less than 24h',
      riskScore: 0,
      color: RiskColors.NORMAL
    };
  }
  if (a.diff(b, 'hours') > 24 && a.diff(b, 'hours') <= 36) {
    return {
      text: DefaultRiskScoreValues.mediumSeverity,
      displayText: 'more than 24h',
      riskScore: 1,
      color: RiskColors.MEDIUM
    };
  }
  if (a.diff(b, 'hours') > 36) {
    return {
      text: DefaultRiskScoreValues.highSeverity,
      displayText: 'more than 36h',
      riskScore: 2,
      color: RiskColors.HIGH
    };
  }

  return {
    text: DefaultRiskScoreValues.undefinedRange,
    riskScore: 0,
    color: '#FFFFFF'
  };
};

// Intervals of each low, medium, high tested respectively
export const getRiskScoreMinMaxInterval = (
  val: number,
  limitValues: ILimitValueSet
): IRisk => {
  const { low, medium, high } = limitValues;
  if (isNaN(val) || val == undefined) {
    return {
      text: DefaultRiskScoreValues.normal,
      riskScore: 0,
      color: RiskColors.NORMAL
    };
  }
  if (val >= low.min && val <= low.max) {
    return {
      text: DefaultRiskScoreValues.normal,
      riskScore: 0,
      color: RiskColors.NORMAL
    };
  }
  if (
    (val >= medium.min && val <= low.min - 1) ||
    (val >= low.max + 1 && val <= medium.max)
  ) {
    return {
      text: DefaultRiskScoreValues.normal,
      color: RiskColors.NORMAL,
      riskScore: low?.riskScore
    };
  }
  if (
    (val >= high.min && val <= medium.max - 1) ||
    (val >= medium.max + 1 && val <= high.max)
  ) {
    return {
      text: DefaultRiskScoreValues.normal,
      color: RiskColors.NORMAL,
      riskScore: medium?.riskScore
    };
  }
  if (val <= high.min - 1 || val >= high.max + 1) {
    return {
      text: DefaultRiskScoreValues.normal,
      color: RiskColors.NORMAL,
      riskScore: high?.riskScore
    };
  }

  return {
    text: DefaultRiskScoreValues.normal,
    riskScore: 0,
    color: RiskColors.NORMAL
  };
  // const { low, medium, high } = limitValues;

  // if (low.min <= val && val <= low.max) {
  //   return {
  //     text: DefaultRiskScoreValues.normal,
  //     riskScore: low?.riskScore - 2,
  //     color: RiskColors.NORMAL
  //   };
  // }
  // if (medium.min <= val && val <= medium.max) {
  //   return {
  //     text: DefaultRiskScoreValues.lowSeverity,
  //     riskScore: low?.riskScore,
  //     color: RiskColors.LOW
  //   };
  // }
  // if (high.min <= val && val <= high.max) {
  //   return {
  //     text: DefaultRiskScoreValues.mediumSeverity,
  //     riskScore: medium?.riskScore,
  //     color: RiskColors.MEDIUM
  //   };
  // }
  // if (high.max < val) {
  //   return {
  //     text: DefaultRiskScoreValues.highSeverity,
  //     riskScore: high?.riskScore,
  //     color: RiskColors.HIGH
  //   };
  // }

  // return {
  //   text: DefaultRiskScoreValues.undefinedRange,
  //   riskScore: 0,
  //   color: '#FFFFFF'
  // };
};

// Systolic blood pressure is taken
export const getRiskScoreBP = (
  val: number,
  limitValues: ILimitValueSet = DEFAULT_LIMIT_VALUES.bloodPressure
): IRisk => {
  const lowMin = limitValues?.low?.min;
  const lowMax = limitValues?.low?.max;
  const medMin = limitValues?.medium?.min;
  const medMax = limitValues?.medium?.max;
  const highMin = limitValues?.high?.min;
  const highMax = limitValues?.high?.max;

  if (val >= lowMin && val <= lowMax) {
    return {
      text: DefaultRiskScoreValues.normal,
      color: RiskColors.NORMAL,
      riskScore: 0
    };
  } else if (
    (val >= medMin && val <= lowMin - 1) ||
    (val >= lowMax + 1 && val <= medMax)
  ) {
    return {
      text: DefaultRiskScoreValues.lowSeverity,
      color: RiskColors.LOW,
      riskScore: 1
    };
  } else if (
    (val >= highMin && val <= medMax - 1) ||
    (val >= medMax + 1 && val <= highMax)
  ) {
    return {
      text: DefaultRiskScoreValues.mediumSeverity,
      color: RiskColors.MEDIUM,
      riskScore: 2
    };
  } else if (val <= highMin - 1 || val >= highMax + 1) {
    return {
      text: DefaultRiskScoreValues.highSeverity,
      color: RiskColors.HIGH,
      riskScore: 2
    };
  } else {
    return {
      text: DefaultRiskScoreValues.undefinedRange,
      color: RiskColors.NORMAL,
      riskScore: 0
    };
  }
};

export const getRiskScoreHR = (
  val: number,
  limitValues: ILimitValueSet = DEFAULT_LIMIT_VALUES.bloodPressure
): IRisk => {
  const lowMin = limitValues?.low?.min;
  const lowMax = limitValues?.low?.max;
  const medMin = limitValues?.medium?.min;
  const medMax = limitValues?.medium?.max;
  const highMin = limitValues?.high?.min;
  const highMax = limitValues?.high?.max;

  if (val >= lowMin && val <= lowMax) {
    return {
      text: DefaultRiskScoreValues.normal,
      color: RiskColors.NORMAL,
      riskScore: 0
    };
  } else if (
    (val >= medMin && val <= lowMin - 1) ||
    (val >= lowMax + 1 && val <= medMax)
  ) {
    return {
      text: DefaultRiskScoreValues.lowSeverity,
      color: RiskColors.LOW,
      riskScore: 1
    };
  } else if (
    (val >= highMin && val <= medMax - 1) ||
    (val >= medMax + 1 && val <= highMax)
  ) {
    return {
      text: DefaultRiskScoreValues.mediumSeverity,
      color: RiskColors.MEDIUM,
      riskScore: 2
    };
  } else if (val <= highMin - 1 || val >= highMax + 1) {
    return {
      text: DefaultRiskScoreValues.highSeverity,
      color: RiskColors.HIGH,
      riskScore: 2
    };
  } else {
    return {
      text: DefaultRiskScoreValues.undefinedRange,
      color: RiskColors.NORMAL,
      riskScore: 0
    };
  }
};

// HeartRate
export const getRiskScoreHeartRate = (
  val: number,
  limitValues: ILimitValueSet = DEFAULT_LIMIT_VALUES.heartRate
): IRisk => getRiskScoreMinMaxInterval(val, limitValues);

export const ecgStatusColor = (status: string) => {
  const _status = status != undefined ? status.toLowerCase() : undefined;
  switch (_status) {
    case 'ok':
    case 'regular':
      return '#FFFFFF';
    case 'slow':
    case 'fast':
    case 'afib':
      return '#F8961E';
    case null:
    case undefined:
      return '#FFFFFF';
    default:
      return '#1D9DE6';
  }
};
//TODO:Batuhan
//@ts-ignore
declare type ILastMeasurements = ITableItem['lastMeasurements'];
declare type ILastMeasurementKey =
  | 'symptoms'
  | 'bloodPressure'
  | 'heartRate'
  | 'bodyWeight'
  | 'ecg';
const lastMeasurementKeys: ILastMeasurementKey[] = [
  'symptoms',
  'bloodPressure',
  'heartRate',
  'bodyWeight',
  'ecg'
];

export function getLastMeasured(lastMeasurements: ILastMeasurements) {
  const lastMeasuredKey = lastMeasurementKeys.reduce((latestMkey?, mkey?) => {
    if (!latestMkey) return mkey;
    var latestMDate = new Date(
      lastMeasurements[latestMkey].dateTime ?? 0
    ).getTime();
    var mDate = new Date(lastMeasurements[mkey].dateTime ?? 0).getTime();
    return latestMDate > mDate ? latestMkey : mkey;
  }, null);

  return {
    type: getMeasurementTypeName(lastMeasuredKey),
    dateTime: lastMeasurements[lastMeasuredKey].dateTime
  };
}

const getMeasurementTypeName = (key: ILastMeasurementKey) => {
  switch (key) {
    case 'bloodPressure':
      return 'BP';
    case 'heartRate':
      return 'HR';
    case 'symptoms':
      return 'SYMPTOM';
    case 'bodyWeight':
      return 'WEIGHT';
    case 'ecg':
      return 'ECG';
    default:
      return 'OTHER';
  }
};

import i18n, { Resource } from 'i18next';

import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import 'moment/min/moment-with-locales';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import countries from 'i18n-iso-countries';
import { LanguageCodes } from 'src/i18n/Languages';
import deJSON from './translations/de_DE.json';
import enJSON from './translations/en_US.json';

export const languageResources: Resource = {
  [LanguageCodes.de]: { translation: deJSON },
  [LanguageCodes.en]: { translation: enJSON }
};

if (localStorage.getItem('i18nextLng') === null) {
  localStorage.setItem('i18nextLng', 'en');
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: languageResources,
    keySeparator: false,
    fallbackLng: LanguageCodes.en, // This will receive languages in formats like en_US, de_DE
    react: {
      useSuspense: true
    },
    interpolation: {
      escapeValue: false
    }
  });

i18n.on('languageChanged', (currentLanguage) => {
  if (currentLanguage === 'de') {
    moment.locale('de');
    localStorage.setItem('i18nextLng', 'de');
  } else if (currentLanguage === 'en') {
    moment.locale('en');
    localStorage.setItem('i18nextLng', 'en');
  } else {
    moment.locale('en');
    localStorage.setItem('i18nextLng', 'en');
  }
});

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/de.json'));

export default i18n;

/* eslint-disable array-callback-return */
import {
  IFormattedGraphicData,
  IFormattedSortableGraphicData,
  IGraphicInformation,
  IPainLevelGraph,
  IValue,
  ValidatedEcgGraphWaveValueObject
} from '../models/graphic/IGraphic';
import { isMultipleGraphicDataType } from './Graphic';
import {
  emptyMonthlyActivity,
  emptyMonthlyAcuteWorkload,
  emptyMonthlyArrayGenerator,
  emptyMonthlyPain,
  emptyMonthlyPainLevel,
  emptyMonthlyQoL,
  emptyMonthlyStiffness,
  emptyTodayArrayGenerator,
  emptyWeeklyArrayGenerator,
  emptyYearlyArrayGenerator,
  MONTHLY,
  WEEKLY,
  YEARLY
} from '../models/observation/enums/TimeSpan';
import {
  _FormattedBpGraphDataCard,
  FormattedBloodPressureGraphDataObject,
  FormattedBpWithHeartRate,
  IFormattedBpDiasDataObject,
  IFormattedBpSysDataObject
} from '../models/graphic/IBloodPressureGraphic';
import * as _ from 'lodash';
import moment from 'moment';
import { MeasurementTypeKey, TimeSpan } from '@actimi/core-migration';

export function divideData(
  data: IFormattedGraphicData[][],
  label: number
): IFormattedGraphicData[] {
  return data[label];
}
export function findLastValueIndex(data: IFormattedGraphicData[]): number {
  let valueIndex = 0;
  let counter = 0;
  data.forEach((d) => {
    if (d.averageOnTime !== 0) {
      valueIndex = counter;
    }
    counter += 1;
  });
  return valueIndex;
}

export function findMinMax(
  graphicData: IFormattedGraphicData[] | IFormattedGraphicData[][]
): { min: number; max: number } {
  let max = 0;
  let min = 200;
  if (isMultipleGraphicDataType(graphicData)) {
    const sysData = divideData(graphicData as IFormattedGraphicData[][], 0);
    const diasData = divideData(graphicData as IFormattedGraphicData[][], 1);
    const heartRateData = divideData(
      graphicData as IFormattedGraphicData[][],
      2
    );
    sysData.forEach((data: IFormattedGraphicData) => {
      if (data.max > max) {
        max = data.max;
      }
    });
    diasData.forEach((data) => {
      if (data.min !== 0 && data.min !== null && data.min < min) {
        min = data.min;
      }
    });
    heartRateData.forEach((data) => {
      if (data.min !== 0 && data.min !== null && data.min < min) {
        min = data.min;
      }
    });
  } else {
    if (graphicData !== undefined) {
      for (const data of graphicData) {
        if (data.max > max) {
          max = data.max;
        }
        if (data.min !== 0 && data.min !== null && data.min < min) {
          min = data.min;
        }
      }
    }
  }
  return {
    max,
    min
  };
}
export function getAverageLineYearly(
  data: IFormattedGraphicData[] | undefined
): { marker: string; value: number | null }[] | undefined {
  let sum = 0;
  let index = 0;
  const averages: { marker: string; value: number | null }[] = [];
  if (data) {
    data.forEach((_data: IFormattedGraphicData) => {
      if (_data.averageOnTime !== 0) {
        sum += _data.averageOnTime;
        index++;
        averages.push({
          marker: _data.marker.substring(0, 3),
          value: parseFloat((sum / index).toFixed(4))
        });
      } else {
        return null;
      }
    });
    return averages;
  }
}
export function getAverageLine(
  data: IFormattedGraphicData[]
): { marker: string; value: number | null }[] {
  let sum = 0;
  let index = 0;
  let pointer = 0;
  const averages: { marker: string; value: number | null }[] = [];
  data.forEach((_data: IFormattedGraphicData) => {
    if (pointer < findLastValueIndex(data) + 1) {
      if (_data.averageOnTime !== 0) {
        sum += _data.averageOnTime;
        index++;
        averages.push({
          marker: _data.marker.substring(0, 3),
          value: parseFloat((sum / index).toFixed(4))
        });
      } else {
        averages.push({
          marker: _data.marker.substring(0, 3),
          value:
            sum !== 0 && index !== 0
              ? parseFloat((sum / index).toFixed(4))
              : null
        });
      }
    } else {
      averages.push({
        marker: _data.marker.substring(0, 3),
        value: null
      });
    }
    pointer++;
  });
  return averages;
}
export const combineBloodPressureCardValues = (
  bpGraphData: IFormattedGraphicData[][]
): { sysValues: IValue[]; diasValues: IValue[]; heartRateValues: IValue[] } => {
  const bpSysValues: IValue[] = [];
  const bpDiasValues: IValue[] = [];
  const bpHeartRateValues: IValue[] = [];
  bpGraphData[0].forEach((x) => bpSysValues.push(...x.values));
  bpGraphData[1].forEach((x) => bpDiasValues.push(...x.values));
  bpGraphData[2].forEach((x) => bpHeartRateValues.push(...x.values));
  const _bpSysValues = bpSysValues;
  const _bpDiasValues = bpDiasValues;
  const _bpHeartRateValues = bpHeartRateValues;
  _bpSysValues.sort(
    (a, b) => new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime()
  );
  _bpDiasValues.sort(
    (a, b) => new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime()
  );
  _bpHeartRateValues.sort(
    (a, b) => new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime()
  );
  return {
    sysValues: _bpSysValues,
    diasValues: _bpDiasValues,
    heartRateValues: _bpHeartRateValues
  };
};
export const combineDetailedBloodPressureObject = (
  givenData: IFormattedGraphicData[][]
): FormattedBloodPressureGraphDataObject[] => {
  return _.values(
    _.merge(
      _.keyBy(
        givenData[0].map((x) => ({
          marker: x.marker,
          y: x.averageOnTime,
          sysAverage: x.averageOnTime
        })),
        'marker'
      ),
      _.keyBy(
        givenData[1].map((x) => ({
          marker: x.marker,
          y0: x.averageOnTime,
          diasAverage: x.averageOnTime
        })),
        'marker'
      )
    )
  );
};
export const combineDetailedBpWithHeartRate = (
  givenData: IFormattedGraphicData[][]
): FormattedBpWithHeartRate[] => {
  return _.values(
    _.merge(
      _.keyBy(
        givenData[0].map((x) => ({
          marker: x.marker,
          y: x.averageOnTime,
          sysAverage: x.averageOnTime
        })),
        'marker'
      ),
      _.keyBy(
        givenData[1].map((x) => ({
          marker: x.marker,
          y0: x.averageOnTime,
          diasAverage: x.averageOnTime
        })),
        'marker'
      ),
      _.keyBy(
        givenData[2].map((x) => ({
          marker: x.marker,
          y0: x.averageOnTime,
          heartRateAverage: x.averageOnTime
        })),
        'marker'
      )
    )
  );
};

export const validateEcgGraphWaveValue = (
  values: number[]
): ValidatedEcgGraphWaveValueObject[] => {
  if (values.length === 0) {
    return [];
  }
  const formattedDataSets: ValidatedEcgGraphWaveValueObject[] = [];
  values
    .filter((x) => x < 3000 && x > -3000)
    .map((val, index) => {
      formattedDataSets.push({ x: index, y: val });
    });
  return formattedDataSets;
};

export function combineBloodPressureObject(
  givenData: IFormattedGraphicData[][]
): (IFormattedBpSysDataObject & IFormattedBpDiasDataObject)[] {
  const sysData: IFormattedBpSysDataObject[] = [];
  const diasData: IFormattedBpDiasDataObject[] = [];
  const sys = divideData(givenData, 0);
  const dias = divideData(givenData, 1);
  sys.forEach((s) => {
    sysData.push({
      marker: s.marker.substring(0, 3),
      y: s.averageOnTime
    });
  });
  dias.forEach((d) => {
    diasData.push({
      marker: d.marker.substring(0, 3),
      y0: d.averageOnTime
    });
  });
  const merged = _.merge(
    _.keyBy(sysData, 'marker'),
    _.keyBy(diasData, 'marker')
  );
  return _.values(merged);
}

export const sortTimeSpan = (
  time: string,
  selectedTimeSpan: TimeSpan
): number => {
  switch (selectedTimeSpan) {
    case TimeSpan.WEEK:
      switch (time) {
        case WEEKLY.MONDAY:
          return 1;
        case WEEKLY.TUESDAY:
          return 2;
        case WEEKLY.WEDNESDAY:
          return 3;
        case WEEKLY.THURSDAY:
          return 4;
        case WEEKLY.FRIDAY:
          return 5;
        case WEEKLY.SATURDAY:
          return 6;
        case WEEKLY.SUNDAY:
          return 7;
        default:
          return 0;
      }
    case TimeSpan.MONTH:
      switch (time) {
        case MONTHLY.FIRST_WEEK:
          return 1;
        case MONTHLY.SECOND_WEEK:
          return 2;
        case MONTHLY.THIRD_WEEK:
          return 3;
        case MONTHLY.FOURTH_WEEK:
          return 4;
        default:
          return 0;
      }
    case TimeSpan.YEAR:
      switch (time) {
        case YEARLY.JANUARY:
          return 1;
        case YEARLY.FEBRUARY:
          return 2;
        case YEARLY.MARCH:
          return 3;
        case YEARLY.APRIL:
          return 4;
        case YEARLY.MAY:
          return 5;
        case YEARLY.JUNE:
          return 6;
        case YEARLY.JULY:
          return 7;
        case YEARLY.AUGUST:
          return 8;
        case YEARLY.SEPTEMBER:
          return 9;
        case YEARLY.OCTOBER:
          return 10;
        case YEARLY.NOVEMBER:
          return 11;
        case YEARLY.DECEMBER:
          return 12;
        default:
          return 0;
      }
    default:
      return 0;
  }
};

export const sortSingleGraphicData = (
  graphicData: IFormattedGraphicData[]
): IFormattedGraphicData[] => {
  return graphicData
    .map((t) => {
      return {
        ...t,
        values: t.values.sort(
          (u, w) =>
            new Date(w.dateTime).getTime() - new Date(u.dateTime).getTime()
        )
      };
    })
    .sort(
      (y, x) =>
        Math.max(...x.values.map((q) => new Date(q.dateTime).getTime())) -
        Math.max(...y.values.map((k) => new Date(k.dateTime).getTime()))
    );
};

export const sortMultipleGraphicData = (
  graphicData: IFormattedGraphicData[][]
): IFormattedGraphicData[][] => {
  return [
    graphicData[0]
      .map((t) => {
        return {
          ...t,
          values: t.values.sort(
            (u, w) =>
              new Date(w.dateTime).getTime() - new Date(u.dateTime).getTime()
          )
        };
      })
      .sort(
        (y, x) =>
          Math.max(...x.values.map((q) => new Date(q.dateTime).getTime())) -
          Math.max(...y.values.map((k) => new Date(k.dateTime).getTime()))
      ),
    graphicData[1]
      .map((t) => {
        return {
          ...t,
          values: t.values.sort(
            (u, w) =>
              new Date(w.dateTime).getTime() - new Date(u.dateTime).getTime()
          )
        };
      })
      .sort(
        (y, x) =>
          Math.max(...x.values.map((q) => new Date(q.dateTime).getTime())) -
          Math.max(...y.values.map((k) => new Date(k.dateTime).getTime()))
      ),
    graphicData[2]
      .map((t) => {
        return {
          ...t,
          values: t.values.sort(
            (u, w) =>
              new Date(w.dateTime).getTime() - new Date(u.dateTime).getTime()
          )
        };
      })
      .sort(
        (y, x) =>
          Math.max(...x.values.map((q) => new Date(q.dateTime).getTime())) -
          Math.max(...y.values.map((k) => new Date(k.dateTime).getTime()))
      )
  ];
};

export const formatGraphicData = (
  graphData: IGraphicInformation,
  timeSpan: TimeSpan,
  selectedDateTime: string
): IGraphicInformation => {
  switch (timeSpan) {
    case TimeSpan.TODAY:
      const today = _.difference(
        emptyTodayArrayGenerator(),
        graphData?.values.map((val) => val?.marker)
      ).map((x) => graphData?.values.find((y) => y?.marker === x?.marker) || x);

      return {
        ...graphData,
        values: today
      };
    case TimeSpan.WEEK:
      const wkly = _.difference(
        emptyWeeklyArrayGenerator(),
        graphData?.values.map((val) => val?.marker)
      ).map((x) => graphData?.values.find((y) => y?.marker === x?.marker) || x);
      // console.log({
      //   ...graphData,
      //   values: wkly,
      // });
      return {
        ...graphData,
        values: wkly
      };
    case TimeSpan.MONTH:
      const mnthly = _.difference(
        emptyMonthlyArrayGenerator(selectedDateTime),
        graphData?.values?.map((val) => val?.marker)
      ).map(
        (x) => graphData?.values?.find((y) => y?.marker === x?.marker) || x
      );

      return {
        ...graphData,
        values: mnthly
      };
    case TimeSpan.YEAR:
      const yearly = _.difference(
        emptyYearlyArrayGenerator(),
        graphData?.values.map((val) => val?.marker)
      ).map((x) => graphData?.values.find((y) => y?.marker === x?.marker) || x);
      return {
        ...graphData,
        values: yearly
      };
    case TimeSpan.LATEST:
      const latest = _.difference(
        emptyTodayArrayGenerator(),
        graphData?.values.map((val) => val?.marker)
      ).map((x) => graphData?.values.find((y) => y?.marker === x?.marker) || x);
      return {
        ...graphData,
        values: latest
      };
    default:
      throw new Error('Unknown Time Span');
  }
};

export const formatECGGraphicData = (
  graphData: IGraphicInformation,
  timeSpan: TimeSpan,
  selectedDateTime: string
): IGraphicInformation => {
  // const updatedSelectedDateTime = useSelector(
  //   (state) => state.exercise.updatedSelectedDateTime
  // );
  switch (timeSpan) {
    case TimeSpan.TODAY:
      const today = _.difference(
        emptyTodayArrayGenerator(),
        JSON.parse(graphData?.value).map((val) => val?.marker)
      ).map(
        (x) =>
          JSON.parse(graphData?.value).find((y) => y?.marker === x?.marker) || x
      );
      return {
        ...graphData,
        values: today
      };
    case TimeSpan.WEEK:
      const wkly = _.difference(
        emptyWeeklyArrayGenerator(),
        JSON.parse(graphData?.value).map((val) => val?.marker)
      ).map(
        (x) =>
          JSON.parse(graphData?.value).find((y) => y?.marker === x?.marker) || x
      );
      return {
        ...graphData,
        values: wkly
      };
    case TimeSpan.MONTH:
      const mnthly = _.difference(
        emptyMonthlyArrayGenerator(selectedDateTime),
        JSON.parse(graphData?.value)?.map((val) => val?.marker)
      ).map(
        (x) =>
          JSON.parse(graphData?.value)?.find((y) => y?.marker === x?.marker) ||
          x
      );

      return {
        ...graphData,
        values: mnthly
      };
    case TimeSpan.YEAR:
      const yearly = _.difference(
        emptyYearlyArrayGenerator(),
        JSON.parse(graphData?.value).map((val) => val?.marker)
      ).map(
        (x) =>
          JSON.parse(graphData?.value).find((y) => y?.marker === x?.marker) || x
      );
      return {
        ...graphData,
        values: yearly
      };
    default:
      throw new Error('Unknown Time Span');
  }
};

export const validateBpGraphDataObjectArray = (data: {
  sysValues: IValue[];
  diasValues: IValue[];
  heartRateValues: IValue[];
}): boolean => {
  return (
    data.sysValues.length === data.diasValues.length &&
    data.sysValues.length > 0 &&
    data.heartRateValues.length > 0
  );
};

export const validateMultipleGraphData = (
  data: IFormattedGraphicData[][]
): boolean => {
  return (
    data[0] &&
    data[0].length > 0 &&
    data[1] &&
    data[1].length > 0 &&
    data[2] &&
    data[2].length > 0
  );
};

export const validateBloodPressureGraphicData = (
  bpSysValues: IFormattedGraphicData[],
  bpDiasValues: IFormattedGraphicData[],
  bpHeartRateValues: IFormattedGraphicData[]
): _FormattedBpGraphDataCard[] | undefined => {
  const isValidMultipleGraphData = validateMultipleGraphData([
    bpSysValues,
    bpDiasValues,
    bpHeartRateValues
  ]);

  if (isValidMultipleGraphData) {
    const isValidGraphDataObjectArray = validateBpGraphDataObjectArray(
      combineBloodPressureCardValues([
        bpSysValues,
        bpDiasValues,
        bpHeartRateValues
      ])
    );

    if (isValidGraphDataObjectArray) {
      const { sysValues, diasValues, heartRateValues } =
        combineBloodPressureCardValues([
          bpSysValues,
          bpDiasValues,
          bpHeartRateValues
        ]);
      return sysValues
        .map((_x, i) => {
          return {
            sysValue: sysValues[i].value,
            diasValue: diasValues[i].value,
            heartRateValue: heartRateValues[i]
              ? heartRateValues[i].value
              : heartRateValues[0].value,
            diasId: diasValues[i].id,
            dateTime: diasValues[i].dateTime,
            sysId: sysValues[i].id,
            heartRateId: heartRateValues[i]
              ? heartRateValues[i].id
              : heartRateValues[0].id,
            sysDevice: sysValues[i].device,
            sysNote: sysValues[i].note
          };
        })
        .sort(
          (a, b) =>
            new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime()
        );
    }
  }
};

export const formatSingleGraphicData = (
  graphicData: IFormattedGraphicData[]
): IValue[] => {
  const singleGraphicValues: IValue[] = [];
  graphicData?.forEach((x) =>
    x?.values?.forEach((y) => singleGraphicValues.push(y))
  );
  return singleGraphicValues.sort(
    (a, b) =>
      new Date(b?.dateTime)?.getTime() - new Date(a?.dateTime)?.getTime()
  );
};

export const fillWeeklyGraphData = (
  graphicDataValues: IFormattedGraphicData[],
  dataToCompare: IFormattedGraphicData
): IFormattedSortableGraphicData[] => {
  return graphicDataValues.map((x) => {
    if (x.values.length > 0) {
      return {
        ...x,
        sortValue: new Date(x.values[0].dateTime).getTime()
      };
    }
    const weekDayAbs =
      getWeekDayValue(dataToCompare.marker) - getWeekDayValue(x.marker);
    let _sortValue: number;
    if (weekDayAbs > 0) {
      _sortValue = Number(
        moment(dataToCompare.values[0].dateTime)
          .subtract(weekDayAbs, 'days')
          .format('x')
      );
    } else {
      _sortValue = Number(
        moment(dataToCompare.values[0].dateTime)
          .add(Math.abs(weekDayAbs), 'days')
          .format('x')
      );
    }
    return {
      ...x,
      sortValue: _sortValue
    };
  });
};

export const getWeekDayValue = (weekDay: string): number => {
  switch (weekDay) {
    case WEEKLY.MONDAY:
      return 1;
    case WEEKLY.TUESDAY:
      return 2;
    case WEEKLY.WEDNESDAY:
      return 3;
    case WEEKLY.THURSDAY:
      return 4;
    case WEEKLY.FRIDAY:
      return 5;
    case WEEKLY.SATURDAY:
      return 6;
    case WEEKLY.SUNDAY:
      return 7;
    default:
      break;
  }
};

export const getMinMaxDateForWeeklyData = (
  graphicData: IFormattedSortableGraphicData[] | undefined
): string => {
  if (graphicData) {
    return (
      moment(graphicData[0].sortValue).format('DD MMMM YYYY') +
      ' - ' +
      moment(graphicData[graphicData.length - 1].sortValue).format(
        'DD MMMM YYYY'
      )
    );
  }
  return (
    moment().startOf('week').format('D') +
    ' - ' +
    moment().endOf('week').format('DD MMMM YYYY')
  );
};

export const handleTimeSpanDateChange = (
  timeSpan: TimeSpan,
  dateTime: string,
  isNext: boolean
): string => {
  switch (timeSpan) {
    case TimeSpan.MONTH:
      if (isNext) {
        return moment(dateTime).add(30, 'days').startOf('day').toISOString();
      }
      return moment(dateTime).subtract(30, 'days').startOf('day').toISOString();
    case TimeSpan.TODAY:
      if (isNext) {
        return moment(dateTime).add(1, 'day').toISOString();
      }
      return moment(dateTime).subtract(1, 'day').toISOString();
    case TimeSpan.YEAR:
      if (isNext) {
        return moment(dateTime).add(1, 'year').startOf('year').toISOString();
      }
      return moment(dateTime).subtract(1, 'year').startOf('year').toISOString();
    case TimeSpan.WEEK:
      if (isNext) {
        return moment(dateTime).add(1, 'week').toISOString();
      }
      return moment(dateTime).subtract(1, 'week').toISOString();

    case TimeSpan.LATEST:
      if (isNext) {
        return moment(dateTime).add(1, 'day').toISOString();
      }
      return moment(dateTime).subtract(1, 'day').toISOString();
    default:
      throw new Error('Unknown timeSpan');
  }
};

export const getGraphicDataCreatedAtParam = (
  minDate: string,
  maxDate: string
): string => {
  return `le${maxDate}&ge${minDate}`;
};

const getMonthlyDiffStiffness = (data, selectedDateTime) => {
  const diff = _.difference(
    emptyMonthlyStiffness(selectedDateTime),
    data.values.stiffness.map((val) => val.marker)
  ).map((x) => data.values.stiffness.find((y) => y.marker === x.marker) || x);

  // const sortedDiff = [
  //   ...data.values.stiffness.map((val) => {
  //     if (val.marker === diff.map((i) => Number(i.marker))) {
  //       return {
  //         marker: val.marker,
  //         values: val.values,
  //         min: val.min,
  //         max: val.max,
  //         averageOnTime: val.averageOnTime,
  //         name: 'Stiffness',
  //         color: 'y'
  //       };
  //     }
  //   }),
  //   ...diff
  // ].sort((a, b) => a.marker - b.marker);

  data.values.stiffness.map((val) => {
    if (val.marker !== diff.map((i) => Number(i.marker))) {
      diff.push({
        marker: val.marker,
        values: val.values,
        min: val.min,
        max: val.max,
        averageOnTime: val.averageOnTime,
        name: 'Stiffness',
        color: 'y'
      });
    }
  });

  const sortedDiff = diff.sort((a, b) => a.marker - b.marker);
  const final = sortedDiff.map((data) => {
    return data.values.flatMap((x) => {
      return x.value.value;
    });
  });

  const marker = getMarker(sortedDiff);

  return { final, marker };
};

const getMonthlyDiffPain = (data, selectedDateTime) => {
  const diff = _.difference(
    emptyMonthlyPain(selectedDateTime),
    data.values.pain.map((val) => val.marker)
  ).map((x) => data.values.pain.find((y) => y.marker === x.marker) || x);

  data.values.pain.map((val) => {
    if (val.marker !== diff.map((i) => Number(i.marker))) {
      diff.push({
        marker: val.marker,
        values: val.values,
        min: val.min,
        max: val.max,
        averageOnTime: val.averageOnTime,
        name: 'Pain',
        color: 'y'
      });
    }
  });

  const sortedDiff = diff.sort((a, b) => a.marker - b.marker);

  const final = sortedDiff.map((data) => {
    return data.values.flatMap((x) => {
      return x.value.value;
    });
  });

  return final;
};
const getMonthlyDiffActivity = (data, selectedDateTime) => {
  const diff = _.difference(
    emptyMonthlyActivity(selectedDateTime),
    data.values.activity.map((val) => val.marker)
  ).map((x) => data.values.activity.find((y) => y.marker === x.marker) || x);

  data.values.activity.map((val) => {
    if (val.marker !== diff.map((i) => Number(i.marker))) {
      diff.push({
        marker: val.marker,
        values: val.values,
        min: val.min,
        max: val.max,
        averageOnTime: val.averageOnTime,
        name: 'Activity',
        color: 'y'
      });
    }
  });

  const sortedDiff = diff.sort((a, b) => a.marker - b.marker);

  const final = sortedDiff.map((data) => {
    return data.values.flatMap((x) => {
      return x.value.value;
    });
  });

  return final;
};
const getMonthlyDiffQoL = (data, selectedDateTime) => {
  const diff = _.difference(
    emptyMonthlyQoL(selectedDateTime),
    data.values.qualityOfLife.map((val) => val.marker)
  ).map(
    (x) => data.values.qualityOfLife.find((y) => y.marker === x.marker) || x
  );

  data.values.qualityOfLife.map((val) => {
    if (val.marker !== diff.map((i) => Number(i.marker))) {
      diff.push({
        marker: val.marker,
        values: val.values,
        min: val.min,
        max: val.max,
        averageOnTime: val.averageOnTime,
        name: 'Quality of Life',
        color: 'y'
      });
    }
  });

  const sortedDiff = diff.sort((a, b) => a.marker - b.marker);

  const final = sortedDiff.map((data) => {
    return data.values.flatMap((x) => {
      return x.value.value;
    });
  });

  return final;
};

const getWeeklyDiffStiffness = (data) => {
  const diff = _.difference(
    emptyWeeklyArrayGenerator(),
    data?.values.stiffness.map((val) => val?.marker)
  ).map(
    (x) => data?.values.stiffness.find((y) => y?.marker === x?.marker) || x
  );

  const final = diff.reverse();
  const marker = formattedWeeklyMarker(getMarker(final));

  return { final, marker };
};

const getWeeklyDiffPain = (data) => {
  const diff = _.difference(
    emptyWeeklyArrayGenerator(),
    data?.values.pain.map((val) => val?.marker)
  ).map((x) => data?.values.pain.find((y) => y?.marker === x?.marker) || x);

  const final = diff.reverse();

  return final;
};

const getWeeklyDiffActivity = (data) => {
  const diff = _.difference(
    emptyWeeklyArrayGenerator(),
    data?.values.activity.map((val) => val?.marker)
  ).map((x) => data?.values.activity.find((y) => y?.marker === x?.marker) || x);

  const final = diff.reverse();

  return final;
};

const getWeeklyDiffQoL = (data) => {
  const diff = _.difference(
    emptyWeeklyArrayGenerator(),
    data?.values.qualityOfLife.map((val) => val?.marker)
  ).map(
    (x) => data?.values.qualityOfLife.find((y) => y?.marker === x?.marker) || x
  );

  const final = diff.reverse();
  return final;
};

const getMarker = (data) => {
  let marker = [];

  data.map((x) => marker.push(convertWeeklyMarkerLanguage(x.marker)));
  return marker;
};

const convertWeeklyMarkerLanguage = (marker: string) => {
  switch (marker) {
    case 'Donnerstag':
      return 'Thursday';
    case 'Freitag':
      return 'Friday';
    case 'Samstag':
      return 'Saturday';
    case 'Sonntag':
      return 'Sunday';
    case 'Montag':
      return 'Monday';
    case 'Dienstag':
      return 'Tuesday';
    case 'Mittwoch':
      return 'Wednesday';
    default:
      return marker;
  }
};

export const finalValueOfQuestionnaire = (
  questionnaireGraphData,
  selectedTimeSpan,
  selectedDateTime
) => {
  const formattedQuestionnaireData = getQuestionnaireGraphData(
    questionnaireGraphData,
    selectedTimeSpan,
    selectedDateTime
  );
  const questionnaireData = {
    labels: formattedQuestionnaireData?.marker,
    datasets: [
      {
        label: 'Stiffness',
        data: formattedQuestionnaireData?.stiffness,
        fill: false,
        showLine: true,
        borderColor: '#DCDFEA',
        lineTension: 0.4,
        pointRadius: 4,
        pointHoverRadius: 4,
        backgroundColor: '#1D9DE6',
        barPercentage: 0.6,
        tooltipDate: formattedQuestionnaireData.marker,
        timeSpan: selectedTimeSpan
      },
      {
        label: 'Pain',

        data: formattedQuestionnaireData?.pain,
        fill: false,
        showLine: true,
        borderColor: '#DCDFEA',
        lineTension: 0.4,
        pointRadius: 4,
        pointHoverRadius: 4,
        barPercentage: 0.6,
        tooltipDate: formattedQuestionnaireData.marker,
        backgroundColor: '#F8961E',
        timeSpan: selectedTimeSpan
      },
      {
        label: 'Activity',
        timeSpan: selectedTimeSpan,
        data: formattedQuestionnaireData?.activity,
        fill: false,
        showLine: true,
        borderColor: '#DCDFEA',
        lineTension: 0.4,
        pointRadius: 4,
        pointHoverRadius: 4,
        barPercentage: 0.6,
        tooltipDate: formattedQuestionnaireData.marker,

        backgroundColor: '#6E759F'
      },
      {
        label: 'Quality of Life',
        timeSpan: selectedTimeSpan,
        data: formattedQuestionnaireData?.QoL,
        fill: false,
        showLine: true,
        borderColor: '#DCDFEA',
        lineTension: 0.4,
        pointRadius: 4,
        pointHoverRadius: 4,
        backgroundColor: '#F0C138',
        barPercentage: 0.6,
        tooltipDate: formattedQuestionnaireData.marker
      }
    ],
    timeSpan: selectedTimeSpan
  };
  // console.log(JSON.stringify(questionnaireData), 'QUESTİONNARIE STRINGFY');
  // console.log(questionnaireData, 'QUESTİONNARIE');
  return questionnaireData;
};

export const getQuestionnaireGraphData = (
  data,
  selectedTimeSpan,
  selectedDateTime
) => {
  let marker = [];

  if (data.avarage !== 0 || data || data.avarage !== undefined) {
    switch (selectedTimeSpan) {
      case TimeSpan.MONTH:
        const diffStiffness = getMonthlyDiffStiffness(data, selectedDateTime);
        const diffPain = getMonthlyDiffPain(data, selectedDateTime);
        const diffActivity = getMonthlyDiffActivity(data, selectedDateTime);
        const diffQoL = getMonthlyDiffQoL(data, selectedDateTime);
        marker = diffStiffness.marker;

        return {
          stiffness: [...diffStiffness?.final.flatMap((x) => Number(x))],
          pain: [...diffPain?.flatMap((x) => Number(x))],
          activity: [...diffActivity?.flatMap((x) => Number(x))],
          QoL: [...diffQoL?.flatMap((x) => Number(x))],
          marker
        };

      case TimeSpan.WEEK:
        const diffStiffnessWeekly = getWeeklyDiffStiffness(data);
        const diffPainWeekly = getWeeklyDiffPain(data);
        const diffActivityWeekly = getWeeklyDiffActivity(data);
        const diffQoLWeekly = getWeeklyDiffQoL(data);
        marker = diffStiffnessWeekly.marker;

        return {
          stiffness: [
            ...diffStiffnessWeekly?.final.flatMap((x) => x.averageOnTime)
          ],
          pain: [...diffPainWeekly?.flatMap((x) => x.averageOnTime)],
          activity: [...diffActivityWeekly?.flatMap((x) => x.averageOnTime)],
          QoL: [...diffQoLWeekly?.flatMap((x) => x.averageOnTime)],
          marker
        };
    }
  }
};

export const observationMonthlyGraphFactory = (data: IPainLevelGraph) => {
  let diff = _.difference(
    emptyMonthlyPainLevel(),
    data.values.map((val) => val.marker)
  ).map((x) => data.values.find((y) => y.marker === x?.marker) || x);

  data.values.map((val) => {
    if (!diff.some((elem) => elem.marker === val.marker)) {
      diff.push({
        avarageOnTime: val.averageOnTime,
        marker: val.marker,
        max: val.max,
        min: val.min,
        patientId: data.patientId,
        values: val.values
      });
    }
  });

  const sortedDiff = diff.sort((a, b) => a.marker - b.marker);
  const final = sortedDiff.map((data) => {
    return data.avarageOnTime || data.averageOnTime;
  });

  const marker = getMarker(sortedDiff);
  const scatter = [];
  sortedDiff.map((item) => {
    item.values.map((val, index) => {
      if (item.values.length === 1) {
        scatter.push({
          x: item.marker.slice(0, 2),
          y: val.value,
          order: 0,
          dateTime: val.dateTime
        });
      } else {
        scatter.push({
          x: item.marker.slice(0, 2),
          y: val.value,
          order: index + 1,
          dateTime: val.dateTime
        });
      }
    });
  });

  return { final, marker, scatter };
};

export const observationWeeklyGraphFactory = (
  data: IPainLevelGraph,
  newCreatedAt
) => {
  const diff = _.difference(
    emptyWeeklyArrayGenerator(newCreatedAt),
    data.values.map((val) => val?.marker)
  ).map((x) => data?.values.find((y) => y?.marker === x?.marker) || x);

  const reversed = diff.reverse();
  const marker = formattedWeeklyMarker(getMarker(reversed));

  const final = [...reversed.map((data) => data.averageOnTime)];

  const scatter = [];
  reversed.map((item) => {
    item.values.map((val, index) => {
      if (item.values.length === 1) {
        scatter.push({
          x: convertWeeklyMarkerLanguage(item.marker).slice(0, 2),
          y: val.value,
          order: 0,
          dateTime: val.dateTime
        });
      } else {
        scatter.push({
          x: convertWeeklyMarkerLanguage(item.marker).slice(0, 2),
          y: val.value,
          order: index + 1,
          dateTime: val.dateTime
        });
      }
    });
  });

  return { final, marker, scatter };
};

export const acuteWorkloadMonthlyGraphFactory = (data: IPainLevelGraph) => {
  const diff = _.difference(
    emptyMonthlyAcuteWorkload(),
    data.values.map((val) => val.marker)
  ).map((x) => data.values.find((y) => y.marker === x?.marker) || x);

  data.values.map((val) =>
    diff.push({
      avarageOnTime: val.averageOnTime,
      marker: val.marker,
      max: val.max,
      min: val.min,
      patientId: data.patientId,
      values: val.values
    })
  );

  const reverse = diff.reverse();
  const sortedDiff = reverse.sort((a, b) => a.marker - b.marker);
  const prevFinal = [...sortedDiff.map((data) => data.values)];
  const final = prevFinal.map((item) => {
    return item.length === 0
      ? undefined
      : item.map((k) => {
          // if (k.value.acwr && k.value.sessionTime !== 0) {
          //   return k.value;
          // }
          return k.value;
        })[item.length - 1];
  });
  const summaryLine = [];
  for (let index = 0; index < final.length; index++) {
    //@ts-ignore
    summaryLine.push(data?.latestMeasuredValue?.ctl);
  }

  const marker = getMarker(sortedDiff);

  return { final, marker, line: summaryLine };
};

export const acuteWorkloadWeeklyGraphFactory = (
  data: IPainLevelGraph,
  timeInterval: string
) => {
  const diff = _.difference(
    emptyWeeklyArrayGenerator(timeInterval),
    data.values.map((val) => val?.marker)
  ).map((x) => data?.values.find((y) => y?.marker === x?.marker) || x);

  const reversed = diff.reverse();
  const marker = formattedWeeklyMarker(getMarker(reversed));

  const prevFinal = [...reversed.map((data) => data.values)];

  const final = prevFinal.map((item) => {
    return item.length === 0
      ? undefined
      : item.map((k) => {
          // if (k.value.acwr && k.value.sessionTime !== 0) {
          //   return k.value;
          // }
          return k.value;
        })[item.length - 1];
  });

  const summaryLine = [];
  for (let index = 0; index < final.length; index++) {
    //@ts-ignore
    summaryLine.push(data?.latestMeasuredValue?.ctl);
  }

  return { final, marker, line: summaryLine };
};

export const observationGraphFactory = (
  data,
  selectedTimeSpan: TimeSpan,
  newCreatedAt
) => {
  switch (selectedTimeSpan) {
    case TimeSpan.MONTH:
      return observationMonthlyGraphFactory(data);

    case TimeSpan.WEEK:
      return observationWeeklyGraphFactory(data, newCreatedAt);
  }
};

export const acuteWorkloadGraphFactory = (
  data,
  selectedTimeSpan: TimeSpan,
  timeInterval: string
) => {
  switch (selectedTimeSpan) {
    case TimeSpan.MONTH:
      return acuteWorkloadMonthlyGraphFactory(data);

    case TimeSpan.WEEK:
      return acuteWorkloadWeeklyGraphFactory(data, timeInterval);
  }
};

export const formattedWeeklyMarker = (marker: string[]) => {
  let formattedMarker = [];
  marker.map((row) => {
    switch (row) {
      case 'Monday':
        return formattedMarker.push('Mo');

      case 'Tuesday':
        return formattedMarker.push('Tu');

      case 'Wednesday':
        return formattedMarker.push('We');

      case 'Thursday':
        return formattedMarker.push('Th');

      case 'Friday':
        return formattedMarker.push('Fr');

      case 'Saturday':
        return formattedMarker.push('Sa');

      default:
        return formattedMarker.push('Su');
    }
  });

  return formattedMarker;
};

export const numberToMonth = (val: number): string => {
  switch (val) {
    case 1:
      return 'January ';
    case 2:
      return 'February';
    case 3:
      return 'March';
    case 4:
      return 'April';
    case 5:
      return 'May';
    case 6:
      return 'June';
    case 7:
      return 'July';
    case 8:
      return 'August';
    case 9:
      return 'September';
    case 10:
      return 'October';
    case 11:
      return 'November';
    case 12:
      return 'December';
    default:
      return 'January';
  }
};

export const shortWeekDayToLong = (marker: string) => {
  switch (marker) {
    case 'Mo':
      return 'Monday';

    case 'Tu':
      return 'Tuesday';

    case 'We':
      return 'Wednesday';

    case 'Th':
      return 'Thursday';

    case 'Fr':
      return 'Friday';

    case 'Sa':
      return 'Saturday';

    case 'Su':
      return 'Sunday';
    default:
      return 'Avarage';
  }
};

export const formatValueToResult = (
  value: number,
  typeKey: MeasurementTypeKey
) => {
  if (typeKey === MeasurementTypeKey.mood) {
    if ((value as number) >= 1 && (value as number) < 2) {
      return 'Horrible';
    }
    if ((value as number) >= 2 && (value as number) < 3) {
      return 'Bad';
    }
    if ((value as number) >= 3 && (value as number) < 4) {
      return 'Neutral';
    }

    if ((value as number) >= 4 && (value as number) < 5) {
      return 'Good';
    }

    if ((value as number) >= 5) {
      return 'Great';
    } else {
      return '    --';
    }
  } else if (typeKey === MeasurementTypeKey.sleepQuality) {
    if ((value as number) >= 1 && (value as number) < 2) {
      return 'Very Restful';
    }
    if ((value as number) >= 2 && (value as number) < 3) {
      return 'Good';
    }
    if ((value as number) >= 3 && (value as number) < 4) {
      return 'Difficult';
    }

    if ((value as number) >= 4 && (value as number) < 5) {
      return 'Restless';
    }

    if ((value as number) >= 5) {
      return 'Insomnia';
    } else {
      return '    --';
    }
  } else if (typeKey === MeasurementTypeKey.muscleSoreness) {
    if ((value as number) >= 1 && (value as number) < 2) {
      return 'Feeling great';
    }
    if ((value as number) >= 2 && (value as number) < 3) {
      return 'Feeling good';
    }
    if ((value as number) >= 3 && (value as number) < 4) {
      return 'Normal';
    }

    if ((value as number) >= 4 && (value as number) < 5) {
      return 'Sore';
    }

    if ((value as number) >= 5) {
      return 'Very Sore';
    } else {
      return '    --';
    }
  } else if (typeKey === MeasurementTypeKey.stressLevel) {
    if ((value as number) >= 1 && (value as number) < 2) {
      return 'Very Relaxed';
    }
    if ((value as number) >= 2 && (value as number) < 3) {
      return 'Relaxed';
    }
    if ((value as number) >= 3 && (value as number) < 4) {
      return 'Normal';
    }

    if ((value as number) >= 4 && (value as number) < 5) {
      return 'Stressed';
    }

    if ((value as number) >= 5) {
      return 'Highly Stressed';
    } else {
      return '    --';
    }
  } else if (typeKey === MeasurementTypeKey.fatigue) {
    if ((value as number) >= 1 && (value as number) < 2) {
      return 'Very fresh';
    }
    if ((value as number) >= 2 && (value as number) < 3) {
      return 'Fresh';
    }
    if ((value as number) >= 3 && (value as number) < 4) {
      return 'Normal';
    }

    if ((value as number) >= 4 && (value as number) < 5) {
      return 'More tired';
    }

    if ((value as number) >= 5) {
      return 'Always tired';
    } else {
      return '    --';
    }
  } else {
    if ((value as number) >= 0 && (value as number) <= 1) {
      return 'No Pain';
    }
    if ((value as number) > 1 && (value as number) <= 3) {
      return 'Mild';
    }
    if ((value as number) > 3 && (value as number) <= 5) {
      return 'Moderate';
    }

    if ((value as number) > 5 && (value as number) <= 7) {
      return 'Severe';
    }

    if ((value as number) > 7 && (value as number) <= 9) {
      return 'Very Severe';
    }

    if ((value as number) > 9 && (value as number) <= 10) {
      return 'Worst';
    } else {
      return '    --';
    }
  }
};

export const formatValueToPainLevelTitle = (
  val: number
): string | undefined => {
  if ((val as number) >= 0 && (val as number) <= 1) {
    return 'No Pain';
  }
  if ((val as number) > 1 && (val as number) <= 3) {
    return 'Mild';
  }
  if ((val as number) > 3 && (val as number) <= 5) {
    return 'Moderate';
  }

  if ((val as number) > 5 && (val as number) <= 7) {
    return 'Severe';
  }

  if ((val as number) > 7 && (val as number) <= 9) {
    return 'Very Severe';
  }

  if ((val as number) > 9 && (val as number) <= 10) {
    return 'Worst';
  } else {
    return '    --';
  }
};

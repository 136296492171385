import { TFunction } from 'i18next';
// import { IUserLocation } from "../services/middleware/types/user/IUserLocation";
// import Geolocation from "@react-native-community/geolocation";
// import { MeasureUnitTypes, MetricSystem } from "src/infrastructure/measure-unit/types";
// import store from "src/store";
import { PatientName } from '../models/patient/PatientName';
// import { IsJsonString } from "./Validations";

// export const getUserLocation = async (): Promise<IUserLocation> => {
//   let _userLocation: IUserLocation | undefined;
//   return new Promise((resolve, reject) => {
//     Geolocation.getCurrentPosition(
//       (position) => {
//         _userLocation = {
//           latitude: position.coords.latitude,
//           longitude: position.coords.longitude,
//         };
//         resolve(_userLocation);
//       },
//       (error) => reject(error),
//     );
//   });
// };

export const getNameSurname = (name: PatientName): string => {
  if (
    name.family !== 'undefined' &&
    name.family !== undefined &&
    name.family != null &&
    name.family !== 'null'
  ) {
    return name.given + ' ' + name.family;
  }
  return name.given;
};

// export const getCurrentUserMeasureUnits = (): MeasureUnitTypes => {
//   const implicitRules = "store.dispatch(get)";
//   if (implicitRules && IsJsonString(implicitRules)) {
//     return JSON.parse(implicitRules) as MeasureUnitTypes;
//   } else {
//     return {xs
//       temperature: MetricSystem.temperature,
//       weight: MetricSystem.weight,
//       height: MetricSystem.height,
//       distance: MetricSystem.distance,
//       bloodGlucose: MetricSystem.bloodGlucose,
//     };
//   }
// };

export const formatGenderi18Code = (gender: string, t: TFunction) => {
  switch (gender) {
    case 'male':
      return t('Male');
    case 'female':
      return t('Female');
    case 'other':
      return t('Other');

    default:
      return t('Other');
  }
};

/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable jsx-a11y/alt-text */
import { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Grid, TextField, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AddCircle } from 'iconsax-react';
import React from 'react';
import { useDispatch, useSelector } from 'src/store';
import slice from 'src/slices/exercise';
import _ from 'lodash';
import { QuestionnaireItem } from '@actimi/core-migration';
import { batch } from 'react-redux';
interface AlertsTabProps {
  isDisabledText?: boolean;
  questionnaireData: QuestionnaireItem;
  isEdit?: boolean;
}

const AlertsTab: FC<AlertsTabProps> = ({ isDisabledText, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const CHARACTER_LIMIT = 80;
  const { tipInputs, attentionInputs } = useSelector((state) => state.exercise);
  const [indexAttention, setIndexAttention] = React.useState(0);
  const [indexTip, setIndexTip] = React.useState(0);
  const handleServiceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const list = [...attentionInputs];
    const cloneDeepList = _.cloneDeep(list);
    cloneDeepList[indexAttention][name] = value;
    dispatch(slice.actions.setInputsOfAttention(cloneDeepList));
  };

  const handleServiceAdd = () => {
    dispatch(
      slice.actions.setInputsOfAttention([
        ...attentionInputs,
        { attention: '' }
      ])
    );
  };

  React.useEffect(() => {
    const attention = [];
    const tip = [];
    if (props.isEdit) {
      props?.questionnaireData?.extension
        ?.flatMap((item) => {
          if (item.url === 'http://extension.actimi.com/exercise-assistant') {
            return item.extension.map((value) => {
              if (
                value.url ===
                'http://extension.actimi.com/exercise-assistant-instructions'
              ) {
                value.extension[1]?.valueString
                  .split('-')
                  .map((item, index) => {
                    attention.push({ attention: item });
                  });
                value.extension[2]?.valueString
                  .split('-')
                  .map((item, index) => {
                    tip.push({ tip: item });
                  });
              }
            });
          }
        })
        .filter((x) => x !== undefined);

      batch(() => {
        dispatch(slice.actions.setInputsOfAttention(attention));
        dispatch(slice.actions.setInputsOfTip(tip));
      });
    }
  }, []);

  const handleTipsAdd = () => {
    dispatch(slice.actions.setInputsOfTip([...tipInputs, { tip: '' }]));
  };

  const handleTipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const list = [...tipInputs];
    const cloneDeepList = _.cloneDeep(list);

    cloneDeepList[indexTip][name] = value;
    dispatch(slice.actions.setInputsOfTip(cloneDeepList));
  };

  return (
    <Grid container mx={2} className={classes.gridContainer}>
      <Grid item sx={{ width: '100%' }} mx={2}>
        {!isDisabledText || props.isEdit
          ? attentionInputs.map((row, index) => {
              return (
                <Box my={3} key={index}>
                  <TextField
                    name="attention"
                    id="outlined-basic"
                    label={t('Attentions')}
                    variant="outlined"
                    className={classes.inputContainer}
                    sx={{
                      width: {
                        xs: '75vw',
                        sm: '80vw',
                        md: '70vw',
                        lg: '60vw',
                        xl: '30vw'
                      }
                    }}
                    value={row.attention}
                    onFocus={() => setIndexAttention(index)}
                    onChange={handleServiceChange}
                  />
                </Box>
              );
            })
          : props?.questionnaireData?.extension
              ?.flatMap((item) => {
                if (
                  item.url === 'http://extension.actimi.com/exercise-assistant'
                ) {
                  return item.extension.map((value) => {
                    if (
                      value.url ===
                      'http://extension.actimi.com/exercise-assistant-instructions'
                    ) {
                      return value.extension[1]?.valueString
                        .split('-')
                        .map((item, index) => {
                          return (
                            <Box my={3} key={index}>
                              <TextField
                                disabled
                                name="service"
                                id="outlined-basic"
                                label={t('Attention')}
                                className={classes.inputContainer}
                                variant="outlined"
                                sx={{
                                  width: {
                                    xs: '75vw',
                                    sm: '80vw',
                                    md: '70vw',
                                    lg: '60vw',
                                    xl: '30vw'
                                  }
                                }}
                                value={item}
                              />
                            </Box>
                          );
                        });
                    }
                  });
                }
              })
              .filter((x) => x !== undefined)}

        <Box>
          <Button
            startIcon={<AddCircle size="20" color="#FFFF" />}
            onClick={handleServiceAdd}
            sx={{
              width: 175,
              color: '#ffffff',
              visibility: isDisabledText ? 'hidden' : 'visible',
              backgroundColor: 'primary'
            }}
            color="primary"
            variant="contained"
          >
            {t('Add new row')}
          </Button>
        </Box>
      </Grid>
      <Grid item sx={{ width: '100%' }} mx={2}>
        {!isDisabledText || props.isEdit
          ? tipInputs.map((row, index) => {
              return (
                <Box my={3} key={index}>
                  <TextField
                    name="tip"
                    id="outlined-basic"
                    label={t('Tips')}
                    className={classes.inputContainer}
                    variant="outlined"
                    sx={{
                      width: {
                        xs: '75vw',
                        sm: '80vw',
                        md: '70vw',
                        lg: '60vw',
                        xl: '30vw'
                      }
                    }}
                    value={row.tip}
                    onFocus={() => setIndexTip(index)}
                    onChange={handleTipChange}
                  />
                </Box>
              );
            })
          : props?.questionnaireData?.extension
              ?.flatMap((item) => {
                if (
                  item.url === 'http://extension.actimi.com/exercise-assistant'
                ) {
                  return item.extension.map((value) => {
                    if (
                      value.url ===
                      'http://extension.actimi.com/exercise-assistant-instructions'
                    ) {
                      return value.extension[2]?.valueString
                        .split('-')
                        .map((item, index) => {
                          return (
                            <Box my={3} key={index}>
                              <TextField
                                disabled
                                name="service"
                                id="outlined-basic"
                                label={t('Tips')}
                                variant="outlined"
                                className={classes.inputContainer}
                                sx={{
                                  width: {
                                    xs: '75vw',
                                    sm: '80vw',
                                    md: '70vw',
                                    lg: '60vw',
                                    xl: '30vw'
                                  }
                                }}
                                value={item}
                              />
                            </Box>
                          );
                        });
                    }
                  });
                }
              })
              .filter((x) => x !== undefined)}

        <Box>
          <Button
            startIcon={<AddCircle size="20" color="#FFFF" />}
            onClick={handleTipsAdd}
            sx={{
              width: 175,
              color: '#ffffff',
              visibility: isDisabledText ? 'hidden' : 'visible',
              backgroundColor: 'primary'
            }}
            color="primary"
            variant="contained"
          >
            {t('Add new row')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AlertsTab;

const useStyles = makeStyles(() => ({
  gridContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
    padding: 0
  },
  inputContainer: {
    maxWidth: '100%',
    width: '100%'
  }
}));

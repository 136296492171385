import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Box, Typography, IconButton } from '@mui/material';
import React from 'react';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

import { useTranslation } from 'react-i18next';
import TableUserBox from 'src/components/Base/TableUserBox';
import CheckboxButton from 'src/components/Base/Checkbox';
import { Flag as FlagIcon, MessageText1, Edit2 } from 'iconsax-react';
import Label from 'src/components/Base/Label';

export interface IRowData {
  id: string; //Also userId
  name: {
    name: string;
    gender: string;
    birthdate: string;
    onClick: (patientId: string) => void;
  };
  gender: {
    value: string;
  };

  healthProblem: string;
  enrollmentDate: string;
  endDate: {
    value: string;
  };
  // consent: {
  //   isActive: boolean;
  // };
  actions: {
    onEditClick: (userId: string) => void;
    onDeleteClick: (userId: string) => void;
  };
}
declare type RenderParams<V = any, R = IRowData, F = V> = GridRenderCellParams<
  V,
  R,
  F
>;
interface IRender {
  [field: string]: (params: RenderParams) => React.ReactNode;
}

const rowComparer = (prevProps, nextProps) => prevProps.row === nextProps.row;

const Flag = ({ row: { id, name } }) => (
  <CheckboxButton
    unCheckedIcon={
      <FlagIcon color="#8B92AB" size={24} style={{ cursor: 'pointer' }} />
    }
    checkedIcon={
      <FlagIcon
        color="#26BDD8"
        variant="Bold"
        size={24}
        style={{ cursor: 'pointer' }}
      />
    }
    onChange={() => {}}
  />
);
const NameSurname = ({ row: { id, name } }) => {
  return (
    <TableUserBox
      name={name.name}
      gender={name.gender}
      birthdate={name.birthdate}
      onClick={() => name.onClick(id)}
    />
  );
};

const HealthProblem = ({ row }) => {
  return <Typography variant="body1">{row.healthProblem}</Typography>;
};

const Enrollment = ({ row: { enrollmentDate } }) => {
  return <Typography variant="body1">{enrollmentDate}</Typography>;
};

// const Consent = ({ row: { id, consent } }) =>
//   consent.isActive ? (
//     <TickCircle color="#5AD0A6" />
//   ) : (
//     <CloseCircle color="#FC6262" />
//   );

const Actions = ({ row: { id, actions: a } }: { row: IRowData }) => {
  console.log(id, 'ROssö');
  return (
    <Box display="contents" sx={{ '& > *': { ml: 1 } }}>
      <IconButton
        onClick={() => (window.location.href = `/applications/messenger/${id}`)}
        sx={{ marginRight: 2 }}
      >
        <MessageText1 style={{ cursor: 'pointer' }} />
      </IconButton>
      <Box>
        <IconButton
          color="error"
          onClick={() => a.onDeleteClick(id)}
          sx={{
            borderRadius: '6px !important',
            backgroundColor: 'rgba(255, 25, 67, 0.1);',
            '&:hover': { backgroundColor: 'rgba(255, 25, 67, 0.2);' }
          }}
        >
          <DeleteOutlineOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};

const NameSurnameMemo = React.memo(NameSurname, rowComparer);
const HealthMemo = React.memo(HealthProblem, rowComparer);
const EnrollmentMemo = React.memo(Enrollment, rowComparer);
// const ConsentMemo = React.memo(Consent, rowComparer);
const ActionsMemo = React.memo(Actions, rowComparer);

const Render: IRender = {
  // flag: ({ row }) => <FlagMemo row={row} />,
  nameSurname: ({ row }) => <NameSurnameMemo row={row} />,
  healthProblem: ({ row }) => <HealthMemo row={row} />,
  enrollmentDate: ({ row }) => <EnrollmentMemo row={row} />,
  actions: ({ row }) => <ActionsMemo row={row} />
  // consent: ({ row }) => <ConsentMemo row={row} />
};

const FlagHeader = () => {
  return null;
};

const NameSurnameHeader = () => {
  const { t }: { t: any } = useTranslation();
  return <Label>{t('Name')}</Label>;
};

const HealthProblemHeader = () => {
  const { t }: { t: any } = useTranslation();
  return <Label>{t('Health Problem')}</Label>;
};

const BirthDateHeader = () => {
  const { t }: { t: any } = useTranslation();
  return <Label>{t('Birth Date')}</Label>;
};

const EnrollmentHeader = () => {
  const { t }: { t: any } = useTranslation();
  return <Label>{t('Enrollment Date')}</Label>;
};

const EndDateHeader = () => {
  const { t }: { t: any } = useTranslation();
  return <Label>{t('End Date')}</Label>;
};

// const ConsentHeader = () => {
//   const { t }: { t: any } = useTranslation();
//   return <Label>{t('Consent')}</Label>;
// };

const ActionsHeader = () => {
  const { t }: { t: any } = useTranslation();
  return <Label>{t('Actions')}</Label>;
};

const RenderHeader = {
  flag: () => <FlagHeader />,
  nameSurname: () => <NameSurnameHeader />,
  healthProblem: () => <HealthProblemHeader />,
  birthDate: () => <BirthDateHeader />,
  enrollmentDate: () => <EnrollmentHeader />,
  endDate: () => <EndDateHeader />,
  // consent: () => <ConsentHeader />,
  actions: () => <ActionsHeader />
};
export const columns: GridColDef[] = [
  {
    field: 'flag',
    headerName: 'FLAG',
    headerAlign: 'left',
    align: 'center',
    sortable: false,
    editable: false,
    filterable: false,
    width: 20,
    renderCell: Render.flag,
    renderHeader: RenderHeader.flag
  },
  {
    field: 'nameSurname',
    headerName: 'NAME SURNAME',
    headerAlign: 'left',
    align: 'center',
    type: 'string',
    flex: 1,
    sortable: false,
    renderCell: Render.nameSurname,
    renderHeader: RenderHeader.nameSurname
  },
  {
    field: 'healthProblem',
    headerName: 'Health Problem',
    headerAlign: 'center',
    align: 'center',
    type: 'string',
    flex: 1,
    sortable: false,
    renderHeader: RenderHeader.healthProblem,
    renderCell: Render.healthProblem
  },
  {
    field: 'birthDate',
    headerName: 'BIRTHDATE',
    headerAlign: 'center',
    align: 'center',
    type: 'string',
    flex: 1,
    sortable: false,
    valueGetter: (params) => params.row.name.birthdate,
    renderHeader: RenderHeader.birthDate
  },
  {
    field: 'enrollmentDate',
    headerName: 'ENROLLMENT DATE',
    headerAlign: 'center',
    align: 'center',
    type: 'string',
    flex: 1,
    sortable: false,
    renderCell: Render.enrollmentDate,
    renderHeader: RenderHeader.enrollmentDate
  },
  {
    field: 'endDate',
    headerName: 'END DATE',
    headerAlign: 'center',
    align: 'center',
    type: 'string',
    flex: 1,
    sortable: false,
    valueGetter: (params) => params.row.endDate.value,
    renderHeader: RenderHeader.endDate
  },
  // {
  //   field: 'consent',
  //   headerName: 'CONSENT',
  //   headerAlign: 'center',
  //   align: 'center',
  //   type: 'string',
  //   flex: 1,
  //   sortable: false,
  //   renderCell: Render.consent,
  //   renderHeader: RenderHeader.consent
  // },
  {
    field: 'actions',
    headerName: 'ACTIONS',
    headerAlign: 'center',
    align: 'center',
    type: 'actions',
    flex: 1,
    sortable: false,
    renderCell: Render.actions,
    renderHeader: RenderHeader.actions
  }
];

import {
  ImperialSystem,
  MeasureUnit,
  MetricSystem
} from '../models/measure/MeasureUnit';

export const getMeasureUnitTemperature = (
  measureUnit: string,
  value: number
): string => {
  if (measureUnit === MeasureUnit.imperialSystem) {
    return (value * 1.8 + 32).toString() + ' ' + ImperialSystem.temperature;
  } else {
    return value.toFixed(1).toString() + ' ' + MetricSystem.temperature;
  }
};

export const getMeasureUnitHeight = (
  measureUnit: string,
  value: number
): string => {
  if (measureUnit === MeasureUnit.imperialSystem) {
    return (
      (value * 0.393).toString().substring(0, 5) + ' ' + ImperialSystem.height
    );
  } else {
    return value.toString() + ' ' + MetricSystem.height;
  }
};

export const getMeasureUnitWeight = (
  measureUnit: string,
  value: number
): string => {
  if (measureUnit === MeasureUnit.imperialSystem) {
    return (
      (value / 2.204).toString().substring(0, 5) + ' ' + ImperialSystem.weight
    );
  } else {
    return value.toFixed(1).toString() + ' ' + MetricSystem.weight;
  }
};

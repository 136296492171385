/* eslint-disable eqeqeq */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import { MiddlewareService } from '../services/middleware/MiddlewareService';

export type IntervalValues = {
  max: number;
  min: number;
  riskScore: number;
};

export interface ILimitValueSet {
  readonly low: IntervalValues;
  readonly medium: IntervalValues;
  readonly high: IntervalValues;
}

export interface ILimitValues {
  readonly id: string;
  readonly patient: {
    resourceType: 'Patient';
    id: string;
  };
  readonly organization: {
    resourceType: 'Organization';
    id: string;
  };
  readonly bloodPressure: ILimitValueSet;
  readonly bodyWeight: ILimitValueSet;
  readonly heartRate: ILimitValueSet;
  readonly spo2: ILimitValueSet;
  readonly symptoms: ILimitValueSet;
  readonly ecg?: ILimitValueSet;
}

export interface LimitValueState {
  isLimitValuesLoading: boolean;
  limitValues: ILimitValues[];
}

const initialState: LimitValueState = {
  isLimitValuesLoading: true,
  limitValues: []
};

const slice = createSlice({
  name: 'limit-values',
  initialState,
  reducers: {
    getLimitValue(
      state: LimitValueState,
      action: PayloadAction<{
        limitValue: ILimitValues;
      }>
    ) {
      const { limitValue } = action.payload;
      if (limitValue != undefined) {
        const limitValueIndex = state.limitValues.findIndex(
          (x) => x?.id === limitValue?.id
        );
        if (limitValueIndex > -1) {
          state.limitValues.splice(limitValueIndex, 1, limitValue);
        } else {
          state.limitValues.push(limitValue);
        }
      }
    },
    setLimitValue(
      state: LimitValueState,
      action: PayloadAction<{
        limitValues: ILimitValues[];
      }>
    ) {
      const { limitValues } = action.payload;
      state.limitValues?.push(...limitValues);
      state.isLimitValuesLoading = false;
    },
    setLimitValues(
      state: LimitValueState,
      action: PayloadAction<{
        limitValues: ILimitValues[];
      }>
    ) {
      const { limitValues } = action.payload;
      state.limitValues = limitValues;
      state.isLimitValuesLoading = false;
    },
    updateLimitValue(
      state: LimitValueState,
      action: PayloadAction<{
        limitValue: ILimitValues;
      }>
    ) {
      state.isLimitValuesLoading = false;
      const { limitValue } = action.payload;
      const limitValueIndex = state.limitValues.findIndex(
        (x) => x?.id === limitValue?.id
      );
      if (limitValueIndex > -1) {
        state.limitValues.splice(limitValueIndex, 1, limitValue);
      }
    },
    reset(state: LimitValueState) {
      Object.assign(state, initialState);
    }
  }
});

export const reducer = slice.reducer;

export const reset = (): AppThunk => async (dispatch) =>
  dispatch(slice.actions.reset());

export const setLimitValue =
  (limitValues: ILimitValues[], whenDone?: () => void): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setLimitValue({ limitValues }));
    const action = whenDone;
    if (action !== undefined) {
      action();
    }
  };

export const getLimitValue =
  (
    organization: string,
    patientId: string,
    whenDone: (limitValues: ILimitValues) => void
  ): AppThunk =>
  async (dispatch) => {
    // const limitValue2 = await new LimitService().getLimitValue(
    //   organization,
    //   patientId
    // );
    const limitValue = await MiddlewareService.getLimitValue(
      organization,
      patientId
    );
    dispatch(slice.actions.getLimitValue({ limitValue }));
    whenDone(limitValue);
  };

export const updateLimitValue =
  (limitValue: Partial<ILimitValues>, whenDone?: () => void): AppThunk =>
  async (dispatch) => {
    const resp = await MiddlewareService.updateLimitValue(limitValue);
    dispatch(
      slice.actions.getLimitValue({
        limitValue: resp
      })
    );
    const action = whenDone;
    if (action !== undefined) {
      action();
    }
  };

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, AppThunk } from '../store';
import { MiddlewareService } from '../services/middleware/MiddlewareService';
import { ICareTeam } from 'src/models/care-team/ICareTeam';
import { Notifications } from 'src/layouts/SidebarLayout/Header/Buttons/Alarms';
import { NotificationFactory } from 'src/utils/NotificationFactory';
import _ from 'lodash';
import { IUserListItem } from 'src/models/general/IUserListItem';
import { IObservation } from '../models/observation/IObservation';
import useAuth from 'src/hooks/useAuth';
import {
  NotificationService,
  UserNotificationsService
} from '@actimi/core-migration';

export interface IUserNotifications {
  readonly id: string;
  readonly to: string;
  readonly createdAt: string;
  readonly read: boolean;
  readonly message: string;
  readonly from: string;
  readonly importance: 'low' | 'medium' | 'high' | 'maximum';
}

export interface UserNotificationState {
  userNotifications: IUserNotifications[];
  notificationBadge: number;
  careTeamPaginationData: ICareTeam[];
  observationPaginationData: Notifications[];
  observations: IObservation[];
  readyMessage: { id: string; message: string }[];
}

const initialState: UserNotificationState = {
  userNotifications: [],
  notificationBadge: 0,
  careTeamPaginationData: [],
  observationPaginationData: [],
  observations: [],
  readyMessage: [
    {
      id: '1',
      message:
        'Do you want to adjust the intensity of this exersize or do you want to try a different exersize?'
    },
    {
      id: '2',
      message: `Hello, Patient! I see you are skipping your exersizes lately. Can you tell me why?`
    }
  ]
};

const slice = createSlice({
  name: 'user-notification',
  initialState,
  reducers: {
    getNotifications(
      state: UserNotificationState,
      action: PayloadAction<{ notifications: IUserNotifications[] }>
    ) {
      const { notifications } = action.payload;
      notifications.forEach((notification) => {
        const index = state.userNotifications.findIndex(
          (notificationItem) => notificationItem.id === notification.id
        );
        if (index > -1) {
          state.userNotifications.splice(index, 1, notification);
        } else {
          state.userNotifications.push(notification);
        }
      });
    },

    getMoreNotifications(
      state: UserNotificationState,
      action: PayloadAction<{ notifications: IUserNotifications[] }>
    ) {
      const { notifications } = action.payload;
      notifications.forEach((notification) => {
        const index = state.userNotifications.findIndex(
          (notificationItem) => notificationItem.id === notification.id
        );
        if (index > -1) {
          state.userNotifications.splice(index, 1, notification);
        } else {
          state.userNotifications.push(notification);
        }
      });
    },

    getUnseenNotifications(
      state: UserNotificationState,
      action: PayloadAction<{ notificationBadge: number }>
    ) {
      const { notificationBadge } = action.payload;
      state.notificationBadge = notificationBadge;
    },
    markAsRead(
      state: UserNotificationState,
      action: PayloadAction<{ body: Array<{ id: string; read: boolean }> }>
    ) {
      for (const { id, read } of action.payload.body) {
        const index = state.userNotifications.findIndex((x) => x.id === id);
        state.userNotifications.splice(index, 1, {
          ...state.userNotifications[index],
          read
        });
      }
      state.notificationBadge = state.userNotifications.filter(
        (x) => !x.read
      ).length;
    },
    getCareTeamPaginationData(
      state: UserNotificationState,
      action: PayloadAction<{ careTeamPaginationData: ICareTeam[] }>
    ) {
      const { careTeamPaginationData } = action.payload;

      state.careTeamPaginationData = careTeamPaginationData;
    },
    getObservationPaginationData(
      state: UserNotificationState,
      action: PayloadAction<{
        observationPaginationData: IObservation[];
        userList: IUserListItem[];
      }>
    ) {
      const { observationPaginationData, userList } = action.payload;

      const data = NotificationFactory.formatObservations(
        observationPaginationData,
        userList
      );
      state.observations.push(...observationPaginationData);
      state.observationPaginationData = _.sortBy(
        data,
        (x) => x.creationDate
      ).reverse();
    },
    getMoreObservationData(
      state: UserNotificationState,
      action: PayloadAction<{
        observationPaginationData: IObservation[];
        userList: IUserListItem[];
      }>
    ) {
      const { observationPaginationData, userList } = action.payload;
      const observations = state.observations.concat(
        ...observationPaginationData
      );
      const data = NotificationFactory.formatObservations(
        observations,
        userList
      );
      state.observations.push(...observations);
      state.observationPaginationData = _.sortBy(
        data,
        (x) => x.creationDate
      ).reverse();
    },
    reset(state: UserNotificationState) {
      Object.assign(state, initialState);
    },
    setReadyMessages(
      state: UserNotificationState,
      action: PayloadAction<{ id: string; message: string }>
    ) {
      state.readyMessage.push(action.payload);
    },
    removeReadyMessages(
      state: UserNotificationState,
      action: PayloadAction<string>
    ) {
      state.readyMessage = state.readyMessage.filter(
        (message) => message.id !== action.payload
      );
    }
  }
});

const userNotificationsService = new UserNotificationsService();
export const { setReadyMessages, removeReadyMessages } = slice.actions;
export const reducer = slice.reducer;

export const reset = (): AppThunk => async (dispatch) =>
  dispatch(slice.actions.reset());

export const getNotifications =
  (meId: string, _page: number, _count: number): AppThunk =>
  async (dispatch: AppDispatch) => {
    const resp = await userNotificationsService.getNotifications({
      _count: _count,
      _page: _page,
      meId: meId
    });
    dispatch(slice.actions.getNotifications({ notifications: resp }));
  };

export const getMoreNotifications =
  (
    meId: string,
    _count: number,
    _page: number,
    whenDone?: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const resp = await MiddlewareService.getNotifications(meId, _count, _page);
    if (resp.length > 0) {
      dispatch(slice.actions.getMoreNotifications({ notifications: resp }));
      if (whenDone) {
        whenDone();
      }
    }
  };

export const getMoreObservationData =
  (
    userId: string,
    page: number,
    count: number,
    userList: IUserListItem[],
    whenDone?: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const resp = await MiddlewareService.getAllNotifications(
      userId,
      page,
      count,
      userList
    );

    dispatch(
      slice.actions.getMoreObservationData({
        observationPaginationData: resp,
        userList
      })
    );
    if (whenDone) {
      whenDone();
    }
  };

export const getUnseenNotifications =
  (meId: string, whenDone: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    const resp = await userNotificationsService.getUnseenNotifications(meId);
    dispatch(slice.actions.getUnseenNotifications({ notificationBadge: resp }));
    const action = whenDone;
    if (action !== undefined) {
      action();
    }
  };

export const markAsRead =
  (body: Array<{ id: string; read: boolean }>): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(slice.actions.markAsRead({ body }));
    await MiddlewareService.markNotificationsAsRead(body);
  };

export const getCareTeamPaginationData =
  (
    userId: string,
    page?: number,
    count?: number,
    whenDone?: (resp: ICareTeam[]) => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const resp = await MiddlewareService.getCareTeams({
      participant: userId,
      page,
      count
    });
    dispatch(
      slice.actions.getCareTeamPaginationData({ careTeamPaginationData: resp })
    );
    const action = whenDone;
    if (action !== undefined) {
      action(resp);
    }
  };

export const getObservationPaginationData =
  (
    userId: string,
    page: number,
    count: number,
    userList: IUserListItem[],
    whenDone?: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const resp = await MiddlewareService.getAllNotifications(
      userId,
      page,
      count,
      userList
    );
    dispatch(
      slice.actions.getObservationPaginationData({
        observationPaginationData: resp,
        userList
      })
    );
    if (whenDone) {
      whenDone();
    }
  };

export default slice;

export enum ExercisePlan {
  PM = 'Pain Management',
  MODL = 'Movement of Daily Life'
}

export const getCategories = (t: any) => {
  return {
    bodyPart: [
      { value: 'Lower Body', label: t('Lower Body') },
      { value: 'Upper Body', label: t('Upper Body') },
      { value: 'Spine', label: t('Spine') },
      { value: 'Nervous System', label: t('Nervous System') }
    ],
    movementCategory: [
      { value: 'Mobilisation', label: t('Mobilisation') },
      { value: 'Activation', label: t('Activation') },
      { value: 'Integration', label: t('Integration') },
      { value: 'Isometrics', label: t('Isometrics') }
    ],
    library: [
      { value: 'Public', label: t('Public') },
      { value: 'Private', label: t('Private') }
    ],
    level: [
      { value: 'Beginner', label: t('Beginner') },
      { value: 'Intermediate', label: t('Intermediate') },
      { value: 'Advanced', label: t('Advanced') },
      { value: 'Expert', label: t('Expert') }
    ],
    movementPattern: [
      { value: 'hold', label: t('Hold') },
      { value: 'up', label: t('Up') },
      { value: 'down', label: t('Down') }
    ]
  };
};

export const equipments = [
  { value: 'No Equipment', label: 'No Equipment' },
  { value: 'Dumbbell', label: 'Dumbbell' },
  { value: 'Cable Pull', label: 'Cable Pull' },
  {
    value: 'Elastic Resistance Bands',
    label: 'Elastic Resistance Bands'
  },
  { value: 'Kettlebell', label: 'Kettlebell' },
  { value: 'Barbell', label: 'Barbell' },
  { value: 'Medicine Ball', label: 'Medicine Ball' },
  { value: 'Weight Sled', label: 'Weight Sled' },
  { value: 'Strength Machines', label: 'Strength Machines' },
  { value: 'Ankle/Wrist Weights', label: 'Ankle/Wrist Weights' },
  {
    value: 'Elastic Resistance Tubes',
    label: 'Elastic Resistance Tubes'
  },
  { value: 'Jump Box', label: 'Jump Box' },
  { value: 'Jump Rope', label: 'Jump Rope' },
  { value: 'Trampoline', label: 'Trampoline' },
  { value: 'Foam Roller', label: 'Foam Roller' },
  { value: 'Agillity Ladder', label: 'Agillity Ladder' },
  { value: 'Cross Trainer', label: 'Cross Trainer' },
  { value: 'Treadmill', label: 'Treadmill' },
  { value: 'Indoor Bike', label: 'Indoor Bike' },
  { value: 'Suspension Trainer', label: 'Suspension Trainer' },
  { value: 'Gymnastic Rings', label: 'Gymnastic Rings' }
];

export enum TypeUrlName {
  Library = 'http://extension.actimi.com/questionnaire-exercise-database-type',
  Level = 'http://extension.actimi.com/questionnaire-exercise-level',
  Body = 'http://extension.actimi.com/questionnaire-exercise-body-part',
  Movement = 'http://extension.actimi.com/questionnaire-exercise-movement-category'
}

export const convertIntensityToNumericValue = (intensity: string) => {
  switch (intensity) {
    case 'beginner':
      return 1;
    case 'Beginner':
      return 1;

    case 'intermediate':
      return 2;
    case 'Intermediate':
      return 2;

    case 'advanced':
      return 3;

    case 'expert':
      return 4;
  }
};

export const convertIntensityNumbericToString = (sumOfIntensity) => {
  if (sumOfIntensity >= 1 && sumOfIntensity < 2) {
    return 'Beginner';
  }
  if (sumOfIntensity >= 2 && sumOfIntensity < 3) {
    return 'Intermediate';
  }
  if (sumOfIntensity >= 3 && sumOfIntensity < 4) {
    return 'Advanced';
  }
  if (sumOfIntensity >= 4) {
    return 'Expert';
  }
};

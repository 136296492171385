/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import routes from './router';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import useScrollTop from 'src/hooks/useScrollTop';
import useAuth from 'src/hooks/useAuth';
import ThemeProvider from './theme/ThemeProvider';
import { CssBaseline } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from './store';
import ChatWSHandler from './utils/ChatWSHandler';
import { getUnseenNotifications } from './slices/user-notifications';
import { UpdateSubscriptionSocket } from './utils/UpdateSubscriptionSocket';
import { setPatients } from './slices/patients-profile';
import { ILimitValues, setLimitValue } from './slices/limit-values';
import { resetActions } from './store/rootReducer';
import { AbilityContext } from './services/casl/AbilityContext';
import { getClinicMembers, getPractitionerInfo } from './slices/clinic';
import { QueryClient, QueryClientProvider } from 'react-query';
import { axiosAidboxInt } from '../src/utils/axios';

import {
  initAxiosInstance as initAxiosAidboxInstance,
  PatientService
} from '@actimi/core-migration';
import internationalization from 'src/i18n/i18n';
import i18n from 'src/i18n/i18n';

const App = () => {
  const queryClient = new QueryClient();
  const content = useRoutes(routes);
  const auth = useAuth();
  useScrollTop();
  const dispatch = useDispatch();
  const practitioner = useSelector((state) => state.clinic.practitionerInfo);
  const patientService = new PatientService();
  const getLanguage = i18n.language;

  useEffect(() => {
    internationalization.changeLanguage(getLanguage);
  }, []);

  useEffect(() => {
    if (auth.user) {
      const userId = window.localStorage.getItem('userId');

      dispatch(
        getUnseenNotifications(auth.user?.id, () => {
          ChatWSHandler.setActiveStatus(auth.user.id, true);
        })
      );

      dispatch(getPractitionerInfo(userId, auth?.user?.clinic?.id));

      dispatch(getClinicMembers(auth.user.clinic.id));
      return () => {
        ChatWSHandler.setActiveStatus(auth.user.id, false);
      };
    }
  }, [practitioner?.id, auth?.user?.id]);
  useEffect(() => {
    if (!auth.user?.clinic?.id) return;
    (async () => {
      const { limitValues, patients } =
        await patientService.getPatientProfilesWithLimitValues(
          auth.user.clinic.id
        );
      // @ts-ignore
      dispatch(setPatients(patients));

      dispatch(setLimitValue(limitValues as unknown as ILimitValues[]));
    })();
    return () => {
      // Cleanup store by using each slice's reset action
      resetActions.forEach((resetAction) => dispatch(resetAction()));

      // Remove all listeners in socket.
      UpdateSubscriptionSocket.cleanUp();
    };
  }, [auth.user?.clinic?.id]);

  initAxiosAidboxInstance(axiosAidboxInt);
  return (
    <>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AbilityContext.abilityCtx.Provider value={AbilityContext.ability}>
            <QueryClientProvider client={queryClient}>
              <CssBaseline />
              {auth.isInitialized ? (
                <Fragment>
                  <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                  />
                  {content}
                </Fragment>
              ) : (
                <>auth not initialized</>
              )}
            </QueryClientProvider>
          </AbilityContext.abilityCtx.Provider>
        </LocalizationProvider>
      </ThemeProvider>
    </>
  );
};
export default App;

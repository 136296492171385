import { CodeableConcept, HumanName } from '@actimi/core-migration';

export type GenericFormObject = Record<string, string | number | Date>;

export function sendChangedFormData(
  previousData: GenericFormObject,
  data: GenericFormObject
): GenericFormObject {
  const changedData: GenericFormObject = {};
  Object.keys(previousData).forEach((key) => {
    if (previousData[key] !== data[key]) {
      Object.assign(changedData, { [key]: data[key] });
    }
  });
  return changedData;
}

export const formatPatientName = (name: string): HumanName[] => {
  const nameParts = name.trim().split(' ');
  if (nameParts.length > 1) {
    return [
      {
        family: nameParts[2],
        given: [
          nameParts[1] !== undefined
            ? nameParts[0] + ' ' + nameParts[1]
            : nameParts[0]
        ]
      }
    ];
  }
  return [
    {
      family: undefined,
      given: [nameParts[0]]
    }
  ];
};

export const formatFirstLetterUpperCase = (str: string) => {
  return str?.substring(0, 1)?.toUpperCase() + str?.substring(1);
};

export const convertBodyStringToKey = (
  bodyParts: CodeableConcept[]
): string[] => {
  let convertedString: string[] = [];

  bodyParts?.map((parts) => {
    switch (parts.text) {
      case 'Right Head':
        convertedString.push('headRight');
        break;
      case 'Left Head':
        convertedString.push('headLeft');
        break;
      case 'Neck':
        convertedString.push('neck');
        break;
      case 'Right Shoulder':
        convertedString.push('shoulderRight');
        break;
      case 'Left Shoulder':
        convertedString.push('shoulderLeft');
        break;
      case 'Right Arm':
        convertedString.push('armRight');
        break;
      case 'Arm Left':
        convertedString.push('armLeft');
        break;
      case 'Right Fore Arm':
        convertedString.push('foreArmRight');
        break;
      case 'Left Fore Arm':
        convertedString.push('foreArmLeft');
        break;
      case 'Right Hand':
        convertedString.push('handRight');
        break;
      case 'Left Hand':
        convertedString.push('handLeft');
        break;
      case 'Right Chest':
        convertedString.push('chestRight');
        break;
      case 'Left Chest':
        convertedString.push('chestLeft');
        break;
      case 'Right Abdomen':
        convertedString.push('abdomenRight');
        break;
      case 'Left Abdomen':
        convertedString.push('abdomenLeft');
        break;
      case 'Right Upper Leg':
        convertedString.push('upperLegRight');
        break;
      case 'Left Upper Leg':
        convertedString.push('upperLegLeft');
        break;
      case 'Right Knee':
        convertedString.push('kneeRight');
        break;
      case 'Left Knee':
        convertedString.push('kneeLeft');
        break;
      case 'Right Lower Leg':
        convertedString.push('lowerLegRight');
        break;
      case 'Left Lower Leg':
        convertedString.push('lowerLegLeft');
        break;

      case 'Right Foot':
        convertedString.push('footRight');
        break;
      case 'Left Foot':
        convertedString.push('footLeft');
        break;

      default:
        convertedString.push('Unkown');
        break;
    }
  });
  return convertedString;
};

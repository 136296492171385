/* eslint-disable eqeqeq */
import { FC } from 'react';
import { Box } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { experimentalStyled } from '@material-ui/core/styles';

import { columns } from './utils';
// import NotesDrawer from 'src/components/dashboard-table/NotesDrawer';
import { useTableData } from './hooks';
import { Backdrop, CircularProgress } from '@mui/material';
import Label from 'src/components/Base/Label';
import DropdownSelectBox from 'src/components/Base/DropdownSelectBox';
import PaginationGroup from 'src/components/Base/Pagination';
import CheckboxButton from 'src/components/Base/Checkbox';
import { useTranslation } from 'react-i18next';
import NoDataIllustrator from 'src/assets/images/data/NoDataPatientTable.png';
import { NoData } from '../../Dashboard/NoData';
import React from 'react';

interface GridProps {
  readonly pageSizeChange: (size: number) => void;
  readonly pageChange: (page: number) => void;
  readonly page: number;
  readonly pageSize: number;
}

const Grid: FC<GridProps> = (props) => {
  const {
    rows,
    isLoading,
    total,
    paginationState: { page },
    setPaginationState,
    blockUI
  } = useTableData();
  const [cloneRow, setCloneRow] = React.useState([]);
  const [isLoadingClone, setLoadingClone] = React.useState(true);

  const refreshCount = React.useRef(0);
  React.useEffect(() => {
    if (!isLoading && refreshCount.current === 0) {
      setCloneRow(rows);
      refreshCount.current = refreshCount.current + 1;
      setLoadingClone(false);
    }
  }, [isLoading, rows]);

  const RenderPagination = () => {
    const { t }: { t: any } = useTranslation();

    return (
      <PaginationWrapper>
        <PaginationGroup
          count={Math.ceil(total / props.pageSize)}
          page={props.page + 1}
          onChange={(e, v) => {
            props.pageChange(v - 1);
            setPaginationState({ page: v - 1, pageSize: props.pageSize });
          }}
          hidePrevButton={props.page === 0 ?? false}
        />
        <Box
          sx={{
            position: 'absolute',
            right: '3%',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Label>{t('Rows per page')}</Label>
          <DropdownSelectBox
            onChange={(event: any) => {
              props.pageSizeChange(event.target.value);
              setPaginationState({ page, pageSize: event.target.value });
            }}
            value={props.pageSize}
            dropdownData={[
              { label: '5', value: 5 },
              { label: '10', value: 10 },
              { label: '15', value: 15 },
              { label: '30', value: 30 },
              { label: '100', value: 100 }
            ]}
          />
        </Box>
      </PaginationWrapper>
    );
  };
  const { t }: { t: any } = useTranslation();

  if (!isLoading && !isLoadingClone && cloneRow.length === 0) {
    return (
      <NoData img={NoDataIllustrator} label={t('No-data-patient')} another />
    );
  }

  return (
    <Box display="flex" height="100%">
      <DataGridStyled
        rowCount={total}
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        rows={rows}
        autoHeight
        rowHeight={80}
        columns={columns}
        rowsPerPageOptions={[5, 10, 15, 30, 100]}
        pageSize={props.pageSize}
        components={{
          Pagination: RenderPagination,
          BaseCheckbox: CheckboxButton
        }}
        onPageSizeChange={(pageSize) => props.pageSizeChange(pageSize)}
        onPageChange={(page) => props.pageChange(page)}
        page={props.page}
        pagination
        paginationMode="server"
        filterMode="server"
        loading={isLoading}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={blockUI}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default Grid;

const DataGridStyled = experimentalStyled(DataGrid)(
  ({ theme }) => `
          border: none;
          background-color: white;
          .MuiDataGrid-columnHeaders {
            background-color: rgb(246, 248, 251);
            border-top: 1px solid #dedede;
          }
          .MuiDataGrid-columnHeader {
            border: none;
          }
          .MuiDataGrid-columnHeaderTitle {
            font-weight: 900;
          }
          .MuiDataGrid-columnSeparator > svg {
            display: none;
          }
          .MuiDataGrid-iconSeparator {
            display: none;
          }
          .MuiDataGrid-cell--withRenderer MuiDataGrid-cell MuiDataGrid-cell--textCenter {
              outline: none;
            }
          .MuiDataGrid-cell--withRenderer {
            display: flex;
            align-items: center;
            outline: none;
          }
  `
);

const PaginationWrapper = experimentalStyled(Box)(
  () => `
      display: flex; 
      justify-content: center;
      align-items:center;
      width:100%;
      
  `
);

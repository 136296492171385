import { formatFirstLetterUpperCase } from './../../../utils/Form';
import { CarePlanActivity } from './../../../services/middleware/types/aidbox/AidboxTypes';
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CarePlan,
  FileSystemService,
  Questionnaire,
  QuestionnaireItem,
  Task
} from '@actimi/core-migration';
import _ from 'lodash';
import * as yup from 'yup';
import AxiosJwt from '../../../utils/axios';
import { toast, ToastPosition } from 'react-toastify';
import moment from 'moment';
import { calculateExerciseTime } from 'src/utils/Task';
import * as uuid from 'uuid';
import { Box, styled } from '@material-ui/core';

export interface IExerciseFromValues {
  exercise: {
    // exerciseVideo: any;
    exerciseName: string;
    bodyPart: string;
    movementCategory: string;
    level: string;
    databaseType: string;
  };
  instructions: {
    instruction: string[];
    attention: string;
    tips: string;
    advice: string;
  };
  parameters: {
    sets: number | string;
    repetitions: number | string;
    rest: number | string;
    tempo?: {
      down: string;
      hold: string;
      up: string;
    };
    equipment: string;
    movementPattern: {
      first: string;
      second: string;
      third: string;
    };
    duration?: number | string;
  };
}

export const DEFAULT_FORMVALUES: IExerciseFromValues = {
  exercise: {
    // exerciseVideo: undefined,
    exerciseName: '',
    bodyPart: '',
    movementCategory: '',
    level: '',
    databaseType: ''
  },
  instructions: {
    instruction: ['', '', ''],
    attention: '',
    tips: '',
    advice: ''
  },
  parameters: {
    equipment: '',
    sets: '',
    repetitions: '',
    rest: '',
    tempo: {
      down: '0',
      hold: '0',
      up: '0'
    },
    movementPattern: {
      first: '',
      second: '',
      third: ''
    },
    duration: ''
  }
};

export function parseToFormValues(
  questionnaireData?: QuestionnaireItem
): IExerciseFromValues {
  const questionnaireMappingExercise = (url) => {
    return String(
      questionnaireData.extension

        .map((item) => {
          if (item.url === url) {
            return String(item.valueString);
          }
        })
        .filter((x) => x !== undefined)
    );
  };
  const parametersMappingInteger = (url) => {
    return Number(
      questionnaireData.extension
        .flatMap((item) => {
          if (item.url === 'http://extension.actimi.com/exercise-assistant') {
            return item.extension.map((value) => {
              if (value.url === url) {
                return value.valueInteger;
              }
            });
          }
        })
        .filter((x) => x !== undefined)
    );
  };

  const parametersMappingString = (url) => {
    return String(
      questionnaireData.extension
        .flatMap((item) => {
          if (item.url === 'http://extension.actimi.com/exercise-assistant') {
            return item.extension.map((value) => {
              if (value.url === url) {
                return value.valueString;
              }
            });
          }
        })
        .filter((x) => x !== undefined)
    );
  };

  const questionnaireMappingParameters = (urlInner) => {
    return Number(
      questionnaireData.extension
        .flatMap((item) => {
          if (item.url === 'http://extension.actimi.com/exercise-assistant') {
            return item.extension.map((value) => {
              if (value.url === urlInner) {
                return value.valueDuration.value;
              }
            });
          }
        })
        .filter((x) => x !== undefined)
    );
  };

  const formValues = _.cloneDeep(DEFAULT_FORMVALUES);
  if (!questionnaireData) return formValues;
  const { exercise, instructions, parameters } = formValues;

  exercise.exerciseName = questionnaireMappingExercise(
    'http://extension.actimi.com/questionnaire-navigation-title'
  );
  exercise.bodyPart = questionnaireMappingExercise(
    'http://extension.actimi.com/questionnaire-exercise-body-part'
  );
  exercise.level = questionnaireMappingExercise(
    'http://extension.actimi.com/questionnaire-exercise-level'
  );
  exercise.movementCategory = questionnaireMappingExercise(
    'http://extension.actimi.com/questionnaire-exercise-movement-category'
  );
  exercise.databaseType = questionnaireMappingExercise(
    'http://extension.actimi.com/questionnaire-exercise-database-type'
  );
  instructions.attention = String(
    questionnaireData.extension
      .flatMap((item) => {
        if (item.url === 'http://extension.actimi.com/exercise-assistant') {
          return item.extension.map((value) => {
            if (
              value.url ===
              'http://extension.actimi.com/exercise-assistant-instructions'
            ) {
              return value.extension[1].valueString;
            }
          });
        }
      })
      .filter((x) => x !== undefined)
  );
  instructions.tips = String(
    questionnaireData.extension
      .flatMap((item) => {
        if (item.url === 'http://extension.actimi.com/exercise-assistant') {
          return item.extension.map((value) => {
            if (
              value.url ===
              'http://extension.actimi.com/exercise-assistant-instructions'
            ) {
              return value.extension[2]?.valueString;
            }
          });
        }
      })
      .filter((x) => x !== undefined)
  );

  const currentTempo = parametersMappingString(
    'http://extension.actimi.com/exercise-assistant-tempo'
  ).split('-');

  const currentMovementPattern = questionnaireMappingExercise(
    'http://extension.actimi.com/questionnaire-exercise-movement-pattern'
  )?.split('-');

  parameters.movementPattern = {
    first:
      currentMovementPattern.length > 2 ? currentMovementPattern?.[0] : 'Down',
    second:
      currentMovementPattern.length > 2 ? currentMovementPattern?.[1] : 'Hold',
    third:
      currentMovementPattern.length > 2 ? currentMovementPattern?.[2] : 'Up'
  };

  parameters.equipment =
    questionnaireMappingExercise(
      'http://extension.actimi.com/questionnaire-exercise-equipment'
    ).length !== 0
      ? questionnaireMappingExercise(
          'http://extension.actimi.com/questionnaire-exercise-equipment'
        )
      : 'No Equipment';

  parameters.tempo = {
    down: currentTempo?.[0],
    hold: currentTempo?.[1],
    up: currentTempo?.[2]
  };

  parameters.rest = questionnaireMappingParameters(
    'http://extension.actimi.com/exercise-assistant-rest'
  );
  exercise.level = questionnaireMappingExercise(
    'http://extension.actimi.com/questionnaire-exercise-level'
  );
  parameters.sets = parametersMappingInteger(
    'http://extension.actimi.com/exercise-assistant-sets'
  );
  parameters.repetitions = parametersMappingInteger(
    'http://extension.actimi.com/exercise-assistant-repetition'
  );
  parameters.duration = parametersMappingString(
    'http://extension.actimi.com/exercise-assistant-duration'
  );

  return formValues;
}

export function exerciseValidationSchema() {
  const requiredString = yup.string().required('This field is required!');

  return yup.object().shape({
    exercise: yup.object().shape({
      // exerciseVideo: requiredString,
      exerciseName: requiredString,
      bodyPart: requiredString,
      movementCategory: requiredString,
      level: requiredString
    }),
    // instructions: yup.object().shape({
    //   // instruction: requiredString,
    //   // attention: requiredString,
    //   // tips: requiredString
    // }),
    parameters: yup.object().shape({
      sets: requiredString,
      repetitions: requiredString,
      rest: requiredString
      // equipment: requiredString,
      // movementPattern: requiredString
    })
  });
}
export const uploadFile = async (requestBody: any) => {
  const { uploadVideo } = new FileSystemService();

  const data = await uploadVideo(requestBody);

  return data;
};

export const SuccessMessage = (message: string, position: ToastPosition) => {
  return toast(message, {
    autoClose: 2000,
    type: 'success',
    hideProgressBar: true,
    style: { borderRadius: 12, backgroundColor: '#26BDD8' },
    position,
    theme: 'colored'
  });
};
export const ErrorMessage = (message: string, position: ToastPosition) => {
  return toast(message, {
    autoClose: 2000,
    type: 'error',
    hideProgressBar: true,
    style: { borderRadius: 12, backgroundColor: '#FF9DAF' },
    position,
    theme: 'colored'
  });
};

export const generateQuestionnaireTask = (
  selectedQuestionnaire: Questionnaire[],
  patientId: string,
  practitionerId: string,
  date: string
) => {
  let generateTask: Task[] = selectedQuestionnaire.map((row, index) => {
    return {
      id: uuid.v4(),
      executionPeriod: {
        start: moment().add(1, 'days').format('YYYY-MM-DD'),
        end: date
      },
      code: {
        text: 'Questionnaire',
        coding: selectedQuestionnaire[index].code
      },
      for: {
        id: patientId as string,
        type: 'Patient',
        resourceType: 'Patient'
      },
      requester: {
        id: practitionerId,
        type: 'Practitioner',
        resourceType: 'Practitioner'
      },
      input: [
        {
          type: {
            text: 'ConditionalQuestionnaire',
            coding: [
              {
                code: selectedQuestionnaire[index].name,
                display: selectedQuestionnaire[index].title
              }
            ]
          },
          value: {
            Reference: {
              id: row.id,
              resourceType: 'Questionnaire'
            }
          }
        }
      ],
      intent: 'plan',
      status: 'requested',
      resourceType: 'Task'
    };
  });

  return generateTask;
};

export const generateCarePlanActivity = (
  assignedTasks: Task[],
  isExercise?: boolean,
  selectedExercises?: Questionnaire[]
) => {
  const carePlanActivity: { activity: CarePlanActivity[] } = {
    activity: assignedTasks.map((task, index) => {
      return {
        detail: {
          code: {
            coding: [
              {
                code: isExercise
                  ? 'exercise-name'
                  : task.input?.[0].type.coding?.[0].code,
                display: isExercise
                  ? selectedExercises[index].title.slice(17)
                  : task.input?.[0].type.coding?.[0].display
              }
            ]
          },
          kind: 'Task',
          status: 'in-progress',
          instantiatesCanonical: [task.input?.[0].value.Reference.id]
        },
        reference: {
          id: task.id,
          resourceType: 'Task'
        }
      };
    })
  };

  return carePlanActivity.activity;
};

export const generateQuestionnairePlan = (body: {
  assignedTasks: Task[];
  date: string;
  patientId: string;
  practitionerId: string;
}): CarePlan => {
  const { assignedTasks, date, patientId, practitionerId } = body;
  const formattedToday = moment().add(1, 'days').format('YYYY-MM-DD');

  const carePlan: CarePlan = {
    period: {
      start: formattedToday,
      end: date
    },
    category: [
      {
        coding: [
          {
            code: 'care-plan-questionnaire-category',
            display: 'Patient Condition Specific Questionnaire Plan'
          }
        ]
      }
    ],

    resourceType: 'CarePlan',
    title: 'mifysio-questionnaire-conditions',
    author: {
      id: practitionerId,
      resourceType: 'Practitioner'
    },
    subject: {
      id: patientId,
      resourceType: 'Patient'
    },
    activity: assignedTasks.map((task) => {
      return {
        detail: {
          code: {
            coding: [
              {
                code: task.input?.[0].type.coding?.[0].code,
                display: task.input?.[0].type.coding?.[0].display
              }
            ]
          },
          kind: 'Task',
          status: 'in-progress',
          performer: [
            {
              id: patientId,
              resourceType: 'Patient'
            }
          ],
          instantiatesCanonical: [task.input?.[0].value.Reference.id]
        },
        reference: {
          id: task.id,
          resourceType: 'Task'
        }
      };
    }),
    status: 'active',
    intent: 'plan'
  };
  return carePlan;
};

export const generateTask = (
  selectedExercises: Questionnaire[],
  patientId: string,
  practitionerId: string,
  date: string
): Task[] => {
  let tasksId: string[] = [];

  for (let i = 0; i < selectedExercises.length; i++) {
    tasksId.push(uuid.v4());
  }

  //@ts-ignore
  let generateTask: Task[] = selectedExercises.map((row, index) => {
    const exerciseValue = {
      tempo: String(
        row.item
          .flatMap((extension) => {
            return extension.extension.flatMap((item) => {
              if (
                item.url === 'http://extension.actimi.com/exercise-assistant'
              ) {
                return item.extension.flatMap((value) => {
                  if (
                    value.url ===
                    'http://extension.actimi.com/exercise-assistant-tempo'
                  ) {
                    return value.valueString;
                  }
                });
              }
            });
          })
          .filter((x) => x !== undefined)
      ),
      duration: String(
        row.item
          .flatMap((extension) => {
            return extension.extension.flatMap((item) => {
              if (
                item.url === 'http://extension.actimi.com/exercise-assistant'
              ) {
                return item.extension.flatMap((value) => {
                  if (
                    value.url ===
                    'http://extension.actimi.com/exercise-assistant-duration'
                  ) {
                    return value.valueString;
                  }
                });
              }
            });
          })
          .filter((x) => x !== undefined)
      ),
      repetitions: String(
        row.item
          .flatMap((extension) => {
            return extension.extension.flatMap((item) => {
              if (
                item.url === 'http://extension.actimi.com/exercise-assistant'
              ) {
                return item.extension.flatMap((value) => {
                  if (
                    value.url ===
                    'http://extension.actimi.com/exercise-assistant-repetition'
                  ) {
                    return value.valueInteger;
                  }
                });
              }
            });
          })
          .filter((x) => x !== undefined)
      ),
      sets: String(
        row.item
          .flatMap((extension) => {
            return extension.extension.flatMap((item) => {
              if (
                item.url === 'http://extension.actimi.com/exercise-assistant'
              ) {
                return item.extension.flatMap((value) => {
                  if (
                    value.url ===
                    'http://extension.actimi.com/exercise-assistant-sets'
                  ) {
                    return value.valueInteger;
                  }
                });
              }
            });
          })
          .filter((x) => x !== undefined)
      )
    };
    const exerciseTime =
      exerciseValue.duration.length !== 0
        ? exerciseValue.duration
        : calculateExerciseTime(exerciseValue);
    const filteredTasks = [...tasksId];
    console.log(exerciseValue, 'AAAAQQQ');
    if (index > -1) {
      filteredTasks.splice(index, 1);
    }

    return {
      id: tasksId[index],
      executionPeriod: {
        start: moment().add(1, 'days').format('YYYY-MM-DD'),
        end: date
      },
      code: {
        text: 'mifysio-exercise',
        coding: [
          {
            code: 'mifysio-exercise-output',
            system: 'http://actimi.org/CodeSystem/TaskCode'
          }
        ]
      },
      for: {
        id: patientId as string,
        type: 'Patient',
        resourceType: 'Patient'
      },
      requester: {
        id: practitionerId,
        type: 'Practitioner',
        resourceType: 'Practitioner'
      },

      input: [
        {
          type: {
            coding: [
              {
                code: 'questionnaireid',
                system: 'http://extension.actimi.com/exercise-input-type'
              }
            ]
          },
          value: {
            Reference: {
              id: row.id,
              resourceType: 'Questionnaire'
            }
          }
        },
        {
          type: {
            coding: [
              {
                code: 'exercise-time',
                system: 'http://extension.actimi.com/exercise-input-type'
              }
            ]
          },
          value: {
            Duration: {
              code: 's',
              unit: 'sec',
              value: Number(exerciseTime),
              system: 'http://unitsofmeasure.org'
            }
          }
        },
        {
          type: {
            coding: [
              {
                code: 'edit-exercise-units',
                system: 'http://extension.actimi.com/exercise-input-type'
              }
            ]
          },
          value: {
            Quantity: {
              id:
                exerciseValue.tempo.length === 0
                  ? exerciseValue.duration
                  : exerciseValue.tempo,
              code: exerciseValue.repetitions,
              unit: exerciseValue.sets,
              value: Number(
                row.item
                  .flatMap((extension) => {
                    return extension.extension.flatMap((item) => {
                      if (
                        item.url ===
                        'http://extension.actimi.com/exercise-assistant'
                      ) {
                        return item.extension.flatMap((value) => {
                          if (
                            value.url ===
                            'http://extension.actimi.com/exercise-assistant-rest'
                          ) {
                            return value.valueDuration.value;
                          }
                        });
                      }
                    });
                  })
                  .filter((x) => x !== undefined)
              )
            }
          }
        },

        ...filteredTasks.map((ID) => {
          return {
            type: {
              coding: [
                {
                  code: 'taskid',
                  system: 'http://hl7.org/financial-taskinputtype'
                }
              ]
            },
            value: {
              id: ID
            }
          };
        })
      ],
      intent: 'plan',
      status: 'in-progress',
      resourceType: 'Task'
    };
  });

  console.log(generateTask, 'GENERATEDTASK');

  return generateTask;
};

export const generateCarePlan = (body: {
  assignedTasks: Task[];
  date: string;
  patientId: string;
  practitionerId: string;
  schedules: any;
}): CarePlan => {
  const { assignedTasks, date, patientId, practitionerId, schedules } = body;

  const carePlan: CarePlan = {
    intent: 'plan',
    status: 'active',
    period: {
      start: moment().add(1, 'days').format('YYYY-MM-DD'),
      end: date
    },
    author: {
      id: practitionerId,
      resourceType: 'Practitioner'
    },
    subject: {
      id: patientId,
      resourceType: 'Patient'
    },
    category: [
      {
        coding: [
          {
            code: 'exercise-plan-category',
            display: 'Training'
          }
        ]
      }
    ],
    activity: assignedTasks.map((task) => {
      return {
        detail: {
          status: 'scheduled'
        },
        reference: {
          id: task.id,
          resourceType: 'Task'
        }
      };
    }),

    title: 'mifysio-care-plan',
    resourceType: 'CarePlan'
  };

  return carePlan;
};

export function questionnaireFactory(parameters): Questionnaire {
  const {
    values,
    videoURI,
    thumbnail,
    practitionerId,
    instructionsInputs,
    attentionInputs,
    tipInputs,
    questionnaireId,
    exerciseBase
  } = parameters;

  const tempo = `${values.parameters.tempo.down}-${values.parameters.tempo.hold}-${values.parameters.tempo.up}`;

  const body: Questionnaire = {
    title: `mifysio-exercise-${values.exercise.exerciseName}`,
    language: 'en',
    id: questionnaireId,
    publisher: practitionerId,
    item: [
      {
        type: 'display',
        linkId: `/${uuid.v4()}`,
        extension: [
          {
            url: 'http://extension.actimi.com/questionnaire-navigation-title',
            valueString: values.exercise.exerciseName
          },

          {
            url: 'http://extension.actimi.com/questionnaire-exercise-database-type',
            valueString: values.exercise.databaseType
          },

          {
            url: 'http://extension.actimi.com/questionnaire-exercise-body-part',
            valueString: values.exercise.bodyPart
          },
          {
            url: 'http://extension.actimi.com/questionnaire-exercise-equipment',
            valueString: values.parameters.equipment
          },
          {
            url:
              exerciseBase === 'tempo'
                ? 'http://extension.actimi.com/questionnaire-exercise-movement-pattern'
                : 'http://extension.actimi.com/questionnaire-exercise-base',
            valueString:
              exerciseBase === 'tempo'
                ? `${values.parameters.movementPattern.first}-${values.parameters.movementPattern.second}-${values.parameters.movementPattern.third}`
                : 'duration'
          },
          {
            url: 'http://extension.actimi.com/questionnaire-exercise-movement-category',
            valueString: values.exercise.movementCategory
          },
          {
            url: 'http://extension.actimi.com/questionnaire-exercise-level',
            valueString: values.exercise.level
          },
          {
            url: 'http://extension.actimi.com/questionnaire-video',
            valueUrl: videoURI as unknown as string
          },
          {
            url: 'http://extension.actimi.com/questionnaire-video-video-thumbnail',
            valueUrl: thumbnail
          },

          {
            url: 'http://extension.actimi.com/exercise-assistant',
            extension: [
              {
                url: 'http://extension.actimi.com/exercise-assistant-rest',
                valueDuration: {
                  value: Number(values.parameters.rest),
                  unit: 'sec',
                  code: 's',
                  system: 'http://unitsofmeasure.org'
                }
              },
              {
                url:
                  exerciseBase === 'tempo'
                    ? 'http://extension.actimi.com/exercise-assistant-tempo'
                    : 'http://extension.actimi.com/exercise-assistant-duration',
                valueString:
                  exerciseBase === 'tempo' ? tempo : values.parameters.duration
              },
              {
                url: 'http://extension.actimi.com/exercise-assistant-repetition',
                valueInteger: Number(values.parameters.repetitions)
              },
              {
                url: 'http://extension.actimi.com/exercise-assistant-sets',
                valueInteger: Number(values.parameters.sets)
              },
              {
                url: 'http://extension.actimi.com/exercise-assistant-instructions',
                extension: [
                  {
                    url: 'http://extension.actimi.com/exercise-assistant-instruction-list',
                    valueCodeableConcept: {
                      coding: [
                        ...instructionsInputs.map((item, index) => {
                          return {
                            code: String(index + 1),
                            display: item.service
                          };
                        })
                      ]
                    }
                  },
                  {
                    url: 'http://extension.actimi.com/exercise-assistant-alert-error',
                    valueString: [
                      ...attentionInputs.map((k) => k.attention)
                    ].join('-')
                  },
                  {
                    url: 'http://extension.actimi.com/exercise-assistant-alert-info',
                    valueString: [...tipInputs.map((k) => k.tip)].join('-')
                  },
                  {
                    url: 'http://extension.actimi.com/exercise-assistant-alert-advice',
                    valueString: values.instructions.advice
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        type: 'integer',
        text: 'How much pain did you feel during this exercise?',
        linkId: '/R51',
        extension: [
          {
            url: 'http://hl7.org/fhir/StructureDefinition/questionnaire-sliderStepValue',
            valueInteger: 1
          },

          {
            url: 'http://hl7.org/fhir/StructureDefinition/maxValue',
            valueInteger: 10
          },
          {
            url: 'http://hl7.org/fhir/StructureDefinition/minValue',
            valueInteger: 0
          },
          {
            valueString: 'Training',
            url: 'http://extension.actimi.com/questionnaire-navigation-title'
          },
          {
            valueString: 'Please rate your pain level on the scale of 0 to 10',
            url: 'http://extension.actimi.com/questionnaire-sub-text'
          }
        ]
      },
      {
        type: 'integer',
        text: 'How much effort did you put on this exercise?',
        linkId: '/R52',
        extension: [
          {
            url: 'http://hl7.org/fhir/StructureDefinition/questionnaire-sliderStepValue',
            valueInteger: 1
          },

          {
            url: 'http://hl7.org/fhir/StructureDefinition/maxValue',
            valueInteger: 10
          },
          {
            url: 'http://hl7.org/fhir/StructureDefinition/minValue',
            valueInteger: 0
          },
          {
            valueString: 'Training',
            url: 'http://extension.actimi.com/questionnaire-navigation-title'
          },
          {
            valueString: '(0=no effort 10=maximum effort)',
            url: 'http://extension.actimi.com/questionnaire-sub-text'
          }
        ]
      },

      {
        text: 'How do you want to proceed with this exercise?',
        type: 'choice',
        answerOption: [
          {
            value: {
              string: 'Make it Harder'
            }
          },
          {
            value: {
              string: 'Stay on Same Level'
            }
          },
          {
            value: {
              string: 'Make It Easier'
            }
          }
        ],
        linkId: '/1',
        extension: [
          {
            valueString: 'Training',
            url: 'http://extension.actimi.com/questionnaire-navigation-title'
          }
        ]
      }
    ],
    resourceType: 'Questionnaire',
    status: 'active'
  };

  return body;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export const LinkHover = styled(Box)(
  ({ theme }) => `
      display: inline-block;
      transform: scale(1);
      transition: ${theme.transitions.create(['transform'])};
  
      &:hover {
          transform: scale(1.1);
      }
    `
);

export const formattedCompareExercisePlan = (
  selectedExercises,
  currentTasks
) => {
  return {
    selected: [
      ...selectedExercises?.map((x) => {
        if (
          x.item?.[0].extension?.[0].url ===
          'http://extension.actimi.com/questionnaire-navigation-title'
        )
          return x.item?.[0].extension?.[0].valueString;
      })
    ],
    current: [
      ...currentTasks?.map((x) => {
        if (
          x.row.extension?.[0].url ===
          'http://extension.actimi.com/questionnaire-navigation-title'
        )
          return x.row.extension?.[0].valueString;
      })
    ]
  };
};

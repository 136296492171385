import axios from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';

const axiosInt = axios.create({
  // baseURL: 'http://localhost:3003',
  baseURL: 'https://actimi-middleware-staging.herokuapp.com/',
  headers: {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache'
  },
  withCredentials: true
});

export const axiosAidboxInt = axios.create({
  // baseURL: 'http://localhost:3003',
  baseURL: 'https://staging.multi.aidbox.actimi.health/',
  headers: {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache'
  },
  // axiosInstance.defaults.headers.common.Authorization = `Bearer ${data.access_token}`;
  timeout: 50000,
  withCredentials: true
});

axiosAidboxInt.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const accessDenied = error.toString().indexOf('401');
    if (accessDenied > -1) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('user');
      return window.location.assign('/');
    }
    return Promise.reject(
      (error.response && error.response.data) || 'There is an error!'
    );
  }
);

export const mock = new AxiosMockAdapter(axios.create(), { delayResponse: 0 });

export default axiosAidboxInt;

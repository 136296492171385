import { useTranslation } from 'react-i18next';
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import { useDispatch, useSelector } from 'src/store';
import { useNavigate } from 'react-router-dom';
import { useGridContainerFilters } from './GridContainer';
import { IRowData } from './utils';
import { IPatientDetail } from 'src/models/patient-detail';
import { useConfirm } from 'src/components/Confirm';
import {
  deletePatientDetail,
  setFormState,
  setIsLoading,
  setPatientsDetail
} from '../../../../slices/patients-detail';
import { PatientDetailsService, PatientService } from '@actimi/core-migration';
import slice from 'src/slices/clinic';
import { formatCareTeamDate } from 'src/utils/DateTimeFormatters';
import { formatGenderi18Code } from 'src/utils/User';

export const useTableActions = (setBlockUI: (v: boolean) => void) => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const { deletePatient } = new PatientService();

  const handleMessageClick = useCallback(
    (_userId: string) => {
      navigate(`/applications/messenger/${_userId}`);
    },
    [auth.user.id]
  );

  const handleDeletePatient = async (patientId: string) => {
    try {
      await confirm({
        description: `This will delete patient's data permanently, and this action cannot be recovered.`,
        confirmationButtonProps: { color: 'error' },
        confirmationText: 'Delete'
      });
      setBlockUI(true);
      await deletePatient(auth?.user?.clinic?.id, patientId);
      dispatch(deletePatientDetail({ patientId }));
      setBlockUI(false);
    } catch {}
  };
  const navigateToAnalytics = (patientId: string) => {
    navigate(`/dashboards/analytics/user/${patientId}`);
  };

  return {
    handleMessageClick,
    navigateToAnalytics,
    handleDeletePatient
  };
};

export const useTableData = () => {
  const auth = useAuth();
  const dispatch = useDispatch();

  const { limitValues } = useSelector((state) => state.limitValues);
  const { patients, isLoading: patientsLoading } = useSelector(
    (state) => state.patientsProfile
  );
  const {
    patientDetails,
    patientIds,
    isLoading: detailsLoading
  } = useSelector((state) => state.patientsDetail);

  const { t } = useTranslation();
  const containerFilters = useGridContainerFilters();
  const [rows, setRows] = useState([]);
  const [blockUI, setBlockUI] = useState(false);
  const [rowsLoading, setRowsLoading] = useState(true);
  const [paginationState, setPaginationState] = useState({
    pageSize: 15,
    page: 0
  });

  const patientDetailsService = new PatientDetailsService();
  const [total, setTotal] = useState(15);

  const { handleMessageClick, navigateToAnalytics, handleDeletePatient } =
    useTableActions(setBlockUI);

  function openEditPatientForm(patientId: string) {
    dispatch(setFormState({ open: true, userId: patientId }));
  }

  // (re)fetch tableData according to page, pageSize and filter params
  useEffect(() => {
    dispatch(setIsLoading(true));

    let unmounted = false;
    const tableParams = {
      ...paginationState,
      ...containerFilters
    };

    (async function () {
      const _patientDetails =
        await patientDetailsService.getMifysioPatientTable(
          auth.user.clinic.id,
          tableParams.pageSize,
          tableParams.page
        );
      dispatch(slice.actions.setTableTotal(_patientDetails.total));
      if (unmounted) return;

      if (_patientDetails.total === 0 && paginationState.page !== 0) {
        setPaginationState({ ...paginationState, page: 0 });
      } else {
        setTotal(_patientDetails.total);
        // @ts-ignore
        dispatch(setPatientsDetail(_patientDetails.items));
      }
    })();

    return () => {
      unmounted = true;
    };
  }, [paginationState, auth.user.clinic.id, containerFilters]);

  const parseRow = useCallback(
    (row: IPatientDetail) => {
      const { id } = row;

      const fullName =
        row.name &&
        `${String(JSON.parse(row?.name)?.[0]?.given)} ${
          JSON.parse(row?.name)?.[0]?.family
        }`;

      const data: IRowData = {
        id: id,
        name: {
          name: fullName ?? '--',
          birthdate:
            row?.birthdate
              ?.split('-')
              ?.reverse()
              ?.join('-')
              ?.replaceAll('-', '.') ?? ('--' as string),
          gender: row?.gender,
          onClick: navigateToAnalytics
        },
        gender: {
          value: formatGenderi18Code(row?.gender, t) ?? 'Other'
        },

        healthProblem: row?.condition?.bodySite?.[0]?.text ?? '--',
        enrollmentDate:
          formatCareTeamDate(row?.enrollment).length !== 0
            ? formatCareTeamDate(row?.enrollment)
            : '',
        endDate: {
          value: '--'
        },

        actions: {
          onEditClick: openEditPatientForm,
          onDeleteClick: handleDeletePatient
        }
      };
      return data;
    },
    [handleMessageClick, patients, limitValues]
  );

  useEffect(() => {
    if (detailsLoading || patientsLoading) {
      if (!rowsLoading) {
        setRows([]);
        setRowsLoading(true);
      }
      return;
    }
    // CAVEAT: This is actually shouldnt be here patient in table data MUST also exists in patient. Keep this until the the bug is fixed with new doctor page.
    const tableData = patientIds.map((id) => patientDetails[id]);

    let filteredRows = tableData?.filter((r) => !!r);

    setRows(filteredRows?.map((r) => parseRow(r)) ?? []);
    setRowsLoading(false);
  }, [
    patientDetails,
    patientIds,
    parseRow,
    detailsLoading,
    patientsLoading,
    patients,
    rowsLoading
  ]);

  return {
    rows,
    isLoading: detailsLoading || rowsLoading,
    total,
    paginationState,
    setPaginationState,
    blockUI
  };
};

import * as Yup from 'yup';
import { FC, useState } from 'react';
import { Formik } from 'formik';
import { Link as RouterLink } from 'react-router-dom';

import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Checkbox,
  Typography,
  Link,
  FormControlLabel,
  CircularProgress
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useAuth from 'src/hooks/useAuth';
import useRefMounted from 'src/hooks/useRefMounted';
import PrivacyPolicyModal from 'src/components/PrivacyPolicy/PrivacyPolicyModal';
import { ErrorMessage } from 'src/content/dashboards/Database/utils';

const LoginJWT: FC = () => {
  const { login } = useAuth() as any;
  const isMountedRef = useRefMounted();
  const { t }: { t: any } = useTranslation();

  const [isTermsConditionsOpen, setIsTermsConditionsOpen] = useState(false);
  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        terms: true,
        submit: null
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t('The email provided should be a valid email address'))
          .max(255)
          .required(t('The email field is required')),
        password: Yup.string()
          .max(255)
          .required(t('The password field is required')),
        terms: Yup.boolean().oneOf(
          [true],
          t('You must agree to our terms and conditions')
        )
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting }
      ): Promise<void> => {
        try {
          await login(values.email, values.password);

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          if (err.error_description === 'Wrong credentials') {
            ErrorMessage('Wrong credentials, please check again', 'top-center');
          }
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            margin="normal"
            autoFocus
            helperText={touched.email && errors.email}
            label={t('Email address')}
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
            data-testid="login-email-input-test"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            margin="normal"
            helperText={touched.password && errors.password}
            label={t('Password')}
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
            data-testid="login-password-input-test"
          />
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.terms}
                  name="terms"
                  color="primary"
                  onChange={handleChange}
                  data-testid="login-terms-checkbox-test"
                />
              }
              label={
                <>
                  <Typography variant="body2">
                    {t('I accept the')}{' '}
                    <Link
                      sx={{ fontWeight: '500' }}
                      onClick={() => setIsTermsConditionsOpen(true)}
                      component="a"
                      href="#"
                      underline="hover"
                    >
                      {t('terms and conditions')}
                    </Link>
                  </Typography>
                </>
              }
            />
            <Typography variant="body2">
              <Link
                sx={{ fontWeight: '500' }}
                component={RouterLink}
                underline="hover"
                to="/account/recover-password"
              >
                {t('Lost password?')}
              </Link>
            </Typography>
          </Box>

          {Boolean(touched.terms && errors.terms) && (
            <FormHelperText error>{errors.terms}</FormHelperText>
          )}

          {isTermsConditionsOpen && (
            <PrivacyPolicyModal
              isModalOpen={isTermsConditionsOpen}
              setIsModalOpen={(val: boolean) => setIsTermsConditionsOpen(val)}
            />
          )}

          <Button
            sx={{ mt: 3, color: '#FFF' }}
            color="primary"
            startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmitting}
            type="submit"
            fullWidth
            size="large"
            variant="contained"
            data-testid="login-login-btn-test"
          >
            {t('Sign in')}
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default LoginJWT;

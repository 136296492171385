/* eslint-disable array-callback-return */
import * as _ from 'lodash';
import { MeasurementTypeKey } from '../models/measure/MeasurementType';
import { formatDateObject } from './DateTimeFormatters';
import { IObservation } from 'src/models/observation/IObservation';
import {
  INotificationInformation,
  Notifications
} from 'src/layouts/SidebarLayout/Header/Buttons/Alarms';
import { ObservationCode } from 'src/models/observation/enums/ObservationCode';
import { IUserListItem } from 'src/models/general/IUserListItem';

export class NotificationFactory {
  public static formatObservations(
    observation: IObservation[],
    userList: IUserListItem[]
  ): Notifications[] {
    const grouped = _.chain(observation)
      .groupBy((x) => formatDateObject(x?.effectiveDateTime || ''))
      .map((value, key) => ({
        title: key,
        data: value,
        creationDate: new Date(value[0].effectiveDateTime || '').getTime()
      }))
      .value();

    return grouped
      .map((x) => ({
        title: x?.title,
        creationDate: x?.creationDate,
        notification: this.formatNotifications(
          _.uniqBy(x?.data, (z) => z?.id),
          userList
        )
      }))
      .filter((x) => x.notification.length !== 0);
  }

  public static group(
    old: Notifications[],
    _new: Notifications[]
  ): Notifications[] {
    const combined = old.concat(_new);
    return _.chain(combined)
      .groupBy((x) => x.creationDate)
      .map((val, key) => ({
        title: key,
        creationDate: val[0].creationDate,
        notification: val
          .map((x) => x.notification)
          .reduce((a, b) => a.concat(b))
      }))
      .value();
  }

  public static formatNotifications(
    observations: IObservation[],
    userList: IUserListItem[]
  ): INotificationInformation[] {
    const groupedBp = _.groupBy(
      observations.filter(
        (x) =>
          x.code?.coding[0].code === ObservationCode.DIASTOLIC_BLOOD_PRESSURE ||
          x.code?.coding[0].code === ObservationCode.SYSTOLIC_BLOOD_PRESSURE ||
          x.code?.coding[0].code === ObservationCode.BLOOD_PRESSURE_HEART_RATE
      ),
      'subject'
    );
    const groupedEcg = _.groupBy(
      observations.filter(
        (x) =>
          x.code?.coding[0].code === ObservationCode.ECG_HEART_RATE ||
          x.code?.coding[0].code === ObservationCode.HEARTH_RATE_VARIABILITY
      ),
      'subject'
    );
    const notifications: INotificationInformation[] = [];
    const userBpNotifications = this.formatBpObservation(groupedBp, userList);
    const userEcgNotifications = this.formatEcgObservations(
      groupedEcg,
      userList
    );
    observations
      .filter(
        (x) =>
          x.code?.coding[0].code !== ObservationCode.BODY_HEIGHT &&
          x.code?.coding[0].code !== ObservationCode.BODY_TEMPERATURE &&
          x.code?.coding[0].code !== ObservationCode.ECG &&
          x.code?.coding[0].code !== ObservationCode.BURNED_CALORIES &&
          x.code?.coding[0].code !== ObservationCode.STEPS &&
          x.code?.coding[0].code !== ObservationCode.BLOOD_GLUCOSE &&
          x.code?.coding[0].code !== ObservationCode.MOOD &&
          x.code?.coding[0].code !== ObservationCode.GRATITUDE &&
          x.valueQuantity !== undefined
      )
      .map((y) => {
        const userId = y?.subject?.split('/')[1];
        if (userId) {
          const patient = userList?.find((x) => x?.id === userId);
          if (
            patient &&
            y?.valueQuantity &&
            y?.valueQuantity !== 'undefined' &&
            y?.valueQuantity.value &&
            y?.valueQuantity.unit !== 'undefined'
          ) {
            notifications.push({
              id: `${patient?.id}-${y?.id}-${y?.effectiveDateTime}`,
              name: patient?.name,
              date: new Date(y?.effectiveDateTime || '').toISOString(),
              observationId: y?.id,
              userId: patient?.id,
              userAvatar: patient?.avatar,
              creationDate: new Date(y?.effectiveDateTime || '').getTime(),
              result: `${y?.valueQuantity?.value} ${y?.valueQuantity?.unit}`,
              resultValue: Number(y?.valueQuantity?.value)
                .toFixed(
                  y?.valueQuantity?.unit === MeasurementTypeKey.bodyWeight
                    ? 1
                    : 0
                )
                .toString(),
              resultValueKey: y?.valueQuantity?.unit,
              measurementTypeKey: this.getMeasurementTypeKey(
                y?.code?.coding?.[0]?.code as ObservationCode
              ),
              action:
                this.getAction(y?.code?.coding?.[0]?.code as ObservationCode) ||
                ''
            });
          }
        }
      });
    return [...notifications, ...userBpNotifications, ...userEcgNotifications];
  }
  public static formatBpObservation(
    bpObservations: _.Dictionary<IObservation[]>,
    userList: IUserListItem[]
  ): INotificationInformation[] {
    const chunkedUserBpInfo: IObservation[][] = [];
    Object.keys(bpObservations).forEach((x) => {
      _.chunk(
        bpObservations[x].sort(
          (a, b) =>
            new Date(a.effectiveDateTime).getTime() -
            new Date(b.effectiveDateTime).getTime()
        ),
        3
      )
        .filter((z) => z.length === 3)
        .forEach((q) => chunkedUserBpInfo.push(q));
      chunkedUserBpInfo.push();
    });

    const userBpNotification: INotificationInformation[] = [];
    chunkedUserBpInfo.forEach((userBpInfo) => {
      const userBpSys = userBpInfo.find(
        (x) =>
          x.code?.coding[0].code === ObservationCode.SYSTOLIC_BLOOD_PRESSURE
      );
      const userBpDias = userBpInfo.find(
        (x) =>
          x.code?.coding[0].code === ObservationCode.DIASTOLIC_BLOOD_PRESSURE
      );

      if (userBpDias && userBpDias && userBpSys) {
        const patientId = userBpDias.subject?.split('/')[1];
        if (patientId) {
          const patient = userList.find((x) => x.id === patientId);
          if (patient) {
            userBpNotification.push({
              id: `${patient?.id}-${userBpDias.id}-${userBpDias.effectiveDateTime}`,
              name: patient.name,
              date: new Date(userBpDias.effectiveDateTime || '').toISOString(),
              creationDate: new Date(
                userBpDias.effectiveDateTime || ''
              ).getTime(),
              userId: patient.id,
              measurementTypeKey: MeasurementTypeKey.bloodPressure,
              observationId: userBpDias.id,
              userAvatar: patient.avatar,
              result: `${userBpSys?.valueQuantity?.value}/${userBpDias?.valueQuantity?.value}`,
              resultValue: `${userBpSys?.valueQuantity?.value}/${userBpDias?.valueQuantity?.value}`,
              resultValueKey: userBpSys?.valueQuantity?.unit,
              action: 'notification.blood-pressure-measured'
            });
          }
        }
      }
    });
    return userBpNotification;
  }
  public static formatEcgObservations(
    ecgObservations: _.Dictionary<IObservation[]>,
    userList: IUserListItem[]
  ): INotificationInformation[] {
    const chunkedUserEcgInfo: IObservation[][] = [];
    Object.keys(ecgObservations).forEach((x) => {
      _.chunk(
        ecgObservations[x].sort(
          (a, b) =>
            new Date(a.effectiveDateTime).getTime() -
            new Date(b.effectiveDateTime).getTime()
        ),
        2
      )
        .filter((z) => z.length === 2)
        .forEach((q) => chunkedUserEcgInfo.push(q));
      chunkedUserEcgInfo.push();
    });

    const userEcgNotification: INotificationInformation[] = [];
    chunkedUserEcgInfo.forEach((userBpInfo) => {
      const userEcgHeartRate = userBpInfo.find(
        (x) => x.code?.coding[0].code === ObservationCode.ECG_HEART_RATE
      );
      const userHRV = userBpInfo.find(
        (x) =>
          x.code?.coding[0].code === ObservationCode.HEARTH_RATE_VARIABILITY
      );

      if (userEcgHeartRate && userHRV) {
        const patientId = userEcgHeartRate.subject?.split('/')[1];
        if (patientId) {
          const patient = userList.find((x) => x.id === patientId);
          if (patient) {
            userEcgNotification.push({
              id: `${patient?.id}-${userEcgHeartRate.id}-${userEcgHeartRate.effectiveDateTime}`,
              name: patient.name,
              date: new Date(
                userEcgHeartRate.effectiveDateTime || ''
              ).toISOString(),
              creationDate: new Date(
                userEcgHeartRate.effectiveDateTime || ''
              ).getTime(),
              userId: patient.id,
              measurementTypeKey: MeasurementTypeKey.bloodPressure,
              observationId: userEcgHeartRate.id,
              userAvatar: patient.avatar,
              result: `${userEcgHeartRate?.valueQuantity?.value}/${userHRV?.valueQuantity?.value}`,
              resultValue: `${userEcgHeartRate?.valueQuantity?.value}/${userHRV?.valueQuantity?.value}`,
              resultValueKey: userHRV?.valueQuantity?.unit,
              action: 'notification.ecg-measured'
            });
          }
        }
      }
    });

    return userEcgNotification;
  }
  private static getMeasurementTypeKey(
    code: ObservationCode
  ): MeasurementTypeKey {
    switch (code) {
      case ObservationCode.BODY_TEMPERATURE:
        return MeasurementTypeKey.temperature;
      case ObservationCode.BLOOD_PRESSURE_HEART_RATE:
      case ObservationCode.DIASTOLIC_BLOOD_PRESSURE:
      case ObservationCode.SYSTOLIC_BLOOD_PRESSURE:
        return MeasurementTypeKey.bloodPressure;
      case ObservationCode.OXYGEN_SATURATION:
        return MeasurementTypeKey.bloodOxygen;
      case ObservationCode.ECG:
      case ObservationCode.ECG_HEART_RATE:
      case ObservationCode.RESPIRATORY_RATE:
      case ObservationCode.HEARTH_RATE_VARIABILITY:
        return MeasurementTypeKey.ecg;
      case ObservationCode.BODY_HEIGHT:
        return MeasurementTypeKey.bodyHeight;
      case ObservationCode.MOOD:
        return MeasurementTypeKey.mood;
      case ObservationCode.BODY_WEIGHT:
        return MeasurementTypeKey.bodyWeight;
      case ObservationCode.GRATITUDE:
        return MeasurementTypeKey.gratitude;
      case ObservationCode.BURNED_CALORIES:
        return MeasurementTypeKey.burnedCalories;
      case ObservationCode.BLOOD_GLUCOSE:
        return MeasurementTypeKey.bloodGlucose;
      case ObservationCode.STEPS:
        return MeasurementTypeKey.steps;
      case ObservationCode.CHOLESTEROL:
        return MeasurementTypeKey.cholesterol;
      case ObservationCode.TRIGLYCERIDES:
        return MeasurementTypeKey.triglycerides;
      case ObservationCode.KETONE:
        return MeasurementTypeKey.ketone;
      case ObservationCode.URIC_ACID:
        return MeasurementTypeKey.uricAcid;
      case ObservationCode.LACTATE:
        return MeasurementTypeKey.lactate;
      default:
        throw new Error('unknown observation code');
    }
  }
  private static getAction(code: ObservationCode): string {
    switch (code) {
      case ObservationCode.BODY_TEMPERATURE:
        return 'notification.body-temperature-measured';
      case ObservationCode.BLOOD_GLUCOSE:
        return 'notification.blood-glucose-measured';
      case ObservationCode.BLOOD_PRESSURE_HEART_RATE:
      case ObservationCode.DIASTOLIC_BLOOD_PRESSURE:
      case ObservationCode.SYSTOLIC_BLOOD_PRESSURE:
        return 'notification.blood-pressure-measured';
      case ObservationCode.OXYGEN_SATURATION:
        return 'notification.blood-oxygen-measured';
      case ObservationCode.MOOD:
        return 'notification.mood-measured';
      case ObservationCode.ECG:
      case ObservationCode.ECG_HEART_RATE:
      case ObservationCode.RESPIRATORY_RATE:
      case ObservationCode.HEARTH_RATE_VARIABILITY:
        return 'notification.ecg-measured';
      case ObservationCode.BODY_HEIGHT:
        return 'notification.body-height-measured';
      case ObservationCode.BODY_WEIGHT:
        return 'notification.body-weight-measured';
      case ObservationCode.GRATITUDE:
        return 'notification.gratitude-measured';
      case ObservationCode.BURNED_CALORIES:
        return 'notification.burned-calories-measured';
      case ObservationCode.STEPS:
        return 'notification.steps-measured';
      case ObservationCode.CHOLESTEROL:
        return 'notification.cholesterol-measured';
      case ObservationCode.TRIGLYCERIDES:
        return 'notification.triglycerides-measured';
      case ObservationCode.KETONE:
        return 'notification.ketone-measured';
      case ObservationCode.URIC_ACID:
        return 'notification.uric-acid-measured';
      case ObservationCode.LACTATE:
        return 'notification.lactate-measured';
      default:
        return 'unknown observation code';
    }
  }
}

import {
  IFormattedGraphicData,
  IGraphicInformation,
  MultipleGraphDataResponse,
  MultipleGraphicDataType
} from '../models/graphic/IGraphic';
import {
  IBloodPressureGraphicDataId,
  IDetailedSingleBloodPressureData
} from '../models/graphic/IBloodPressureGraphic';
import { ObservationCode } from '../models/observation/enums/ObservationCode';
import { MeasureDataSourceId } from '../models/measure/IMeasureResource';
// eslint-disable-next-line import/no-cycle
import { getMeasurementTypeKey } from './Measure';

export const checkSingleGraphDataNullity = (
  data?: IGraphicInformation
): boolean => {
  if (!data) {
    return true;
  }
  return data.latestMeasuredValue === null;
};

export const isGraphDataNull = (
  data: MultipleGraphDataResponse | undefined
): boolean => {
  // Check if data exists and all of its latestMeasured values are not nullish
  // If not return true
  console.assert(
    (!data ||
      data[0].latestMeasuredValue === null ||
      data[1].latestMeasuredValue === null ||
      data[2].latestMeasuredValue === null) ===
      (!data?.length || data.some((d) => d.latestMeasuredValue === null)),
    `isGraphDataNull wrong data: ${JSON.stringify(data)}`
  );
  if (!data?.length) return true;
  return data.some((d) => d.latestMeasuredValue === null);
};

export const getNullGraphicInformationData = (
  observationCode: ObservationCode
): IGraphicInformation => {
  return {
    latestMeasuredValue: null,
    latestMeasuredDevice: {
      id: MeasureDataSourceId.ACTIMI_MANUAL.toString(),
      resourceType: 'Device'
    },
    latestMeasuredNote: undefined,
    latestMeasuredDateTime: '',
    name: getMeasurementTypeKey(observationCode),
    patientId: '',
    average: null,
    values: []
  };
};

export const isMultipleGraphDataResponse = (
  response: unknown
): response is MultipleGraphDataResponse => {
  const partial: Partial<MultipleGraphDataResponse> =
    response as Partial<MultipleGraphDataResponse>;
  return partial !== undefined && partial[0] !== undefined;
};

export const isMultipleGraphicDataType = (
  graphicData: unknown
): graphicData is MultipleGraphicDataType => {
  const partial: Partial<MultipleGraphicDataType> =
    graphicData as Partial<MultipleGraphicDataType>;
  return (
    partial !== undefined &&
    partial[0] !== undefined &&
    partial[0][0] !== undefined
  );
};

export const isMultipleFormattedGraphicData = (
  graphicData: unknown
): graphicData is IFormattedGraphicData[][] => {
  const partial: Partial<IFormattedGraphicData[][]> = graphicData as Partial<
    IFormattedGraphicData[][]
  >;
  return partial[0] !== undefined && partial[0][0] !== undefined;
};

export const isBloodPressureSingleData = (
  response: unknown
): response is IDetailedSingleBloodPressureData => {
  const partial: Partial<IDetailedSingleBloodPressureData> =
    response as Partial<IDetailedSingleBloodPressureData>;
  return partial.sys !== undefined;
};

export const isBloodPressureSingleDataId = (
  response: unknown
): response is IBloodPressureGraphicDataId => {
  const partial: Partial<IBloodPressureGraphicDataId> =
    response as Partial<IBloodPressureGraphicDataId>;
  return partial !== undefined && partial.sysId !== undefined;
};

export const generateScatterColor = (val: number): string => {
  if ((val as number) >= 0 && (val as number) <= 1) {
    return '#43B07C';
  }
  if ((val as number) > 1 && (val as number) <= 3) {
    return ' #46BA82';
  }
  if ((val as number) > 3 && (val as number) <= 5) {
    return '#F9C74F';
  }

  if ((val as number) > 5 && (val as number) <= 7) {
    return '#F8961E';
  }

  if ((val as number) > 7 && (val as number) <= 9) {
    return '#FF4F01';
  }

  if ((val as number) > 9 && (val as number) <= 10) {
    return '#FD515F';
  }
};

export const getPainScatterColorOfNewest = (val) => {
  if ((val as number) >= 0 && (val as number) <= 1) {
    return 'rgba(174, 220, 197, 1)';
  }
  if ((val as number) > 1 && (val as number) <= 3) {
    return 'rgba(70, 186, 130, 0.5)';
  }
  if ((val as number) > 3 && (val as number) <= 5) {
    return 'rgba(249, 199, 79, 0.5)';
  }

  if ((val as number) > 5 && (val as number) <= 7) {
    return 'rgba(252, 203, 175, 1)';
  }

  if ((val as number) > 7 && (val as number) <= 9) {
    return 'rgba(251, 209, 217, 1)';
  }

  if ((val as number) > 9 && (val as number) <= 10) {
    return 'rgba(253, 81, 95, 0.5)';
  }
};

export const getScatterColorOfNewest = (val) => {
  switch (val) {
    case 5:
      return '#D1EEFF';

    case 4:
      return '#CFF7E9';

    case 3:
      return '#FFF6C6';

    case 2:
      return '#FFF4E3';

    case 1:
      return '#FFE5EA';

    default:
      return '#FFE5EA';
  }
};

export const getScatterStrokeColor = (val) => {
  switch (val) {
    case 5:
      return '#1D9DE6';

    case 4:
      return '#46BA82';

    case 3:
      return '#FFE872';

    case 2:
      return '#FFA552';

    case 1:
      return '#FC6262';

    default:
      return '#1D9DE6';
  }
};

export const orderNoToStrokeWidth = (val: number) => {
  switch (val) {
    case 0:
      return { stroke: 0, width: 6 };
    case 1:
      return { stroke: 5, width: 9 };

    case 2:
      return { stroke: 4, width: 7 };

    case 3:
      return { stroke: 3, width: 6 };

    case 4:
      return { stroke: 2, width: 6 };
    case 5:
      return { stroke: 1, width: 6 };

    default:
      return { stroke: 5, width: 8 };
  }
};

export const getBarChartColor = (val: number): string => {
  if ((val as number) < 0.8) {
    return ' #FFEFB8;';
  }
  if ((val as number) >= 0.8 && (val as number) <= 1.3) {
    return '#46BA82';
  }
  if ((val as number) >= 1.4 && (val as number) <= 1.7) {
    return '#FFCE85';
  }

  if ((val as number) >= 1.8) {
    return '#FF9DAF';
  }
};

export const getBarChartColorArr = (val: any[]) => {
  let colors = [];

  for (let i = 0; i < val?.length; i++) {
    if (val?.[i] && val?.[i]?.acwr === null) colors.push('');
    if ((val?.[i]?.acwr as number) < 0.8) {
      colors.push('#F9C750');
    }
    if (
      (val?.[i]?.acwr as number) >= 0.8 &&
      (val?.[i]?.acwr as number) <= 1.3
    ) {
      colors.push('#46BA82');
    }
    if (
      (val?.[i]?.acwr as number) >= 1.4 &&
      (val?.[i]?.acwr as number) <= 1.7
    ) {
      colors.push('#F8961D');
    }

    if ((val?.[i]?.acwr as number) >= 1.8) {
      colors.push('#FD515F');
    } else {
      colors.push('#FD515F');
    }
  }

  return colors.slice(0, 7);
};

import { TFunction } from 'i18next';
/* eslint-disable @typescript-eslint/no-unused-expressions */

import { TaskInput } from '@actimi/core-migration';

/* eslint-disable array-callback-return */
export const calculateExerciseTime = (values: {
  tempo: string;
  repetitions: string;
  sets: string;
}) => {
  console.log(values, 'VALUES');
  const { tempo, repetitions, sets } = values;
  let sumOfTempo = 0;
  let durationBasedSeconds = 0;

  if (tempo.includes('-')) {
    tempo.split('-').map((tempo) => {
      sumOfTempo = sumOfTempo + Number(tempo);
    });
  } else {
    sumOfTempo = Number(tempo);
  }

  const exerciseTime = sumOfTempo * Number(repetitions) * Number(sets);

  return exerciseTime;
};

export const getExerciseBase = (t: TFunction, input: TaskInput[]) => {
  const result = input
    .filter((k) => k.type.coding[0].code === 'edit-exercise-units')
    .map((q) => {
      return q.value.Quantity.id.includes('-') ? t('Tempo') : t('Duration');
    })
    .filter((x) => x !== undefined)
    .slice(-1)[0];

  return result;
};

export const getExercisePlanDuration = (plan) => {
  let totalTime = 0;
  let countOfTask = 0;
  plan.map((item) => {
    countOfTask = item.tasks.length;
    item.tasks.forEach((i) => {
      let repetition = 0;
      let rest = 0;
      let sets = 0;
      let tempo = '';
      let tempoSecond = 0;
      let durationBasedSeconds = 0;
      const tempoValue = i.input
        .filter((x) => x.type.coding?.[0].code === 'requestedTempo')
        .slice(-1)[0];

      if (tempoValue?.value.string) {
        if (tempoValue.value.string.includes('-')) {
          tempo = tempoValue.value.string;
        } else {
          durationBasedSeconds = Number(tempoValue.value.string);
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        i.input
          .filter((k) => k.type.coding[0].code === 'edit-exercise-units')
          .map((q) => {
            if (q.value.Quantity.id.includes('-')) {
              tempo = q.value.Quantity.id;
            } else {
              durationBasedSeconds = Number(q.value.Quantity.id);
            }
          })
          .filter((x) => x !== undefined)
          .slice(-1)[0];
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      i.input
        .filter((k) => k.type.coding[0].code === 'edit-exercise-units')
        .map((q) => {
          sets = Number(q.value.Quantity.unit);
          repetition = Number(q.value.Quantity.code);
          rest = q.value.Quantity.value;
        })
        .filter((x) => x !== undefined)
        .slice(-1)[0];

      const numberTempo = tempo.split('-').filter((x) => x !== '');

      tempoSecond =
        numberTempo.length !== 0 &&
        Number(numberTempo[0]) +
          Number(numberTempo[1]) +
          Number(numberTempo[2]);

      totalTime +=
        tempoSecond * repetition * sets + rest + durationBasedSeconds;
    });
  });

  return {
    exerciseCount: countOfTask,
    durationOfPlan: Number(totalTime)
  };
};

export const formatExerciseValues = (plan) => {
  let values = [];

  const exerciseCount = plan.map((row) => row.tasks.length);

  plan.map((row) => {
    row.tasks.map((item) => {
      const tempoValue = item.input
        .filter((x) => x.type.coding?.[0].code === 'requestedTempo')
        .slice(-1)[0];

      if (tempoValue?.value.string) {
      } else {
        values.push({
          tempo: item.input[item.input.length - 1].value.Quantity.id,
          sets: item.input[item.input.length - 1].value.Quantity.unit,
          repetitions: item.input[item.input.length - 1].value.Quantity.code,
          rest: item.input[item.input.length - 1].value.Quantity.value
        });
      }
    });
  });

  const durationOfPlan = values.reduce(
    (acc, curr) =>
      acc +
      calculateExerciseTime({
        tempo: curr.tempo,
        repetitions: curr.repetitions,
        sets: curr.sets
      }),
    0
  );

  console.log(values, 'XXX');
  const durationOfRest = values.reduce((acc, curr) => acc + curr.rest, 0);

  return {
    exerciseCount: Number(exerciseCount),
    durationOfPlan: durationOfPlan
  };
};

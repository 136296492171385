import { initialQuestionniareCannocial } from './../utils/Constants';
import {
  Device,
  Condition,
  Observation,
  QuestionnaireResponse,
  Task,
  PractitionerTaskRequestBody,
  TaskService,
  ObservationService,
  AcuteChronicWorkloadService,
  QuestionnaireService,
  TrainingService,
  ConditionService
} from '@actimi/core-migration';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { ErrorMessage } from 'src/content/dashboards/Database/utils';
import { IPatientDetail } from 'src/models/patient-detail';
import { AppThunk } from 'src/store';
import { TrainingSchedules } from '@actimi/core-migration/dist/api/services/interfaces/training';

export type PatientDetailMap = { [patientId: string]: IPatientDetail };

export type IFormState = { open: boolean; userId?: string };

export type RecentActivties = {
  observation: Observation;
  schedule: TrainingSchedules;
  tools: Device;
  questionnaire: QuestionnaireResponse;
  acuteWorkload: any;
  conditions: Condition;
};
export interface IPatientsDetailState {
  patientDetails: PatientDetailMap;
  patientIds: string[];
  isLoading: boolean;
  formState: IFormState;
  recentActivites: RecentActivties;
  practitionerTask: Task[];
}

const initialState: IPatientsDetailState = {
  patientDetails: {},
  patientIds: [],
  practitionerTask: undefined,
  isLoading: true,
  formState: {
    open: false,
    userId: null
  },
  recentActivites: {
    observation: undefined,
    schedule: undefined,
    tools: undefined,
    questionnaire: undefined,
    acuteWorkload: undefined,
    conditions: undefined
  }
};

const slice = createSlice({
  name: 'patients-detail',
  initialState,
  reducers: {
    setPatientsDetail(
      state,
      action: PayloadAction<{
        patientDetails: PatientDetailMap;
        patientIds: string[];
      }>
    ) {
      state.patientDetails = action.payload.patientDetails;
      state.patientIds = action.payload.patientIds;
      state.isLoading = false;
    },

    setPatientDetail(
      state,
      action: PayloadAction<{
        patientDetail: IPatientDetail;
      }>
    ) {
      const { patientDetail } = action.payload;

      state.patientDetails[patientDetail.id] = patientDetail;
      if (!state.patientIds.find((id) => patientDetail.id === id))
        state.patientIds = [patientDetail.id, ...state.patientIds];
    },
    deletePatientDetail(state, action: PayloadAction<{ patientId: string }>) {
      const { patientId } = action.payload;
      delete state.patientDetails[patientId];
      state.patientIds.filter((id) => patientId !== id);
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setFormState(state, action: PayloadAction<IFormState>) {
      state.formState = action.payload;
    },

    setRecentActivities(state, action: PayloadAction<RecentActivties>) {
      state.recentActivites = action.payload;
    },
    setPractitionerTask(state, action: PayloadAction<Task[]>) {
      state.practitionerTask = action.payload;
    },
    resetPractitionerTask(state) {
      Object.assign(state.practitionerTask, initialState.practitionerTask);
    },
    reset(state: IPatientsDetailState) {
      Object.assign(state, initialState);
    }
  }
});

export const reset = (): AppThunk => async (dispatch) =>
  dispatch(slice.actions.reset());

export const setPatientsDetail = (patientDetailList: IPatientDetail[]) => {
  return async (dispatch) => {
    const patientDetails = _.keyBy(patientDetailList, 'id');
    const patientIds = patientDetailList.map((x) => x?.id);
    dispatch(slice.actions.setPatientsDetail({ patientDetails, patientIds }));
  };
};

export const updatePatientDetail = (
  patientDetail: IPatientDetail
): AppThunk => {
  return async (dispatch) => {
    dispatch(slice.actions.setPatientDetail({ patientDetail }));
  };
};

export const getPractitionerTask = (
  body: PractitionerTaskRequestBody,
  whenDone?: () => void
): AppThunk => {
  return async (dispatch) => {
    const { getPractitionerTaskByRequesterId } = new TaskService();
    try {
      const practitionerTask = await getPractitionerTaskByRequesterId(body);
      dispatch(slice.actions.setPractitionerTask(practitionerTask));
      whenDone?.();
    } catch (err) {
      ErrorMessage('Error while fetching practitioner task', 'top-center');
    }
  };
};

export const getRecentActivtiesOfPatient = (
  patientId: string,
  organizationId: string,
  whenDone?: () => void
): AppThunk => {
  return async (dispatch) => {
    const { getObservations } = new ObservationService();
    const { getTrainingSchedule, getTrainingToolsByUserId } =
      new TrainingService();
    const { getLatestEntryOfQuestionnaireResponse } =
      new QuestionnaireService();
    const { getLatestAcuteChronicWorkload } = new AcuteChronicWorkloadService();
    const { getConditionByPatientId } = new ConditionService();
    try {
      const [
        observation,
        tools,
        schedule,
        questionnaire,
        acuteWorkload,
        conditions
      ] = await Promise.all([
        await getObservations({
          _count: 1,
          subject: `Patient/${patientId}`,
          _sort: '-createdAt'
        }),
        await getTrainingToolsByUserId(patientId, organizationId),
        await getTrainingSchedule(patientId),
        await getLatestEntryOfQuestionnaireResponse(patientId, organizationId),
        await getLatestAcuteChronicWorkload(patientId, organizationId),
        await getConditionByPatientId({ patientId, organizationId })
      ]);

      console.log(questionnaire, 'questionnaire');

      dispatch(
        slice.actions.setRecentActivities({
          observation: observation[0],
          schedule: schedule[0],
          tools: tools,
          questionnaire: questionnaire[0],
          acuteWorkload: acuteWorkload[0],
          conditions: conditions[0]
        })
      );
    } catch (err) {
      console.log(err, 'ERROR');
      ErrorMessage('Error while fetching recent activities', 'top-center');
    }

    whenDone?.();
  };
};

export const setFormState = slice.actions.setFormState;
export const setIsLoading = slice.actions.setIsLoading;
export const deletePatientDetail = slice.actions.deletePatientDetail;

export const reducer = slice.reducer;

export default slice;

import { TimeSpan } from 'src/models/observation/enums/TimeSpan';
import { GetQuestionnaireScoreQuery } from './../../models/care-team/GetGraphicInformationQuery';
import {
  GetSymptomGraphicInformationQuery,
  GraphicInformation,
  GraphicWaveValues,
  Task
} from '@actimi/core-migration';
/* eslint-disable eqeqeq */
import AxiosJwt from '../../utils/axios';
import {
  LoginResponse,
  LoginResponseError
} from './types/responses/LoginResponse';
import { RegisterResponse } from './types/responses/RegisterResponse';
import { GetCareTeamsQuery } from '../../models/care-team/GetCareTeamsQuery';
import { Patient } from '../../models/patient/Patient';
import { CreatePatient } from '../../models/patient/CreatePatient';
import { IObservation } from '../../models/observation/IObservation';
import { GetObservationsQuery } from '../../models/observation/GetObservationsQuery';
import { GetCareTeamQuery } from '../../models/care-team/GetCareTeamQuery';
import { CreateCareTeam } from '../../models/care-team/CreateCareTeam';
import { IGraphicInformation } from '../../models/graphic/IGraphic';
import { GetGraphicInformationQuery } from '../../models/care-team/GetGraphicInformationQuery';
import { UpdateCareTeam } from '../../models/care-team/UpdateCareTeam';
import { UpdatePatient } from '../../models/patient/UpdatePatient';
import { INotificationSettings } from '../../models/general/INotificationSettings';
import {
  ILatestMeasures,
  ILatestMeasureWithCodeResponse
} from '../../models/graphic/ILatestMeasures';
import { CreateObservation } from '../../models/observation/CreateObservation';
import { IRegisterInformation } from '../../models/user/IRegisterInformation';
import {
  IUpdateEmailInformation,
  IUpdatePasswordInformation,
  IUserPatchData
} from '../../models/user/IUpdateUserInformation';
import { ICareTeam } from '../../models/care-team/ICareTeam';
import { PatchUserResponse } from './types/responses/UpdatePasswordResponse';
import { INotificationSettingsTriggers } from '../../models/general/INotificationSettingsTriggers';
import {
  Annotation,
  PatientContact,
  AuditEvent,
  Observation,
  Questionnaire
} from './types/aidbox/AidboxTypes';
import { ObservationCode } from '../../models/observation/enums/ObservationCode';
import { VerifyResetTokenResponse } from './types/responses/VerifyResetTokenResponse';
import { ChangePasswordBody } from '../../models/user/ChangePasswordBody';
import { ICareTeamParticipant } from '../../models/care-team/ICareTeamParticipant';
import { IUserNotifications } from '../../slices/user-notifications';
import ChatWSHandler, { IChatMessage } from '../../utils/ChatWSHandler';
import { ILimitValues } from '../../slices/limit-values';

import { IQuestionnaireResponseBody } from '../../models/symptom-questionnaire/IQuestionnaireResponseBody';
import { IQuestionnaireResponse } from '../../models/symptom-questionnaire/IQuestionnaireResponse';
import { IUserListItem } from '../../models/general/IUserListItem';
import axios from '../../utils/axios';
import { User } from '../../models/user';
import {
  CommunicationRequest,
  TaskDetailStatusReason,
  TaskDetailWith
} from '../../slices/communication-request';
import { AxiosResponse } from 'axios';
import { IPatientProfile } from 'src/models/general/IPatientProfile';
import { ITableItem } from '../../models/dashboard-table';
import { IPatientDetail } from 'src/models/patient-detail';
import {
  ITaskComments,
  ITaskDetails,
  ITaskUpdate,
  TaskStatus
} from '../../models/tasks/ITasks';
import {
  Doctor,
  IClinic,
  IOrganizationInfoBody,
  IPractitionerInfoBody
} from 'src/models/user/IPractitionerInfoBody';
import { IClinicMembers } from 'src/models/clinic/IClinicMembers';
import { DashboardTableFilter } from 'src/utils/DashbordTable';
import { IGetPain, IMessage, IUserOnlineStatus } from '@actimi/core-migration';
import { QuestionnaireTypes } from 'src/models/symptom-questionnaire/QuestionnaireTypes';
import { IScriptMessage } from 'src/models/ready-message';
/**
 * Used for methods that fetches data asynchronously.
 * Caches the promise according to arguments given to function UNTILL THE PROMISE BEING FULFILLED.
 * Must be used under extreme caution (only use it if args are string and request type is get)
 */
function MemoPromise() {
  //TODO: Test it with multiple methods
  const cache: { [url: string]: Promise<AxiosResponse<any>> } = {};
  return (t, m, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;
    descriptor.value = async function (...args: string[]) {
      /**
       * 1. Parse args to one string to be used identifier of the request.
       * 2. If the request made again before it is has been fulfilled, then return the same request.
       * 3. Else put the new request until it is being fulfilled. (remove it from cache with finally method)
       * 4. return the new request from cache
       */
      var cacheKey = args.toString();
      if (cacheKey in cache) return await cache[cacheKey];
      cache[cacheKey] = originalMethod.apply(this, args);
      cache[cacheKey].finally(() => {
        delete cache[cacheKey];
      });
      return cache[cacheKey];
    };
  };
}

export class MiddlewareService {
  public static async getPatientProfilesWithLimitValues(
    organizationId: string
  ): Promise<{ limitValues: ILimitValues[]; patients: IPatientProfile[] }> {
    const { data } = await AxiosJwt.get(`/patient-profile/${organizationId}`);
    console.log(data, 'data');
    const res = { patients: [], limitValues: [] };
    data.forEach(({ limitValues, patient }) => {
      if (limitValues) res.limitValues.push(limitValues);
      res.patients.push(patient);
    });
    return res;
  }
  public static async checkPatientEmail(email: string): Promise<boolean> {
    const { data } = await AxiosJwt.get(`/check-patient-email/${email}`);
    return data;
  }

  public static getPain = async ({
    _count,
    pain,
    patientId
  }: IGetPain): Promise<Observation[]> => {
    const { data } = await AxiosJwt.get(
      `/mood-tracker/pain?patientId=${patientId}&_count=${_count}&pain=${pain}`
    );
    return data;
  };

  public static async getLogs(
    userId: string,
    page: number,
    count: number,
    typeAutocomplete: string,
    sortAutocomplete: string,
    startDate: Date,
    endDate: Date
  ): Promise<{
    logs: AuditEvent[];
    total: number;
  }> {
    const entityName =
      typeAutocomplete == 'All' ? '' : `&entity-name=${typeAutocomplete}`;
    const date =
      startDate != undefined && endDate != undefined
        ? `&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
        : '';
    const sort =
      sortAutocomplete == 'Latest first'
        ? `&_sort=-createdAt`
        : `&_sort=createdAt`;
    const { data } = await AxiosJwt.get(
      `/logs?userId=${userId}&page=${page}&count=${count}${entityName}&sub-type=create,patch,update${date}${sort}`
    );
    return data;
  }

  // communication-request
  public static async getCommunicationRequest(
    recipient: string | string[],
    subject?: string,
    status?: string
  ): Promise<CommunicationRequest[]> {
    const { data } = await AxiosJwt.get(
      `/communication-request?recipient=${JSON.stringify(recipient)}${
        subject != undefined ? `&subject=${subject}` : ''
      }${status != undefined ? `&status=${status}` : ''}`
    );
    return data;
  }
  public static async getPractitionerInfo(id: string): Promise<Doctor> {
    const { data } = await AxiosJwt.get(`/web-doctor/practitioner/${id}`);
    return data;
  }

  public static async getClinicInfo(id: string): Promise<IClinic> {
    const { data } = await AxiosJwt.get(`/organization/${id}`);
    return data;
  }
  public static async updatePractitionerInfo(
    id: string,
    body: Partial<IPractitionerInfoBody>
  ): Promise<IPractitionerInfoBody> {
    const { data } = await AxiosJwt.patch(`/web-doctor/practitioner/${id}`, {
      ...body
    });
    return data;
  }

  public static async deletePractitionerAccount(id: string): Promise<void> {
    await AxiosJwt.delete(`/web-doctor/practitioner/${id}`);
  }
  public static async getTaskDetails(
    statusReason?: TaskDetailStatusReason,
    count?: number,
    sort?: string,
    _with?: TaskDetailWith,
    subject?: string,
    recipient?: string,
    status?: string[]
  ): Promise<ITaskDetails[]> {
    const params = new URLSearchParams({
      statusReason,
      _count: count?.toString(),
      _sort: sort,
      _with: _with,
      subject,
      recipient,
      status: status?.join(',') || undefined
    });
    const { data } = await AxiosJwt.get(
      `/communication-request/task-details?${params?.toString()}`
    );
    return data;
  }

  public static async addTaskDetailNote(id: string, note: ITaskComments) {
    await AxiosJwt.patch(`communication-request/task-details/note/${id}`, note);
  }

  public static async changeTaskDetailStatus(id: string, status: TaskStatus) {
    await AxiosJwt.patch(`communication-request/task-detail-status/${id}`, {
      status
    });
  }
  public static async updateClinicInfo(
    id: string,
    body: Partial<IOrganizationInfoBody>
  ): Promise<IOrganizationInfoBody> {
    const { data } = await AxiosJwt.patch(`/organization/${id}`, {
      ...body
    });
    return data;
  }

  public static async changePasswordAuth(body: {
    email: string;
    currentPassword: string;
    password: string;
  }): Promise<void> {
    await AxiosJwt.put(`/change-password-auth`, body);
  }

  public static async updateCommunicationRequest(
    communicationRequest: CommunicationRequest
  ): Promise<CommunicationRequest> {
    const {
      status,
      statusReason,
      recipient,
      authoredOn,
      note,
      category,
      subject
    } = communicationRequest;
    const { data } = await AxiosJwt.patch(
      `/communication-request/${communicationRequest.id}`,
      {
        status,
        statusReason,
        recipient,
        authoredOn,
        note,
        category,
        subject
      }
    );
    return data;
  }

  public static async addCommunicationRequestRecipient(
    id: string,
    recipient: string
  ): Promise<CommunicationRequest> {
    const { data } = await AxiosJwt.patch(
      `/communication-request/recipient/${id}`,
      {
        recipient
      }
    );
    return data;
  }

  @MemoPromise()
  public static async getTableData(
    meId: string,
    organizationId: string,
    count: number,
    page: number,
    sort?: { field: string; order: 'asc' | 'desc' },
    searchArg?: string,
    taskFilter?: DashboardTableFilter
  ): Promise<{ items: ITableItem[]; total: number }> {
    let sortParams = '';
    let searchParam = '';
    if (sort) sortParams += `&sort=${sort.field}&order=${sort.order}`;
    if (!!searchArg) searchParam += `&filter=${searchArg}`;
    if (!!taskFilter) searchParam += `&taskFilter=${taskFilter}`;
    const { data } = await AxiosJwt.get(
      `/dashboard-web/table-data/${meId}/${organizationId}?count=${count}&page=${page}` +
        sortParams +
        searchParam
    );
    return data;
  }
  public static async deleteClinicInvitation(id: string): Promise<void> {
    await AxiosJwt.delete(`/web-doctor/clinic-invitation/${id}`);
  }

  public static async registerAssociate(
    name: string,
    surname: string,
    email: string,
    phone: string,
    password: string,
    clinicId: string
  ): Promise<{ jwt: string; user: User }> {
    const { data } = await AxiosJwt.post('/web-doctor/register-associate', {
      name,
      surname,
      email,
      phone,
      password,
      clinicId
    });
    return data;
  }

  public static async getAllNotifications(
    meId: string,
    page: number,
    count: number,
    members: IUserListItem[]
  ): Promise<IObservation[]> {
    const membersId = members?.map((x) => x.id);
    const { data } = await AxiosJwt.get(
      `/web-notifications/${meId}?_page=${page}&_count=${count}&members=${membersId}`,
      { headers: { 'cache-control': 'no-cache' } }
    );
    return data.observations;
  }

  // dashboard-web end

  // Patient Details
  public static async fetchPatientDetailsTableData(
    organizationId: string,
    params: {
      pageSize?: number;
      page?: number;
      sort?: 'newestFirst' | 'alphabetically';
      searchText?: string;
    }
  ) {
    let url = `/patient-detail/table-data/${organizationId}`;

    const { data } = await AxiosJwt.post<{
      items: IPatientDetail[];
      total: number;
    }>(url, params);

    return data;
  }

  public static async createUpdatePatientDetails(
    body: IPatientDetail,
    organizationId?: string
  ) {
    const { data } = await AxiosJwt.post(
      '/patient-detail/create-update-details',
      body,
      {
        params: { organizationId }
      }
    );
    return data;
  }
  public static async deletePatient(patientId: string) {
    const { data } = await AxiosJwt.delete(`/patient-detail/${patientId}`);
    return data;
  }

  // cache methods start

  public static async updateCache(
    resourceType:
      | 'Observation'
      | 'CareTeam'
      | 'Patient'
      | 'Message'
      | 'LimitValues',
    id?: string
  ): Promise<ILimitValues> {
    const { data } = await AxiosJwt.get(`/redis/${resourceType}?id=${id}`);
    return data;
  }

  // cache methods end

  // Limit Values

  public static async getLimitValue(
    organizationId: string,
    patientId: string
  ): Promise<ILimitValues> {
    const { data } = await AxiosJwt.get(
      `/limit-values/${patientId}/${organizationId}`,
      {
        headers: { 'cache-control': 'no-cache' }
      }
    );
    return data;
  }

  public static async createLimitValue(
    body: ILimitValues
  ): Promise<ILimitValues> {
    const { data } = await AxiosJwt.post(`/limit-values`, body);
    return data;
  }

  public static async updateLimitValue(
    body: Partial<ILimitValues>
  ): Promise<ILimitValues> {
    const { data } = await AxiosJwt.patch(`/limit-values`, body);
    return data;
  }

  // End Limit Values

  // Chat
  public static async getMessages(
    from: string,
    to: string,
    userId: string,
    chatId: string,
    count: number,
    page: number
  ): Promise<{ messages: IMessage[]; chatId: string; total: number }> {
    const { data } = await AxiosJwt.post(
      `/tele-heath/get-messages`,
      {
        from,
        to,
        userId,
        chatId,
        count,
        page
      },
      { headers: { 'cache-control': 'no-cache' } }
    );
    return data;
  }

  public static async changeUserOnlineStatus(
    userId: string,
    isOnline: boolean,
    lastActiveAt: string
  ): Promise<void> {
    await AxiosJwt.patch(`/tele-heath/change-online-status`, {
      userId,
      isOnline,
      lastActiveAt
    });
  }

  public static async getChatUser(
    userId: string
  ): Promise<Array<{ id: string; name: string; avatar: string }>> {
    const { data } = await AxiosJwt.get(
      `/tele-heath/chat-users/doctor/${userId}`
    );
    return data;
  }

  public static async getChatRooms(
    users: Array<{ userId: string; to: string }>
  ): Promise<IChatMessage[]> {
    const { data } = await AxiosJwt.post(`/tele-heath/chatRoom`, users);
    return data;
  }

  public static async getTotalUnreadMessages(to: string): Promise<number> {
    const { data } = await AxiosJwt.post(
      `/tele-heath/get-total-unread-messages`,
      {
        to
      }
    );
    return data;
  }

  public static async updateMessageStatus(
    messageId: string,
    userId: string,
    status: 'SENT' | 'SEEN' | 'ERROR' | 'UNKNOWN'
  ): Promise<void> {
    await AxiosJwt.patch(`/tele-heath/update-messages-status`, {
      messageId,
      status
    });
  }

  public static async sendMessage(
    chatId: string,
    id: string,
    to: string,
    from: string,
    text: string,
    createdAtTime: string,
    status: 'SEEN' | 'SENT' | 'ERROR' | 'UNKNOWN',
    previousMessageId: string
  ): Promise<void> {
    await AxiosJwt.post(`/tele-heath/send-messages`, {
      chatId,
      id,
      to,
      from,
      text,
      createdAtTime,
      status,
      previousMessageId
    });
    ChatWSHandler.sendMessage(
      chatId,
      id,
      to,
      from,
      text,
      status,
      previousMessageId
    );
  }

  public static async getUserOnlineStatus(
    userId: string
  ): Promise<IUserOnlineStatus> {
    const { data } = await AxiosJwt.get(
      `/tele-heath/user-online-status/${userId}`
    );
    return data;
  }

  // End Chat

  // UserNotifications START
  public static async getNotifications(
    meId: string,
    _count: number,
    _page: number
  ): Promise<IUserNotifications[]> {
    const { data } = await AxiosJwt.get(
      `/user-notifications/${meId}?_count=${_count}&_page=${_page}`
    );
    return data;
  }

  public static async getUnseenNotifications(meId: string): Promise<number> {
    const { data } = await AxiosJwt.get(`/user-notifications/unseen/${meId}`);
    return data;
  }

  public static async markNotificationsAsRead(
    body: Array<{ id: string; read: boolean }>
  ): Promise<IUserNotifications[]> {
    const { data } = await AxiosJwt.post(`/user-notifications/mark-read`, body);
    return data;
  }

  // UserNotifications END

  public static async resetPassword(email: string): Promise<void> {
    await AxiosJwt.post(`/reset-password/${email}`);
  }

  public static async verifyResetToken(
    token: string
  ): Promise<VerifyResetTokenResponse> {
    const { data } = await AxiosJwt.get(`/verify-reset-token/${token}`);
    return data;
  }

  public static async changePassword(body: ChangePasswordBody): Promise<void> {
    await AxiosJwt.put(`/change-password`, body);
  }

  public static async createObservation(
    body: CreateObservation
  ): Promise<string> {
    const { data } = await AxiosJwt.post(`/observation/create`, {
      ...body,
      hasMember: this.hasMember(body.hasMember)
    });
    return data;
  }

  public static async updateObservationNote(
    id: string,
    body: Annotation[]
  ): Promise<string> {
    const { data } = await AxiosJwt.patch(`/observation/${id}`, {
      note: body
    });
    return data;
  }

  public static async getLatestMeasurements(
    subject: string
  ): Promise<ILatestMeasures> {
    const { data } = await AxiosJwt.get(`/latest-measured/${subject}`);
    return data;
  }

  public static async updatePatient(body: UpdatePatient): Promise<Patient> {
    const { data } = await AxiosJwt.put(`/patient`, {
      ...body
    });
    return data;
  }

  public static async updatePatientField(
    id: string,
    body: Partial<IUserPatchData>
  ): Promise<Patient> {
    const { data } = await AxiosJwt.patch(`/patient/${id}`, {
      ...body
    });
    return data;
  }

  public static async updatePatientContact(
    patientId: string,
    contacts: PatientContact[]
  ): Promise<PatientContact[]> {
    const { data } = await AxiosJwt.post(
      `/patient-detail/create-update-contact/${patientId}`,
      contacts
    );
    return data;
  }

  public static async deleteCareTeam(careTeamId: string): Promise<void> {
    await AxiosJwt.delete(`/care-team/${careTeamId}`);
  }

  // public static async getMoodTracker(
  //   query: IGetMoodTracker
  // ): Promise<IMoodTracker[]> {
  //   const { data } = await AxiosJwt.get(
  //     `/mood-tracker?patient=${query.patient}&_count=${query._count}&mood=${query.mood}`
  //   );
  //   return data;
  // }

  // public static async getFeelings(
  //   ids: string[] | undefined
  // ): Promise<IFeelings[]> {
  //   if (ids !== undefined) {
  //     const { data } = await AxiosJwt.get(`/mood-tracker/feelings?ids=${ids}`);
  //     return data;
  //   } else {
  //     const { data } = await AxiosJwt.get(`/mood-tracker/feelings`);
  //     return data;
  //   }
  // }

  // public static async updateMoodTrackerCount(
  //   moodTrackerType: MoodTrackerType,
  //   id: string,
  //   used: number
  // ): Promise<IActivity | IFeelings> {
  //   const { data } = await AxiosJwt.put(
  //     `/mood-tracker/${moodTrackerType}/${id}`,
  //     {
  //       used
  //     }
  //   );
  //   return data;
  // }

  // public static async createFeelings(name: string): Promise<IFeelings> {
  //   const { data } = await AxiosJwt.post(`/mood-tracker/feelings`, {
  //     name: name
  //   });
  //   return data;
  // }

  // public static async deleteFeelings(id: string): Promise<IFeelings> {
  //   const { data } = await AxiosJwt.delete(`/mood-tracker/feelings/${id}`);
  //   return data;
  // }

  // public static async getActivities(
  //   ids: string[] | undefined,
  //   userId: string,
  //   count: number
  // ): Promise<IActivity[]> {
  //   if (ids !== undefined) {
  //     const { data } = await AxiosJwt.get(`/mood-tracker/activity?ids=${ids}`);
  //     return data;
  //   } else {
  //     const { data } = await AxiosJwt.get(
  //       `/mood-tracker/activity?userId=${userId}&count=${count}`
  //     );
  //     return data;
  //   }
  // }

  // public static async createActivities(
  //   name: string,
  //   userId: string
  // ): Promise<IActivity> {
  //   const { data } = await AxiosJwt.post(`/mood-tracker/activity`, {
  //     name,
  //     userId
  //   });
  //   return data;
  // }

  // public static async deleteActivity(id: string): Promise<IActivity> {
  //   const { data } = await AxiosJwt.delete(`/mood-tracker/activity/${id}`);
  //   return data;
  // }

  public static async updateCareTeam(
    careTeamId: string,
    body: UpdateCareTeam
  ): Promise<string> {
    const { data } = await AxiosJwt.put(`/care-team/${careTeamId}`, {
      id: careTeamId,
      name: body.name,
      participant: body.participant.map((patient) => ({
        member: {
          resourceType: 'Patient',
          id: patient.id
        },
        role: patient.isAdmin
          ? [{ id: 'admin', text: 'CareTeam Administrator' }]
          : [{ id: 'member', text: 'CareTeam Member' }]
      })),
      extension: [
        {
          id: 'avatar',
          url: 'CareTeam Avatar',
          valueAttachment: {
            id: 'avatar',
            title: 'CareTeam Avatar',
            data: body.avatar
          }
        },
        {
          id: 'description',
          url: 'CareTeam Description',
          valueString: body.description
        },
        {
          id: 'created-by',
          url: 'CareTeam Created By',
          valueString: body.createdBy
        },
        {
          id: 'created-at',
          url: 'CareTeam Created At',
          valueString: body.createdAt
        }
      ]
    });
    return data;
  }

  //End Mood Tracker Methods

  public static async makeAdmin(
    careTeamId: string,
    userId: string,
    currentCareTeamAdminIds: string[]
  ): Promise<void> {
    const { data } = await AxiosJwt.put(`/care-team/admins/${careTeamId}`, {
      currentCareTeamAdminIds
    });
    return data;
  }

  public static async createCareTeam(
    createCareTeam: CreateCareTeam
  ): Promise<ICareTeam> {
    const { data } = await AxiosJwt.post(`/care-team`, {
      id: createCareTeam.id,
      name: createCareTeam.name,
      participant: createCareTeam.participant.map((patient) => ({
        member: {
          resourceType: 'Patient',
          id: patient.id
        },
        role: patient.isAdmin
          ? [{ id: 'admin', text: 'CareTeam Administrator' }]
          : [{ id: 'member', text: 'CareTeam Member' }]
      })),
      extension: [
        {
          id: 'avatar',
          url: 'CareTeam Avatar',
          valueAttachment: {
            id: 'avatar',
            title: 'CareTeam Avatar',
            data: createCareTeam.avatar
          }
        },
        {
          id: 'description',
          url: 'CareTeam Description',
          valueString: createCareTeam.description
        },
        {
          id: 'created-by',
          url: 'CareTeam Created By',
          valueString: createCareTeam.createdBy
        },
        {
          id: 'created-at',
          url: 'CareTeam Created At',
          valueString: createCareTeam.createdAt
        }
      ]
    });
    return data;
  }

  public static async getObservations(
    query: GetObservationsQuery
  ): Promise<IObservation[]> {
    const { data } = await AxiosJwt.post('/observation', {
      ...query
    });
    return data;
  }

  public static async createPatient(
    createPatientInfo: CreatePatient
  ): Promise<Patient> {
    const { data } = await AxiosJwt.post('/patient', {
      data: { ...createPatientInfo }
    });
    return data;
  }

  public static async getPatient(id: string): Promise<Patient> {
    const { data } = await AxiosJwt.get(`Patient/${id}`);
    return data;
  }

  public static async getMultiplePatients(ilike: string): Promise<Patient[]> {
    const { data } = await AxiosJwt.get(`multiple-patients/${ilike}`);
    return data;
  }

  public static async updatePassword(
    id: string,
    updatePasswordBody: IUpdatePasswordInformation
  ): Promise<PatchUserResponse | LoginResponseError> {
    const { data } = await AxiosJwt.put(`/update-password/${id}`, {
      email: updatePasswordBody.email,
      oldPassword: updatePasswordBody.oldPassword,
      password: updatePasswordBody.password
    });
    return data;
  }

  public static async updateEmail(
    id: string,
    updateEmailBody: IUpdateEmailInformation
  ): Promise<PatchUserResponse | LoginResponseError> {
    const { data } = await AxiosJwt.put(`/update-email/${id}`, {
      email: updateEmailBody.email,
      newEmail: updateEmailBody.newEmail,
      password: updateEmailBody.password
    });
    return data;
  }

  public static async getCareTeam(query: GetCareTeamQuery): Promise<ICareTeam> {
    const { data } = await AxiosJwt.get(`/care-team?id=${query.id}`);
    return data;
  }

  public static async getCareTeamCount(userId: string): Promise<number> {
    const { data } = await AxiosJwt.get(`/care-team/count/${userId}`);
    return data;
  }

  public static async deleteUser(userId: string): Promise<void> {
    const { data } = await AxiosJwt.delete(`/user/${userId}`);
    return data;
  }

  public static async deleteObservation(id: string): Promise<void> {
    const { data } = await AxiosJwt.delete(`/observation/${id}`);
    return data;
  }

  public static async getGraphicInformation(
    query: GetGraphicInformationQuery | GetSymptomGraphicInformationQuery
  ): Promise<GraphicInformation | GraphicWaveValues[]> {
    const { data } = await AxiosJwt.post('/observation/graphic-information', {
      ...query
    });
    return data;
  }

  public static async registerDoctor(
    name: string,
    email: string,
    password: string,
    surname: string,
    phoneNumber: string,
    practiceName: string,
    practicePhoneNumber: string,
    practiceStreetAddress: string,
    practiceCity: string,
    practiceZipCode: string,
    members: string[]
  ): Promise<{ jwt: string; user: User }> {
    const { data } = await axios.post('/web-doctor/register', {
      name,
      surname,
      password,
      email,
      phoneNumber,
      practiceName,
      practicePhoneNumber,
      practiceStreetAddress,
      practiceCity,
      practiceZipCode,
      members
    });
    return data;
  }

  public static async getClinicInvitation(
    id: string
  ): Promise<
    { email: string; id: string; token: string; clinicId: string } | undefined
  > {
    const { data } = await AxiosJwt.get(`/web-doctor/clinic-invitation/${id}`);
    return data;
  }

  public static async getAllGraphicInformation(
    queries: GetGraphicInformationQuery[]
  ): Promise<IGraphicInformation[]> {
    const { data } = await AxiosJwt.post(`/graphs/information`, { queries });
    return data;
  }

  public static async getCareTeamParticipants(
    careTeamId: string
  ): Promise<ICareTeamParticipant[]> {
    const { data } = await AxiosJwt.get(`/careteam-participants/${careTeamId}`);
    return data;
  }

  public static async patchCareTeam(
    id: string,
    body: Partial<UpdateCareTeam>
  ): Promise<ICareTeam> {
    const { data } = await AxiosJwt.patch(`/care-team/${id}`, {
      ...body
    });
    return data;
  }

  public static async getCareTeams(
    query: GetCareTeamsQuery
  ): Promise<ICareTeam[]> {
    const { data } = await AxiosJwt.get(
      `/care-teams?participant=${query.participant}&count=${query.count}&page=${query.page}`
    );
    return data;
  }

  public static async checkEmail(email: string): Promise<boolean> {
    const { data } = await AxiosJwt.get(`/check-email/${email}`);
    return data;
  }

  public static async register(
    info: IRegisterInformation
  ): Promise<RegisterResponse> {
    const { data } = await AxiosJwt.post('/register', {
      ...info
    });
    return data;
  }

  public static async getNotificationSettings(
    userId: string | undefined
  ): Promise<INotificationSettings> {
    const { data } = await AxiosJwt.get(`/notification-settings/${userId}`, {
      headers: { 'cache-control': 'no-cache' }
    });
    return data[0];
  }

  public static async updateNotificationSettings(
    userId: string | undefined,
    triggers: Partial<INotificationSettingsTriggers>
  ): Promise<INotificationSettings> {
    const { data } = await AxiosJwt.put(`/notification-settings/${userId}`, {
      triggers
    });
    return data[0];
  }

  public static async createNotificationSettings(
    userId: string | undefined,
    triggers: INotificationSettingsTriggers
  ): Promise<INotificationSettings> {
    const { data } = await AxiosJwt.post(`/notification-settings`, {
      userId,
      triggers
    });
    return data[0];
  }

  public static async createExercisePlan(body: Task[]) {
    const { data } = await AxiosJwt.post(`/task`, body);
    return data;
  }

  public static async login(
    username: string,
    password: string
  ): Promise<LoginResponse | LoginResponseError> {
    const { data } = await AxiosJwt.get(
      `/web-doctor/login?username=${username}&password=${password}`
    );
    return data;
  }

  private static hasMember(members?: string[]): string[] {
    if (members === undefined) {
      return [];
    }
    return members.map((x) => `Observation/${x}`);
  }

  public static async getLatestMeasurementWithCode(
    subject: string,
    code: ObservationCode
  ): Promise<ILatestMeasureWithCodeResponse | undefined> {
    const { data } = await AxiosJwt.get(`/latest-measured/${subject}/${code}`, {
      // must be fixed
      headers: { Authorization: 'UserState.jwt' }
    });
    return data;
  }
  public static async getLatestQuestionnaireResponse(
    body: IQuestionnaireResponseBody
  ): Promise<IQuestionnaireResponse> {
    const { data } = await AxiosJwt.get(
      `/questionnaire-response/latest/${body.userId}/${body.questionnaire}?lang=${body.lang}`
    );
    return data;
  }
  public static async getQuestionnaireResponse(
    body: IQuestionnaireResponseBody
  ): Promise<IQuestionnaireResponse[]> {
    const { data } = await AxiosJwt.get(
      `/questionnaire-response/${body.userId}/${body.questionnaire}?lang=${body.lang}`
    );
    return data;
  }

  public static async getExerciseQuestionnaire(
    title: string,
    lang: string
  ): Promise<Questionnaire[]> {
    const { data } =
      await AxiosJwt.get(`/questionnaire/title/mifysio-exercise?&lang=en
    `);
    return data;
  }

  public static async getQuestionnaire(
    questionnaireType: QuestionnaireTypes,
    lang: string
  ): Promise<Questionnaire> {
    const { data } = await AxiosJwt.get(
      `/questionnaire/${questionnaireType}?lang=${lang}`
    );
    return data;
  }

  public static async createQuestionnaire(
    body: Questionnaire
  ): Promise<Questionnaire> {
    const { data } = await AxiosJwt.post(`/questionnaire`, body);
    return data;
  }
  public static async updateQuestionnaire(
    body: Questionnaire
  ): Promise<Questionnaire> {
    const { data } = await AxiosJwt.patch(`/questionnaire`, body);
    return data;
  }

  public static async getQuestionnaireById(
    questionnaireId: string
  ): Promise<Questionnaire> {
    const { data } = await AxiosJwt.get(`/questionnaire/id/${questionnaireId}`);
    return data;
  }

  public static async deleteQuestionnaireById(id: string): Promise<any> {
    const { data } = await AxiosJwt.delete(`/questionnaire/${id}`);
    return data;
  }

  public static async getClinicMembers(
    clinicId: string
  ): Promise<IClinicMembers[]> {
    const { data } = await AxiosJwt.get(
      `/web-doctor/practitioners/${clinicId}`
    );
    return data;
  }
  public static async getTrainingSchedule(patientId: string) {
    const { data } = await AxiosJwt.get(
      `/training-schedules/patient/${patientId}`
    );

    return data;
  }

  public static async getTrainingTools(patientId: string) {
    const { data } = await AxiosJwt.get(`/training-tools/patient/${patientId}`);

    return data;
  }

  public static async getTaskByPatientId(patientId: string) {
    const { data } = await AxiosJwt.get(`/task/patient/${patientId}`);
    return data;
  }

  public static async updateTask(
    id: string,
    params: ITaskUpdate
  ): Promise<any> {
    const { data } = await AxiosJwt.patch(`/task/${id}`, params);
    return data;
  }

  @MemoPromise()
  public static async getCustomTableData(
    meId: string,
    organizationId: string,
    count: number,
    page: number,
    sort?: { field: string; order: 'asc' | 'desc' },
    searchArg?: string,
    taskFilter?: DashboardTableFilter
  ): Promise<{ items: ITableItem[]; total: number }> {
    let sortParams = '';
    let searchParam = '';
    if (sort) sortParams += `&sort=${sort.field}&order=${sort.order}`;
    if (!!searchArg) searchParam += `&filter=${searchArg}`;
    const { data } = await AxiosJwt.get(
      `/dashboard-web/mifysio-table-data/${organizationId}?count=${count}&page=${page}` +
        sortParams +
        searchParam
    );
    return data;
  }

  public static async getReadyMessagesById(
    practitionerId: string
  ): Promise<IScriptMessage[]> {
    const { data } = await AxiosJwt.get(
      `/script-message/publisher/${practitionerId}`
    );
    return data;
  }

  public static async createReadyMessage(
    body: IScriptMessage
  ): Promise<IScriptMessage> {
    const { data } = await AxiosJwt.post(`/script-message`, body);
    return data;
  }

  public static async deleteReadyMessageById(messageId: string): Promise<any> {
    const { data } = await AxiosJwt.delete(`/script-message/${messageId}`);
    return data;
  }

  public static async getQuestionnaireScoreGraph(
    body: GetQuestionnaireScoreQuery
  ) {
    try {
      const response = await AxiosJwt.post(
        `questionnaire-score/graphic-information`,
        body
      );
      return response.data;
    } catch (e) {
      console.log(e, 'ERRO');
    }
  }

  public static async getAcuteWorkloadGraph(owner: string, timespan: TimeSpan) {
    const response = await AxiosJwt.post(
      `acute-chronic-workload/graphic-information`,
      { owner, timespan }
    );
    return response.data;
  }

  public static async updateCondition(id, category) {
    const response = await AxiosJwt.patch(`condition/${id}`, { category });

    return response;
  }

  public static async getLatestQuestionnaireScore(id: string) {
    const { data } = await AxiosJwt.get(
      `questionnaire-score/latest-score/${id}`
    );
    return data;
  }

  public static async getLatestAcuteChronicWorkloadScore(id: string) {
    const { data } = await AxiosJwt.get(
      `acute-chronic-workload/latest-score/${id}`
    );
    return data;
  }

  public static async memberInviteToClinic(
    clinicId: string,
    members: string[]
  ): Promise<void> {
    await AxiosJwt.post(`/web-doctor/clinic-invitation`, {
      members: members,
      clinic_id: clinicId
    });
  }
}

/* eslint-disable jsx-a11y/alt-text */
import { Box, Typography } from "@mui/material";
import rightArrow from "../../../assets/icons/right-arrow.svg";
import downArrow from "../../../assets/icons/arrow-down.svg";
import React, { useState } from "react";
import { useDispatch } from "../../../store";
import { setRowHeight } from "../../../slices/log-history";

export interface ActionItem {
  icon: string;
  label: string;
  details: unknown;
}

export const ActionItem = (props: ActionItem) => {
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const dispatch = useDispatch();
  let parsed = {};
  try {
    parsed = JSON.parse(props.details as string);
  } catch {
    parsed = { no: "value" };
  }
  return (
    <Box
      onClick={() => {
        setExpanded(!isExpanded);
        !isExpanded ? dispatch(setRowHeight(400)) : dispatch(setRowHeight(80));
      }}
      style={{
        flex: 1,
        whiteSpace: "nowrap",
        flexDirection: "column",
        display: "flex",
      }}
    >
      <Box
        style={{
          flex: 1,
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <img src={props.icon} />
        <Typography variant="h6">
          {props.label.replace("undefined", "")}
        </Typography>
        <Box>
          {!isExpanded ? <img src={rightArrow} /> : <img src={downArrow} />}
        </Box>
      </Box>
      {isExpanded ? (
        <Box
          style={{
            marginTop: 50,
            marginLeft: 100,
            marginRight: 100,
          }}
        >
          {Object.entries(parsed).map(([key, val]) => {
            return (
              <Box
                style={{
                  flex: 1,
                  display: "flex",
                }}
              >
                <Typography variant={"h5"}>{key}:</Typography>
                <Typography>&nbsp; {JSON.stringify(val, null, 2)}</Typography>;
              </Box>
            );
          })}
        </Box>
      ) : (
        false
      )}
    </Box>
  );
};

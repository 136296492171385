/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable jsx-a11y/alt-text */
import { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, TextField } from '@mui/material';
import { Select, Text } from '../../PatientDetails/AddEdit/Forms/Fields';
import { useTranslation } from 'react-i18next';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import React from 'react';
import { useDispatch, useSelector } from 'src/store';
import { Field } from 'formik';
import { Box, Stack, Typography } from '@material-ui/core';
import {
  equipments,
  getCategories
} from 'src/models/exercise-plan/ExercisePlan';
import slice from 'src/slices/exercise';
import { formatFirstLetterUpperCase } from 'src/utils/Form';
interface ParametersTabProps {
  isDisabledText?: boolean;
  isEdit?: boolean;
  parameters: {
    sets: number | string;
    repetitions: number | string;
    rest: number | string;
    tempo?: {
      down: string;
      hold: string;
      up: string;
    };
    movementPattern: {
      first: string;
      second: string;
      third: string;
    };
    equipment: string;
    duration?: number | string;
  };
}

const ParametersTab: FC<ParametersTabProps> = ({
  isDisabledText,
  isEdit,
  parameters,
  ...props
}) => {
  const classes = useStyles();
  const { t }: { t: any } = useTranslation();
  const { movementPattern } = getCategories(t);
  const dispatch = useDispatch();
  const [selectedBase, setSelectedBase] = React.useState<string>();
  console.log(parameters, 'PARAMS');
  const { isIsometric, exerciseBase } = useSelector((state) => state.exercise);
  React.useEffect(() => {
    isIsometric
      ? (setSelectedBase('duration'),
        dispatch(slice.actions.setExerciseBase('duration')))
      : (isEdit || isDisabledText) &&
        (parameters.duration as string).length !== 0
      ? (setSelectedBase('duration'),
        dispatch(slice.actions.setExerciseBase('tempo')))
      : (setSelectedBase('tempo'),
        dispatch(slice.actions.setExerciseBase('tempo')));
  }, [isIsometric, dispatch]);

  if (!selectedBase) {
    return <></>;
  }

  return (
    <Grid container className={classes.gridContainer}>
      <Grid
        item
        md={4.5}
        sm={8}
        xs={12}
        mt={0}
        mx={4}
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <FormControl
          sx={{
            height: 53,
            marginBottom: 5
          }}
        >
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={
              isDisabledText && (parameters.duration as string).length === 0
                ? 'tempo'
                : isDisabledText && (parameters.duration as string).length !== 0
                ? 'duration'
                : selectedBase
            }
            defaultValue={selectedBase ?? isIsometric ? 'duration' : 'tempo'}
          >
            <FormControlLabel
              value="tempo"
              disabled={isIsometric || isDisabledText}
              control={
                <Radio
                  sx={{
                    color: '#DCDFEA'
                  }}
                />
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSelectedBase(event.target.value);
                dispatch(slice.actions.setExerciseBase('tempo'));
              }}
              label={t('Tempo Based')}
            />
            <FormControlLabel
              value="duration"
              disabled={isIsometric || isDisabledText}
              control={
                <Radio
                  sx={{
                    color: '#DCDFEA'
                  }}
                />
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSelectedBase(event.target.value);
                dispatch(slice.actions.setExerciseBase('duration'));
              }}
              label={t('Duration Based')}
            />
          </RadioGroup>
        </FormControl>
        <Text
          name="parameters.sets"
          isHalf
          label={t('Sets')}
          parametersValue
          isParamaters
          defaultValue={String(parameters.sets)}
          isDisabledText={isDisabledText}
          width={{
            xs: '100%',
            sm: '100%',
            md: '100%',
            lg: '100%',
            xl: '100%'
          }}
        />

        <Text
          name="parameters.rest"
          isHalf
          parametersValue
          isParamaters
          label={t('Rest (secs)')}
          defaultValue={String(parameters.rest)}
          isDisabledText={isDisabledText}
          width={{
            xs: '100%',
            sm: '100%',
            md: '100%',
            lg: '100%',
            xl: '100%'
          }}
        />
      </Grid>

      <Grid
        item
        md={4.5}
        sm={8}
        xs={12}
        mt={0}
        mx={4}
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {/* Equipment space */}
        <Stack width={'100%'} mt={5} height={53} />
        <Text
          name="parameters.repetitions"
          isHalf
          label={t('Repetitions')}
          parametersValue
          isParamaters
          defaultValue={String(parameters.repetitions)}
          isDisabledText={isDisabledText}
          width={{
            xs: '100%',
            sm: '100%',
            md: '100%',
            lg: '100%',
            xl: '100%'
          }}
        />
        {selectedBase === 'tempo' && (
          <Grid container direction="row" justifyContent="space-between">
            <Grid item xs="auto">
              <Field name={'parameters.tempo.down'}>
                {({ field, meta }) => {
                  console.log(
                    { isDisabledText, isEdit, value: field.value },
                    '{isDisabledText,isEdit,value:field.value}'
                  );
                  return (
                    <TextField
                      {...field}
                      disabled={isDisabledText}
                      type="number"
                      inputProps={{ min: '1', max: '5', step: '1' }}
                      InputLabelProps={{
                        shrink: true,
                        sx: {
                          color: isDisabledText
                            ? `#8B92AB !important`
                            : field.value && field.value === '0' && isEdit
                            ? `#5AD0A6 !important`
                            : field.value === '0' && !isEdit
                            ? `#8B92AB !important`
                            : !field.value && isEdit
                            ? `#8B92AB !important`
                            : `#5AD0A6 !important`
                        }
                      }}
                      sx={{
                        ...sx.increaseInput,
                        height: '100%',
                        '& fieldset': {
                          borderRadius: 1,
                          borderWidth: 1,
                          borderColor: isDisabledText
                            ? `#8B92AB !important`
                            : field.value && field.value === '0' && isEdit
                            ? `#5AD0A6 !important`
                            : field.value === '0' && !isEdit
                            ? `#8B92AB !important`
                            : !field.value && isEdit
                            ? `#8B92AB !important`
                            : `#5AD0A6 !important`
                        }

                        // ml: 3,
                      }}
                      label={t('Down')}
                    />
                  );
                }}
              </Field>
            </Grid>
            <Grid item xs="auto">
              <Field name={'parameters.tempo.hold'}>
                {({ field, meta }) => {
                  return (
                    <TextField
                      {...field}
                      type="number"
                      disabled={isDisabledText}
                      inputProps={{ min: '0', max: '5', step: '1' }}
                      InputLabelProps={{
                        shrink: true,
                        sx: {
                          color: isDisabledText
                            ? `#8B92AB !important`
                            : field.value && field.value === '0' && isEdit
                            ? `#5AD0A6 !important`
                            : field.value === '0' && !isEdit
                            ? `#8B92AB !important`
                            : !field.value && isEdit
                            ? `#8B92AB !important`
                            : `#5AD0A6 !important`
                        }
                      }}
                      sx={{
                        ...sx.increaseInput,
                        height: '100%',
                        '& fieldset': {
                          borderRadius: 1,
                          borderWidth: 1,
                          borderColor: isDisabledText
                            ? `#8B92AB !important`
                            : field.value && field.value === '0' && isEdit
                            ? `#5AD0A6 !important`
                            : field.value === '0' && !isEdit
                            ? `#8B92AB !important`
                            : !field.value && isEdit
                            ? `#8B92AB !important`
                            : `#5AD0A6 !important`
                        }

                        // ml: 3,
                      }}
                      label={t('Hold')}
                    />
                  );
                }}
              </Field>
            </Grid>

            <Grid item xs="auto">
              <Field name={'parameters.tempo.up'}>
                {({ field, meta }) => {
                  return (
                    <TextField
                      {...field}
                      type="number"
                      disabled={isDisabledText}
                      inputProps={{ min: '1', max: '5', step: '1' }}
                      InputLabelProps={{
                        shrink: true,
                        sx: {
                          color: isDisabledText
                            ? `#8B92AB !important`
                            : field.value && field.value === '0' && isEdit
                            ? `#5AD0A6 !important`
                            : field.value === '0' && !isEdit
                            ? `#8B92AB !important`
                            : !field.value && isEdit
                            ? `#8B92AB !important`
                            : `#5AD0A6 !important`
                        }
                      }}
                      sx={{
                        ...sx.increaseInput,
                        height: '100%',
                        '& fieldset': {
                          borderRadius: 1,
                          borderWidth: 1,
                          borderColor: isDisabledText
                            ? `#8B92AB !important`
                            : field.value && field.value === '0' && isEdit
                            ? `#5AD0A6 !important`
                            : field.value === '0' && !isEdit
                            ? `#8B92AB !important`
                            : !field.value && isEdit
                            ? `#8B92AB !important`
                            : `#5AD0A6 !important`
                        }

                        // ml: 3,
                      }}
                      label={t('Up')}
                    />
                  );
                }}
              </Field>
            </Grid>
          </Grid>
        )}
        {selectedBase === 'duration' && (
          <Text
            name="parameters.duration"
            label={t('Duration (secs)')}
            parametersValue
            isHalf
            isParamaters
            defaultValue={String(parameters.duration)}
            isDisabledText={isDisabledText}
            width={{
              xs: '100%',
              sm: '100%',
              md: '100%',
              lg: '100%',
              xl: '100%'
            }}
          />
        )}
      </Grid>
      {selectedBase !== 'duration' && (
        <Stack mx={4} my={-4}>
          <Typography
            my={2}
            fontFamily="Poppins"
            fontSize={14}
            fontWeight="500"
            color={'#8B92AB'}
          >
            {t('Movement Pattern')}
          </Typography>
          <Stack
            display="flex"
            flexDirection={{
              xs: 'column',
              sm: 'column',
              md: 'row',
              lg: 'row',
              xl: 'row'
            }}
            alignItems="center"
          >
            <Box mr={2}>
              {isDisabledText ? (
                <Text
                  name="parameters.movementPattern.first"
                  isHalf
                  label={`${t('Pattern')} 1`}
                  parametersValue
                  isParamaters
                  defaultValue={String(
                    formatFirstLetterUpperCase(parameters.movementPattern.first)
                  )}
                  isDisabledText={isDisabledText}
                  width={{
                    xs: '100%',
                    sm: '100%',
                    md: '100%',
                    lg: '100%',
                    xl: '100%'
                  }}
                />
              ) : (
                <Select
                  name="parameters.movementPattern.first"
                  label={`${t('Pattern')} 1`}
                  options={movementPattern}
                  required
                  width={215}
                />
              )}
            </Box>
            <Box mx={2}>
              {isDisabledText ? (
                <Text
                  name="parameters.movementPattern.second"
                  isHalf
                  label={`${t('Pattern')} 2`}
                  parametersValue
                  isParamaters
                  defaultValue={String(
                    formatFirstLetterUpperCase(
                      parameters.movementPattern.second
                    )
                  )}
                  isDisabledText={isDisabledText}
                  width={{
                    xs: '100%',
                    sm: '100%',
                    md: '100%',
                    lg: '100%',
                    xl: '100%'
                  }}
                />
              ) : (
                <Select
                  name="parameters.movementPattern.second"
                  label={`${t('Pattern')} 2`}
                  options={movementPattern}
                  required
                  width={215}
                />
              )}
            </Box>
            <Box ml={2}>
              {isDisabledText ? (
                <Text
                  name="parameters.movementPattern.third"
                  isHalf
                  label={`${t('Pattern')} 3`}
                  parametersValue
                  isParamaters
                  defaultValue={String(
                    formatFirstLetterUpperCase(parameters.movementPattern.third)
                  )}
                  isDisabledText={isDisabledText}
                  width={{
                    xs: '100%',
                    sm: '100%',
                    md: '100%',
                    lg: '100%',
                    xl: '100%'
                  }}
                />
              ) : (
                <Select
                  name="parameters.movementPattern.third"
                  label={`${t('Pattern')} 3`}
                  options={movementPattern}
                  required
                  width={215}
                />
              )}
            </Box>
          </Stack>
        </Stack>
      )}
    </Grid>
  );
};

export default ParametersTab;

const useStyles = makeStyles(() => ({
  gridContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
    padding: 0
  }
}));

const sx = {
  increaseInput: {
    '& .MuiOutlinedInput-root': {
      width: {
        xs: '100%',
        sm: '100%',
        md: '100%',
        lg: '100%',
        xl: '100%'
      },

      '&.Mui-focused fieldset': {
        borderWidth: 1,
        borderColor: `#5AD0A6 !important`
      },
      '& .MuiFormHelperText-root': {
        color: '#5AD0A6',
        fontSize: 12,
        fontWeight: 700,
        marginBottom: 2
      },
      '& .MuiFormLabel-root': {
        color: '#5AD0A6'
      }
    }
  }
};

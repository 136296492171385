/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { ILogRow } from './log-utils';
import { useDispatch, useSelector } from '../../../store';
import { fetchLogs } from '../../../slices/log-history';
import {
  Autocomplete,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { ActionItem } from './action-item';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import { DatePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import SuspenseLoader from '../../../components/SuspenseLoader';

const typeAutocompleteOptions = [
  'CommunicationRequest',
  'LimitValues',
  'Patient',
  'Observation',
  'All'
];

export const LogHistory = () => {
  return (
    <div
      className="p-8"
      style={{ flex: 1, display: 'flex', flexDirection: 'column', margin: 50 }}
    >
      <h1 className="text-2xl font-bold">Logs</h1>
      <CollapsibleTable />
    </div>
  );
};

export default function CollapsibleTable() {
  const { formattedLogs, total, isLoading } = useSelector(
    (state) => state.logHistory
  );
  const [page, setPage] = React.useState(0);
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = React.useState(15);
  const [typeAutocomplete, setTypeAutocomplete] = React.useState<string>('All');
  const [sortAutocomplete, setSortAutocomplete] =
    React.useState<string>('Latest first');
  const date = new Date();
  const [startDate, setStartDate] = React.useState<Date | null>(
    new Date(date.getFullYear(), 0)
  );
  const [endDate, setEndDate] = React.useState<Date | null>(
    new Date(date.getFullYear(), 11)
  );
  useEffect(() => {
    // const userId = tableData.map((x) => x?.patient).join(',');
    dispatch(
      fetchLogs(
        'userId',
        page,
        pageSize,
        typeAutocomplete,
        sortAutocomplete,
        startDate,
        endDate
      )
    );
  }, [page, pageSize, typeAutocomplete, sortAutocomplete, startDate, endDate]);
  if (isLoading) {
    return <SuspenseLoader />;
  }
  return (
    <Paper sx={{ width: '100%' }}>
      <Box
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'center',
          margin: 20
        }}
      >
        <Box style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
          <Autocomplete
            onChange={(_ev, _val) =>
              setTypeAutocomplete(_val as unknown as string)
            }
            value={typeAutocomplete}
            disablePortal
            options={typeAutocompleteOptions}
            sx={{ width: 200 }}
            renderInput={(params) => <TextField {...params} />}
          />
          <Autocomplete
            onChange={(_ev, _val) =>
              setSortAutocomplete(_val as unknown as string)
            }
            value={sortAutocomplete}
            disablePortal
            options={['Latest first', 'Oldest first']}
            sx={{ width: 200 }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
        <Box>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Start date"
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue);
              }}
              renderInput={(params) => {
                // @ts-ignore
                return <TextField {...params} />;
              }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="End date"
              value={endDate}
              onChange={(newValue) => {
                setEndDate(newValue);
              }}
              renderInput={(params) => {
                // @ts-ignore
                return <TextField {...params} />;
              }}
            />
          </LocalizationProvider>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell align="right">TIME</TableCell>
              <TableCell align="right">AUTHOR</TableCell>
              <TableCell align="center">ACTION</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {formattedLogs.map((row) => (
              <Row key={row.id} row={row} />
            ))}
          </TableBody>
          <Box
            style={{
              flex: 1,
              display: 'block',
              justifyContent: 'flex-end',
              alignItems: 'flex-end'
            }}
          >
            <TablePagination
              rowsPerPageOptions={[25, 100]}
              count={total}
              rowsPerPage={pageSize}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page'
                },
                native: true
              }}
              onPageChange={(_ev, page) => setPage(page)}
              onRowsPerPageChange={(_ev) => {
                setPageSize(parseInt(_ev.target.value, 10));
                setPage(0);
              }}
              ActionsComponent={TablePaginationActions}
            />
          </Box>
        </Table>
      </TableContainer>
    </Paper>
  );
}

function Row(props: { row: ILogRow }) {
  const { row } = props;

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component="th" scope="row">
          {row.date}
        </TableCell>
        <TableCell align="right">{row.time}</TableCell>
        <TableCell align="right">
          <Box>
            <Typography variant="h6">{row.author.name}</Typography>
            <Typography variant="h6">{row.author.role}</Typography>
          </Box>
        </TableCell>
        <TableCell align="right">
          <ActionItem
            icon={row.action.icon}
            label={row.action.label}
            details={row.action.details}
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

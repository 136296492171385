import { MiddlewareService } from 'src/services/middleware/MiddlewareService';

import { IScriptMessage } from './../models/ready-message/index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, AppThunk } from '../store';
import { Doctor, IClinic } from 'src/models/user/IPractitionerInfoBody';
import { IClinicMembers } from 'src/models/clinic/IClinicMembers';
import {
  ClinicService,
  ConditionService,
  Patient,
  PatientService,
  Questionnaire,
  Task
} from '@actimi/core-migration';
import getActimiCoreLazy from 'src/utils/actimi-core';
import { CodeableConcept, IClinicMember } from '@actimi/core-migration';

export interface ClinicState {
  authPractitionerInfo: Doctor | undefined;
  practitionerInfo: Doctor | undefined;
  clinicInfo: IClinic | undefined;
  clinicMembers: IClinicMembers[];
  readyMessage: IScriptMessage[];
  totalOfTable: number;
  questionnaires: Questionnaire[];
  organizationPatients: Patient[];
  practitionerTasks: Task[];
}

const initialState: ClinicState = {
  readyMessage: undefined,
  totalOfTable: undefined,
  authPractitionerInfo: undefined,
  questionnaires: undefined,
  practitionerInfo: undefined,
  clinicInfo: undefined,
  organizationPatients: undefined,
  clinicMembers: [],
  practitionerTasks: undefined
};

const clinicService = new ClinicService();

const slice = createSlice({
  name: 'clinic',
  initialState,
  reducers: {
    getAuthPractitionerInfo(
      state: ClinicState,
      action: PayloadAction<{ authPractitionerInfo: Doctor }>
    ) {
      const { authPractitionerInfo } = action.payload;
      state.authPractitionerInfo = authPractitionerInfo;
    },
    getPractitionerInfo(
      state: ClinicState,
      action: PayloadAction<{ practitionerInfo: Doctor }>
    ) {
      const { practitionerInfo } = action.payload;
      state.practitionerInfo = practitionerInfo;
    },

    setQuestionnaires(
      state,
      action: PayloadAction<{ questionnaires: Questionnaire[] }>
    ) {
      state.questionnaires = action.payload.questionnaires;
    },

    resetSelectedQuestionnaires(state) {
      Object.assign(state.questionnaires, initialState.questionnaires);
    },

    updateClinicMember(
      state,
      action: PayloadAction<{
        clinicMember: IClinicMember;
      }>
    ) {
      const { clinicMember } = action.payload;
      const maybeOldClinicMember = state.clinicMembers.find(
        //@ts-ignore
        ({ practitioner }) => practitioner.id === clinicMember.practitioner.id
      );
      if (maybeOldClinicMember) {
        Object.assign(maybeOldClinicMember, clinicMember);
      } else {
        //@ts-ignore
        state.clinicMembers.push(clinicMember);
      }
    },
    getClinicInfo(
      state: ClinicState,
      action: PayloadAction<{ clinicInfo: IClinic }>
    ) {
      const { clinicInfo } = action.payload;
      state.clinicInfo = clinicInfo;
    },
    setTableTotal(state, action: PayloadAction<number>) {
      state.totalOfTable = action.payload;
    },
    setClinicMembers(
      state,
      action: PayloadAction<{
        clinicMembers: IClinicMembers[];
      }>
    ) {
      state.clinicMembers = action.payload.clinicMembers;
    },
    removeClinicMember(
      state,
      action: PayloadAction<{
        userId: string;
      }>
    ) {
      const index = state.clinicMembers.findIndex(
        //@ts-ignore
        (member) => member?.role?.user?.id === action.payload.userId
      );
      if (index !== -1) {
        state.clinicMembers.splice(index, 1);
      }
    },
    setPatients(state, action: PayloadAction<Patient[]>) {
      state.organizationPatients = action.payload;
    },
    setReadyMessage(state, action: PayloadAction<IScriptMessage[]>) {
      state.readyMessage = action.payload;
    },
    setPractitionerTasks(state, action: PayloadAction<Task[]>) {
      state.practitionerTasks = action.payload;
    },
    resetPractitonerTasks(state: ClinicState) {
      Object.assign(state.practitionerTasks, initialState.practitionerTasks);
    },
    reset(state: ClinicState) {
      Object.assign(state, initialState);
    }
  }
});

//Reducers

export const reducer = slice.reducer;

export const reset = (): AppThunk => async (dispatch) =>
  dispatch(slice.actions.reset());

export const getAuthPractitionerInfo =
  (authUserId: string, clinicId: string, whenDone?: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { PractitionerService } = await getActimiCoreLazy();
    const { getPractitionerInfo } = new PractitionerService();
    const resp = await getPractitionerInfo(authUserId, clinicId);

    dispatch(
      slice.actions.getAuthPractitionerInfo({
        authPractitionerInfo: resp as Doctor
      })
    );
    whenDone?.();
  };

export const getPractitionerInfo =
  (userId: string, organizationId: string, whenDone?: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { PractitionerService } = await getActimiCoreLazy();
    const { getPractitionerInfo } = new PractitionerService();

    const resp = await getPractitionerInfo(userId, organizationId);
    //@ts-ignore
    dispatch(slice.actions.getPractitionerInfo({ practitionerInfo: resp }));

    dispatch(
      slice.actions.getAuthPractitionerInfo({
        authPractitionerInfo: resp as Doctor
      })
    );
    whenDone?.();
  };

export const getClinicInfo =
  (clinicId: string, whenDone?: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    const resp = await clinicService.getClinicInfo(clinicId);
    dispatch(slice.actions.getClinicInfo({ clinicInfo: resp as IClinic }));
    whenDone?.();
  };

export const updateClinicMember =
  (clinicMember: IClinicMember): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updateClinicMember({ clinicMember }));
  };

export const deletePractitionerAccount =
  (
    data: { userId: string; organizationId: string },
    callbacks: Partial<Record<'onSuccess' | 'onError', Function>> = {}
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { PractitionerService } = await getActimiCoreLazy();
    const { deletePractitioner: deletePractitionerAPI } =
      new PractitionerService();

    await deletePractitionerAPI(data)
      .then(() => {
        callbacks.onSuccess?.();
        dispatch(slice.actions.removeClinicMember({ userId: data.userId }));
      })
      .catch(() => callbacks.onError?.());
  };

// export const getPractitionerTaskRelatedToPatient =
//   (
//     practitionerId: string,
//     patientId: string,
//     organizationId: string,
//     whenDone?: () => void
//   ): AppThunk =>
//   async (dispatch) => {
//     const { TaskService } = await getActimiCoreLazy();
//     const { getPractitionerActiveTasksRelatedPatient } = new TaskService();
//     console.log({ practitionerId, patientId, organizationId }, "id's");
//     const data = await getPractitionerActiveTasksRelatedPatient(
//       practitionerId,
//       patientId,
//       organizationId
//     );
//     dispatch(slice.actions.setPractitionerTasks(data));
//     whenDone?.();
//   };

export const getClinicMembers =
  (clinicId: string): AppThunk =>
  async (dispatch) => {
    const resp = await clinicService.getClinicMembers(clinicId);
    dispatch(
      slice.actions.setClinicMembers({
        clinicMembers: resp as unknown as IClinicMembers[]
      })
    );
  };

export const inviteMembersToClinic =
  (membersArr: string[], clinicId: string): AppThunk =>
  async () => {
    await MiddlewareService.memberInviteToClinic(clinicId, membersArr);
  };

export const getReadyMessagesById =
  (practitionerId: string, whenDone?: () => void): AppThunk =>
  async (dispatch) => {
    const data = await MiddlewareService.getReadyMessagesById(practitionerId);
    dispatch(slice.actions.setReadyMessage(data));
    whenDone?.();
  };

export const createReadyMessage =
  (body: IScriptMessage, whenDone?: () => void): AppThunk =>
  async () => {
    await MiddlewareService.createReadyMessage(body);
    whenDone?.();
  };

export const deleteReadyMessage =
  (messageId: string, whenDone?: () => void): AppThunk =>
  async () => {
    await MiddlewareService.deleteReadyMessageById(messageId);
    whenDone?.();
  };

export const updateCondition =
  (
    conditionId: string,
    organizationId: string,
    body: CodeableConcept[],
    whenDone?: () => void
  ): AppThunk =>
  async () => {
    const { updateConditionCategory } = new ConditionService();
    await updateConditionCategory(conditionId, organizationId, body);
    whenDone?.();
  };

export const getPatientByOrganizationId =
  (organizationId: string, whenDone?: () => void): AppThunk =>
  async (dispatch) => {
    const { getPatientByOrganizationId } = new PatientService();
    const patients = await getPatientByOrganizationId(organizationId);
    dispatch(slice.actions.setPatients(patients));
    whenDone?.();
  };

export default slice;

import { ILastMessage, IMessage, MessageStatus } from '@actimi/core-migration';
import { AidboxReference } from '../services/middleware/types/aidbox/AidboxTypes';
import { socket } from './socket';
export interface IChatMessage extends AidboxReference<IChatMessage> {
  readonly resourceType: 'ChatMessage';
  readonly id: string;
  readonly members: string[];
  readonly lastActive: { dateTime: string; lastMessage: string };
  readonly lastMessage: ILastMessage;
  readonly onlineStatus: Array<{
    isOnline: boolean;
    lastActiveAt: string;
    userId: string;
  }>;
}

export interface IChatWSHandler {
  setActiveStatus: (userId: string, isActive: boolean) => void;
  listenStatusChanged: (
    selectedUserId: string,
    cb: (isActive: boolean, userId: string) => void
  ) => void;
  setIsTyping: (to: string, _isTyping: boolean, chatId: string) => void;
  listenIsTyping: (
    to: string,
    cb: ({ chatId, isTyping }: { chatId: string; isTyping: boolean }) => void
  ) => void;
  startListening: (
    userId: string,
    cb: (messages: IMessage[] | IMessage, chatId: string) => void
  ) => void;
  sendMessage: (
    chatId: string,
    id: string,
    to: string,
    from: string,
    text: string,
    status: string,
    previousMessageId: string
  ) => void;
  getMessages: (
    to: string,
    from: string,
    chatId: string,
    userId: string
  ) => void;
  updateMessage: (
    chatId: string,
    messageId: string,
    status: MessageStatus
  ) => void;
}

const listenStatusChanged = (
  selectedUserId: string,
  cb: (isActive: boolean, userId: string) => void
) => {
  if (!socket.hasListeners(`active-status-changed-${selectedUserId}`)) {
    socket.on(
      `active-status-changed-${selectedUserId}`,
      (ev: { isActive: boolean }) => cb(ev.isActive, selectedUserId)
    );
  }
};

const setActiveStatus = async (userId: string, isActive: boolean) => {
  socket.emit('set-active', { userId, isActive });
};

const listenIsTyping = async (
  to: string,
  cb: ({ chatId, isTyping }: { chatId: string; isTyping: boolean }) => void
) => {
  if (!socket.hasListeners(`is-typing-${to}`)) {
    socket.on(
      `is-typing-${to}`,
      ({ chatId, isTyping }: { chatId: string; isTyping: boolean }) => {
        cb({ chatId, isTyping });
      }
    );
  }
};

const startListening = async (
  userId: string,
  cb: (messages: IMessage[] | IMessage, chatId: string) => void
): Promise<void> => {
  const evId = `message-received-${userId}`;
  if (!socket.hasListeners(evId)) {
    await socket.connect();
    socket.on(
      evId,
      (events: { message: IMessage | undefined; chatId: string }) => {
        cb(events.message, events.chatId);
      }
    );
  }
};

const setIsTyping = (to: string, _isTyping: boolean, chatId: string) => {
  socket.emit('is-typing', { to, isTyping: _isTyping, chatId });
};

const updateMessage = (
  chatId: string,
  messageId: string,
  status: 'SENT' | 'SEEN' | 'ERROR' | 'UNKNOWN'
) => {
  socket.emit('update-messages-status', {
    chatId,
    messageId,
    status
  });
};

const getMessages = async (
  to: string,
  from: string,
  chatId: string,
  userId: string
) => {
  socket.emit('get-messages', {
    from,
    to,
    chatId,
    userId
  });
};

const sendMessage = (
  chatId: string,
  id: string,
  to: string,
  from: string,
  text: string,
  status: string,
  previousMessageId: string
) => {
  socket.emit('send-message', {
    chatId,
    id,
    to,
    from,
    text,
    status,
    previousMessageId
  });
};

export default {
  listenStatusChanged,
  setActiveStatus,
  setIsTyping,
  startListening,
  sendMessage,
  listenIsTyping,
  getMessages,
  updateMessage
} as IChatWSHandler;

/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable jsx-a11y/alt-text */
import { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Grid, TextField, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AddCircle } from 'iconsax-react';
import React from 'react';
import { useDispatch, useSelector } from 'src/store';
import slice from 'src/slices/exercise';
import _ from 'lodash';
import { QuestionnaireItem } from '@actimi/core-migration';
interface InstructionsTabProps {
  isDisabledText?: boolean;
  isEdit?: boolean;
  questionnaireData: QuestionnaireItem;
}

const InstructionsTab: FC<InstructionsTabProps> = ({
  isDisabledText,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const listOfInputs = useSelector(
    (state) => state.exercise.instructionsInputs
  );
  const dispatch = useDispatch();
  const [index, setIndex] = React.useState(0);

  const handleServiceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const list = [...listOfInputs];
    const cloneDeepList = _.cloneDeep(list);
    cloneDeepList[index][name] = _.cloneDeep(value);
    dispatch(slice.actions.setInputsOfInstructions(cloneDeepList));
  };

  React.useEffect(() => {
    const x = [];
    if (props.isEdit) {
      props?.questionnaireData?.extension
        ?.flatMap((item) => {
          if (item.url === 'http://extension.actimi.com/exercise-assistant') {
            return item.extension.map((value) => {
              if (
                value.url ===
                'http://extension.actimi.com/exercise-assistant-instructions'
              ) {
                return value.extension[0].valueCodeableConcept.coding.flatMap(
                  (a, index) => {
                    x.push({ service: a.display });
                  }
                );
              }
            });
          }
        })
        .filter((x) => x !== undefined);
      dispatch(slice.actions.setInputsOfInstructions(x));
    }
  }, []);

  const handleServiceRemove = (index) => {
    const list = [...listOfInputs];
    list.splice(index, 1);
    dispatch(slice.actions.setInputsOfInstructions(list));
  };

  const handleServiceAdd = () => {
    dispatch(
      slice.actions.setInputsOfInstructions([...listOfInputs, { service: '' }])
    );
  };

  return (
    <Grid container mx={2} className={classes.gridContainer}>
      <Grid item sx={{ width: '100%' }} mx={2}>
        {!isDisabledText || props.isEdit
          ? listOfInputs.map((singleService, index) => {
              return (
                <Box my={3} key={index}>
                  <TextField
                    name="service"
                    id="outlined-basic"
                    className={classes.inputContainer}
                    label={t('Step') + ' ' + (index + 1)}
                    variant="outlined"
                    sx={{
                      width: {
                        xs: '75vw',
                        sm: '80vw',
                        md: '70vw',
                        lg: '60vw',
                        xl: '30vw'
                      }
                    }}
                    value={singleService.service}
                    onFocus={() => setIndex(index)}
                    onChange={handleServiceChange}
                  />
                </Box>
              );
            })
          : props?.questionnaireData?.extension
              ?.flatMap((item) => {
                if (
                  item.url === 'http://extension.actimi.com/exercise-assistant'
                ) {
                  return item.extension.map((value) => {
                    if (
                      value.url ===
                      'http://extension.actimi.com/exercise-assistant-instructions'
                    ) {
                      return value.extension[0].valueCodeableConcept.coding.flatMap(
                        (a, index) => {
                          return (
                            <Box my={3} key={index}>
                              <TextField
                                disabled
                                name="service"
                                id="outlined-basic"
                                onChange={handleServiceChange}
                                className={classes.inputContainer}
                                label={t('Step') + ' ' + (index + 1)}
                                variant="outlined"
                                sx={{
                                  width: {
                                    xs: '75vw',
                                    sm: '80vw',
                                    md: '70vw',
                                    lg: '60vw',
                                    xl: '30vw'
                                  }
                                }}
                                value={`${a.display}${'\n'}`}
                              />
                            </Box>
                          );
                        }
                      );
                    }
                  });
                }
              })
              .filter((x) => x !== undefined)}
        {!isDisabledText && (
          <Box>
            <Button
              startIcon={<AddCircle size="20" color="#FFFF" />}
              onClick={handleServiceAdd}
              sx={{
                width: 175,
                color: '#ffffff',
                backgroundColor: 'primary'
              }}
              color="primary"
              variant="contained"
            >
              {t('Add new step')}
            </Button>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default InstructionsTab;

const useStyles = makeStyles(() => ({
  gridContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
    padding: 0
  },
  inputContainer: {
    maxWidth: '100%',
    width: '100%'
  }
}));

import { useRef, useState } from 'react';

import {
  Box,
  Button,
  Divider,
  Popover,
  Typography,
  styled,
  Avatar
} from '@material-ui/core';
import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@material-ui/icons/LockOpenTwoTone';
import { useNavigate } from 'react-router';
import useAuth from 'src/hooks/useAuth';
import { MiddlewareService } from '../../../../services/middleware/MiddlewareService';
import { useSelector } from 'src/store';
import { useTranslation } from 'react-i18next';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: #232F48;
        display: block;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        
`
);

function HeaderUserbox() {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { practitionerInfo } = useSelector((state) => state.clinic);
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        {practitionerInfo?.avatar ? (
          <img
            crossOrigin="anonymous"
            alt={practitionerInfo?.name?.given}
            src={practitionerInfo?.avatar}
            style={{
              height: 50,
              width: 50,
              borderRadius: 25,
              objectFit: 'cover'
            }}
          />
        ) : (
          <Avatar
            variant="circular"
            alt={practitionerInfo?.name?.given}
            src={practitionerInfo?.avatar}
          />
        )}

        <Box
          component="span"
          sx={{
            display: { xs: 'none', md: 'inline-block' }
          }}
        >
          <UserBoxText>
            <UserBoxLabel variant="body1">
              {`${practitionerInfo?.name?.given ?? ''} ${
                practitionerInfo?.name?.family ?? ''
              }`}
            </UserBoxLabel>
          </UserBoxText>
        </Box>
        <Box
          component="span"
          sx={{
            display: { xs: 'none', sm: 'inline-block' }
          }}
        >
          <ExpandMoreTwoToneIcon
            sx={{
              ml: 1,
              mt: 1
            }}
          />
        </Box>
      </UserBoxButton>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          {practitionerInfo?.avatar ? (
            <img
              crossOrigin="anonymous"
              alt={practitionerInfo?.name?.given}
              src={practitionerInfo?.original_avatar}
              style={{
                height: 50,
                width: 50,
                borderRadius: 25,
                objectFit: 'cover'
              }}
            />
          ) : (
            <Avatar variant="circular" alt={practitionerInfo?.name?.given} />
          )}

          <UserBoxText>
            <UserBoxLabel variant="body1">
              {`${practitionerInfo?.name?.given} ${practitionerInfo?.name?.family}`
                .replace('null', '')
                .replace('undefined', '')}
            </UserBoxLabel>
          </UserBoxText>
        </MenuUserBox>
        <Divider
          sx={{
            mb: 0
          }}
        />
        {/* <List
          sx={{
            p: 1
          }}
          component="nav"
        >
          <ListItem
            onClick={() => {
              handleClose();
            }}
            button
          >
            <AccountBoxTwoToneIcon fontSize="small" />
            <ListItemText primary={t('Profile')} />
          </ListItem>
          <ListItem
            onClick={() => {
              handleClose();
            }}
            button
          >
            <InboxTwoToneIcon fontSize="small" />
            <ListItemText primary={t('Inbox')} />
          </ListItem>
          <ListItem
            onClick={() => {
              handleClose();
            }}
            button
          >
            <AccountTreeTwoToneIcon fontSize="small" />
            <ListItemText primary={t('Projects')} />
          </ListItem>
        </List> */}
        <Divider />
        <Box m={1}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon
              sx={{
                mr: 1
              }}
            />
            {t('Sign out')}
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;

import { AuditEvent } from "../../../services/middleware/types/aidbox/AidboxTypes";
import warning from "../../../assets/icons/warning.svg";
import limitValues from "../../../assets/icons/limit-values.svg";
import patient from "../../../assets/icons/patient.svg";
import pill from "../../../assets/icons/pill.svg";
import list from "../../../assets/icons/list.svg";

export interface FormatAction {
  readonly icon: string;
  readonly label: string;
  readonly details: unknown;
}

export interface ILogRow {
  readonly id: string;
  readonly date: string;
  readonly time: string;
  readonly author: {
    name: string;
    role: string;
  };
  readonly action: FormatAction;
  readonly status: string;
}

const getCommunicationRequest = (
  auditEventEntity: AuditEvent
): FormatAction => {
  const getLabel = () => {
    if (auditEventEntity.entity[0].detail[0]?.value?.string !== undefined) {
      const parsed = JSON.parse(
        auditEventEntity.entity[0].detail[0]?.value?.string
      );
      return parsed.payload.contentString?.replace(
        "Value measured for (,,)",
        ""
      );
    }
    return "";
  };

  if (auditEventEntity.subtype[0].code === "create") {
    return {
      icon: warning,
      label: getLabel(),
      details: auditEventEntity.entity[0].detail[0]?.value?.string,
    };
  }
};

const getLimitValues = (auditEventEntity: AuditEvent): FormatAction => {
  const getLabel = () => {
    return "Limit values were edited";
  };

  if (auditEventEntity.subtype[0].code === "patch") {
    return {
      icon: limitValues,
      label: getLabel(),
      details: auditEventEntity.entity[0].detail[0]?.value?.string,
    };
  }
};

const getPatient = (auditEventEntity: AuditEvent): FormatAction => {
  const getLabel = () => {
    return auditEventEntity.entity[0].description;
  };
  return {
    icon: patient,
    label: getLabel(),
    details: auditEventEntity.entity[0].detail[0]?.value?.string,
  };
};

const getMedicationRequest = (auditEventEntity: AuditEvent): FormatAction => {
  const getLabel = () => {
    return auditEventEntity.entity[0].description;
  };
  return {
    icon: pill,
    label: getLabel(),
    details: auditEventEntity.entity[0].detail[0]?.value?.string,
  };
};

const getObservationRequest = (auditEventEntity: AuditEvent): FormatAction => {
  const getLabel = () => {
    return auditEventEntity.entity[0].description;
  };
  return {
    icon: list,
    label: getLabel(),
    details: auditEventEntity.entity[0].detail[0]?.value?.string,
  };
};

export const formatAction = (
  auditEvent: AuditEvent
): FormatAction | undefined => {
  switch (auditEvent.entity[0].name) {
    case "CommunicationRequest":
      return getCommunicationRequest(auditEvent);
    case "LimitValues":
      return getLimitValues(auditEvent);
    case "Patient":
      return getPatient(auditEvent);
    case "MedicationRequest":
      return getMedicationRequest(auditEvent);
    case "Observation":
      return getObservationRequest(auditEvent);
    default:
      return undefined;
  }
};

export const formatLogs = (logs: AuditEvent[]) => {
  return logs.map((log: AuditEvent) => {
    const action = formatAction(log);
    if (action === undefined) {
      return undefined;
    }
    return {
      id: log.id,
      time: new Date(log.recorded).toLocaleString("en-US", {
        hour: "numeric",
        hour12: true,
      }),
      date: new Date(log.recorded).toLocaleDateString(undefined, {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      }),
      author: {
        name: log.agent[0].name,
        role: log.agent[0].who.resourceType,
      },
      action: {
        icon: action.icon,
        label: action.label,
        details: action.details,
      },
      status: log.outcomeDesc,
    };
  });
};

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable jsx-a11y/alt-text */
import { FC, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Grid, Button } from '@mui/material';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Select, Text } from '../../PatientDetails/AddEdit/Forms/Fields';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useDispatch, useSelector } from 'src/store';
import slice from 'src/slices/exercise';
import { Field } from 'formik';
import React from 'react';
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
import VideoSnapshot from 'video-snapshot';
import { useTranslation } from 'react-i18next';
import { convertBase64 } from '../../Settings/components/modals/PractitionerSettingModal';
import _ from 'lodash';
import { Stack, useMediaQuery } from '@material-ui/core';
import {
  equipments,
  getCategories
} from 'src/models/exercise-plan/ExercisePlan';
import { height } from '@mui/system';

interface ExerciseOverviewTabProps {
  isDisabledText?: boolean;
  isEdit?: boolean;
}

const ExerciseOverviewTab: FC<ExerciseOverviewTabProps> = ({
  isDisabledText
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t }: { t: any } = useTranslation();

  const videoURI = useSelector((state) => state.exercise.videoURI);
  const selectedVideo = useSelector((state) => state.exercise.videoFile);

  const [isUploaded, setIsUploaded] = useState<boolean>(false);
  const [value, setValue] = useState('Public');

  const inputRef = React.useRef(null);

  const { bodyPart, movementCategory, level } = getCategories(t);

  const videoRef = useRef(null);
  useEffect(() => {
    videoRef?.current?.load();
  }, [videoURI]);

  return (
    <Grid
      container
      m={1}
      my={-1}
      ml={8}
      columnSpacing={videoURI || selectedVideo ? 4 : 3}
      className={classes.gridContainer}
    >
      {videoURI || selectedVideo ? (
        <Grid
          item
          md={5}
          sm={5.5}
          ml={2}
          mb={1}
          p={1}
          xs={10}
          sx={{
            ml: { xs: 6, sm: 0 },
            mb: { xs: 4 }
          }}
          className={classes.videoGrid}
        >
          <video
            crossOrigin="anonymous"
            className={classes.reactPlayer}
            ref={videoRef}
            controls
            width={'100%'}
          >
            <source src={videoURI ?? URL.createObjectURL(selectedVideo)} />
            {t('Your browser does not support the video tag.')}
          </video>
        </Grid>
      ) : (
        <Grid
          item
          md={5}
          sm={5.5}
          ml={2}
          mb={1}
          p={1}
          xs={10}
          sx={{
            ml: { xs: 6, sm: 0 },
            mb: { xs: 4 }
          }}
          className={classes.itemsGrid}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {isUploaded ? (
              <CheckCircleIcon
                sx={{
                  width: '100%',
                  height: '100%',
                  color: '#5AD0A6'
                }}
              />
            ) : (
              <VideoCallIcon
                sx={{
                  width: '75%',
                  height: '75%',
                  color: '#9AA2BB'
                }}
              />
            )}

            <Button
              variant="text"
              component="label"
              color="primary"
              sx={{ bottom: 12 }}
            >
              {isUploaded ? t('Change Video') : t('Upload Video')}
              <input
                ref={inputRef}
                onChange={async (e) => {
                  console.log(e?.target?.files?.item(0).type, 'file');
                  dispatch(
                    slice.actions.setVideoFile(e?.target?.files?.item(0))
                  );

                  const snapshoter = new VideoSnapshot(e.target.files[0]);
                  const previewSrc = await snapshoter.takeSnapshot();
                  const convertedPreview = _.cloneDeep(previewSrc).replace(
                    'data:image/png;base64,',
                    'data:image/jpg;base64,'
                  );
                  dispatch(slice.actions.takeSnapShotVideo(convertedPreview));
                }}
                hidden
                multiple
                type="file"
                accept="video/*"
              />
            </Button>
          </Box>
        </Grid>
      )}

      <Grid
        item
        md={videoURI || selectedVideo ? 4.7 : 5.2}
        sm={videoURI || selectedVideo ? 12 : 5}
        xs={12}
        mx={2.5}
        spacing={2}
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Text
          name="exercise.exerciseName"
          label={t('Exercise Name')}
          required
          isDisabledText={isDisabledText}
          width={{
            xs: '60vw',
            sm: '100%',
            md: '100%',
            lg: '100%',
            xl: '100%'
          }}
        />

        <>
          {isDisabledText ? (
            <React.Fragment>
              <Text
                name="exercise.bodyPart"
                label={t('Body Part')}
                required
                isDisabledText={isDisabledText}
                width={{
                  xs: '60vw',
                  sm: '100%',
                  md: '100%',
                  lg: '100%',
                  xl: '100%'
                }}
              />
              <Text
                name="exercise.movementCategory"
                label={t('Movement Category')}
                required
                isDisabledText={isDisabledText}
                width={{
                  xs: '60vw',
                  sm: '100%',
                  md: '100%',
                  lg: '100%',
                  xl: '100%'
                }}
              />
              <Text
                name="exercise.level"
                label={t('Level')}
                required
                isDisabledText={isDisabledText}
                width={{
                  xs: '60vw',
                  sm: '100%',
                  md: '100%',
                  lg: '100%',
                  xl: '100%'
                }}
              />
              <Text
                name="parameters.equipment"
                label={t('Equipment')}
                // defaultValue={String(parameters.equipment)}
                isDisabledText={isDisabledText}
                width={{
                  xs: '60vw',
                  sm: '100%',
                  md: '100%',
                  lg: '100%',
                  xl: '100%'
                }}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Select
                name="exercise.bodyPart"
                label={t('Body Part')}
                options={bodyPart}
                required
                width={{
                  xs: '70vw',
                  sm: '35vw',
                  md: 340,
                  lg: 340,
                  xl: 340
                }}
              />
              <Select
                name="exercise.movementCategory"
                label={t('Movement Category')}
                options={movementCategory}
                required
                width={{
                  xs: '70vw',
                  sm: '35vw',
                  md: 340,
                  lg: 340,
                  xl: 340
                }}
              />
              <Select
                name="exercise.level"
                label={t('Level')}
                options={level}
                required
                width={{
                  xs: '70vw',
                  sm: '35vw',
                  md: 340,
                  lg: 340,
                  xl: 340
                }}
              />
              <Select
                name="parameters.equipment"
                label={t('Equipment')}
                options={equipments}
                required
                width={{
                  xs: '100%',
                  sm: '100%',
                  md: 170,
                  lg: 170,
                  xl: 170
                }}
              />
            </React.Fragment>
          )}
        </>
        <Field name="exercise.databaseType">
          {({ field }) => (
            <RadioGroup
              {...field}
              row
              value={field.value ?? value}
              defaultChecked={field.value ?? value}
              sx={{
                mt: 1,
                my: -2
              }}
            >
              <FormControlLabel
                value="Public"
                control={<Radio />}
                disabled={isDisabledText}
                label={t('Public')}
              />
              <FormControlLabel
                value="Private"
                disabled={isDisabledText}
                control={<Radio />}
                label={t('Private')}
              />
            </RadioGroup>
          )}
        </Field>
      </Grid>
    </Grid>
  );
};

export default ExerciseOverviewTab;

const useStyles = makeStyles(() => ({
  gridContainer: {
    padding: 0
  },
  reactPlayer: {
    borderRadius: 10,
    height: '25vw'
  },
  selectedVideo: {
    display: 'flex',
    flexDirection: 'column'
  },
  itemsGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F2F6FF',
    border: '2px dashed rgba(23, 37, 86, 0.5)'
  },
  videoGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

import {
  MeasurementTypeKey,
  MeasurementTypeName,
  MeasurementTypeValueKey
} from '../models/measure/MeasurementType';
import { getMeasureUnitWeight } from './MeasureUnitCalculations';
// import { UserState } from "../infrastructure/user-state";
import { ObservationCode } from '../models/observation/enums/ObservationCode';
import {
  IBloodPressureCode,
  IBloodPressureInterpretationValue
} from '../models/graphic/IBloodPressureGraphic';
import {
  getBloodPressureInterpretation,
  getBodyTemperatureInterpretation,
  getBodyWeightInterpretation,
  getHeartRateInterpretation,
  getOxygenSaturationInterpretation,
  InterpretationResult
} from './Interpretation';
import {
  IHeartRateValue
  // IMoodValue
} from '../models/measure/IMeasureTypeValues';
// import { HeartRateResponse } from "react-native-google-fit";
import {
  Annotation,
  Device
} from '../services/middleware/types/aidbox/AidboxTypes';
import { ILastMeasuredInfo } from '../models/graphic/ILatestMeasures';
// eslint-disable-next-line import/no-cycle
// import { MeasureDataSourceId } from "../infrastructure/measure/IMeasureResource";
import { formatLastMeasurementDateWithoutAMPM } from './DateTimeFormatters';

export const getMeasurementTypeTitle = (
  measurementTypeKey: MeasurementTypeKey
): string => {
  switch (measurementTypeKey) {
    case MeasurementTypeKey.temperature:
      return MeasurementTypeName.temperature;
    case MeasurementTypeKey.ecgHeartRate:
    case MeasurementTypeKey.ecgHRV:
    case MeasurementTypeKey.ecg:
      return MeasurementTypeName.ecg;
    case MeasurementTypeKey.bodyWeight:
      return MeasurementTypeName.bodyWeight;
    case MeasurementTypeKey.bloodOxygen:
      return MeasurementTypeName.bloodOxygen;
    case MeasurementTypeKey.bloodPressure:
      return MeasurementTypeName.bloodPressure.title;
    case MeasurementTypeKey.bodyHeight:
      return MeasurementTypeName.bodyHeight;
    case MeasurementTypeKey.mood:
      return MeasurementTypeName.mood;
    case MeasurementTypeKey.burnedCalories:
      return MeasurementTypeName.burnedCalories;
    case MeasurementTypeKey.dailySteps:
      return MeasurementTypeName.dailySteps;
    case MeasurementTypeKey.bloodGlucose:
      return MeasurementTypeName.bloodGlucose;
    default:
      throw new Error('Unknown Measure Type Key');
  }
};

export const getMeasurementTypeValueKey = (
  measurementTypeKey: MeasurementTypeKey,
  value: string | null
): string => {
  if (value !== null) {
    switch (measurementTypeKey) {
      case MeasurementTypeKey.ecgHeartRate:
        return value + ' ' + MeasurementTypeValueKey.ecgHeartRate;
      case MeasurementTypeKey.ecgHRV:
        return value + ' ' + MeasurementTypeValueKey.ecgHrv;
      case MeasurementTypeKey.bloodGlucose:
        return value + ' ' + MeasurementTypeValueKey.bloodGlucose;
      case MeasurementTypeKey.bloodOxygen:
        return value + ' ' + MeasurementTypeValueKey.spO2;
      case MeasurementTypeKey.temperature:
        return value + ' ' + MeasurementTypeValueKey.temperature;
      case MeasurementTypeKey.bodyWeight:
        return getMeasureUnitWeight(
          // must be fixed
          'UserState.currentUser.measureUnit',
          Number(value)
        );
      case MeasurementTypeKey.bloodPressure:
        return value;
      case MeasurementTypeKey.bodyHeight:
        return value + ' ' + MeasurementTypeValueKey.bodyHeight;
      default:
        return '--';
    }
  } else {
    return '--';
  }
};

export const getMeasurementTypeKey = (
  code: ObservationCode
): MeasurementTypeKey => {
  switch (code) {
    case ObservationCode.BODY_TEMPERATURE:
      return MeasurementTypeKey.temperature;
    case ObservationCode.BLOOD_PRESSURE_HEART_RATE:
      return MeasurementTypeKey.bloodPressureHeartRate;
    case ObservationCode.DIASTOLIC_BLOOD_PRESSURE:
      return MeasurementTypeKey.bloodPressureDiastolic;
    case ObservationCode.SYSTOLIC_BLOOD_PRESSURE:
      return MeasurementTypeKey.bloodPressureSystolic;
    case ObservationCode.OXYGEN_SATURATION:
      return MeasurementTypeKey.bloodOxygen;
    case ObservationCode.ECG:
    case ObservationCode.RESPIRATORY_RATE:
    case ObservationCode.HEARTH_RATE_VARIABILITY:
      return MeasurementTypeKey.ecg;
    case ObservationCode.MOOD:
      return MeasurementTypeKey.mood;
    case ObservationCode.ECG_HEART_RATE:
      return MeasurementTypeKey.ecgHeartRate;
    case ObservationCode.BODY_HEIGHT:
      return MeasurementTypeKey.bodyHeight;
    case ObservationCode.BODY_WEIGHT:
      return MeasurementTypeKey.bodyWeight;
    case ObservationCode.BLOOD_GLUCOSE:
      return MeasurementTypeKey.bloodGlucose;
    case ObservationCode.GRATITUDE:
      return MeasurementTypeKey.gratitude;
    default:
      throw new Error('unknown observation code');
  }
};

export const getObservationCodeWithMeasureTypeKey = (
  measurementTypeKey: MeasurementTypeKey
): ObservationCode | IBloodPressureCode => {
  switch (measurementTypeKey) {
    case MeasurementTypeKey.temperature:
      return ObservationCode.BODY_TEMPERATURE;
    case MeasurementTypeKey.bloodGlucose:
      return ObservationCode.BLOOD_GLUCOSE;
    case MeasurementTypeKey.ecgHRV:
      return ObservationCode.HEARTH_RATE_VARIABILITY;
    case MeasurementTypeKey.mood:
      return ObservationCode.MOOD;
    case MeasurementTypeKey.bloodOxygen:
      return ObservationCode.OXYGEN_SATURATION;
    case MeasurementTypeKey.bodyWeight:
      return ObservationCode.BODY_WEIGHT;
    case MeasurementTypeKey.bodyHeight:
      return ObservationCode.BODY_HEIGHT;
    case MeasurementTypeKey.gratitude:
      return ObservationCode.GRATITUDE;
    case MeasurementTypeKey.bloodPressure:
      return {
        sys: ObservationCode.SYSTOLIC_BLOOD_PRESSURE,
        dias: ObservationCode.DIASTOLIC_BLOOD_PRESSURE,
        heartRate: ObservationCode.BLOOD_PRESSURE_HEART_RATE
      };
    default:
      throw new Error('unknown observation code');
  }
};

export const getMeasurementTypeValueUnit = (
  measurementTypeKey: MeasurementTypeKey
): MeasurementTypeValueKey => {
  switch (measurementTypeKey) {
    case MeasurementTypeKey.temperature:
      return MeasurementTypeValueKey.temperature;
    case MeasurementTypeKey.ecgHeartRate:
      return MeasurementTypeValueKey.ecgHeartRate;
    case MeasurementTypeKey.bloodGlucose:
      return MeasurementTypeValueKey.bloodGlucose;
    case MeasurementTypeKey.bloodOxygen:
      return MeasurementTypeValueKey.spO2;
    case MeasurementTypeKey.bodyWeight:
      return MeasurementTypeValueKey.bodyWeight;
    case MeasurementTypeKey.bodyHeight:
      return MeasurementTypeValueKey.bodyHeight;
    case MeasurementTypeKey.bloodPressure:
      return MeasurementTypeValueKey.bloodPressureDialostic;
    default:
      return '-' as MeasurementTypeValueKey;
  }
};

export const getMeasurementTypeInterpretation = (
  measurementTypeKey: MeasurementTypeKey,
  val: number | IBloodPressureInterpretationValue
): InterpretationResult | undefined => {
  switch (measurementTypeKey) {
    case MeasurementTypeKey.ecgHeartRate:
      return getHeartRateInterpretation(val as number);
    case MeasurementTypeKey.bloodOxygen:
      return getOxygenSaturationInterpretation(val as number);
    case MeasurementTypeKey.bloodPressure:
      return getBloodPressureInterpretation(
        (val as IBloodPressureInterpretationValue).sys,
        (val as IBloodPressureInterpretationValue).dias
      );
    case MeasurementTypeKey.temperature:
      return getBodyTemperatureInterpretation(val as number);
    case MeasurementTypeKey.bodyWeight:
      return getBodyWeightInterpretation(val as number);
    default:
      return undefined;
  }
};

// export const getMeasurementTypeClassificationInfo = (
//   measurementTypeKey: MeasurementTypeKey,
// ): IMeasureTypeClassificationInfo => {
//   switch (measurementTypeKey) {
//     case MeasurementTypeKey.bloodPressure:
//       return bloodPressureClassificationCards;
//     case MeasurementTypeKey.ecgHeartRate:
//       return heartRateClassificationInfo;
//     case MeasurementTypeKey.bodyWeight:
//       return bodyWeightClassificationInfo;
//     case MeasurementTypeKey.bloodOxygen:
//       return bloodOxygenClassificationInfo;
//     case MeasurementTypeKey.temperature:
//       return bodyTemperatureClassificationInfo;
//     default:
//       return bloodPressureClassificationCards;
//   }
// };

// export const getGraphicDataLastMeasuredInfo = (
//   data: IGraphicInformation | IGraphicInformation[] | undefined
// ): {
//   lastMeasuredValue: string | null;
//   lastMeasuredDateTime: string | null;
// } => {
//   if (!isMultipleGraphDataResponse(data)) {
//     if (data && data.latestMeasuredValue && data.latestMeasuredDateTime) {
//       return {
//         lastMeasuredValue: data.latestMeasuredValue.toString(),
//         lastMeasuredDateTime: data.latestMeasuredDateTime
//       };
//     }
//     return { lastMeasuredDateTime: null, lastMeasuredValue: null };
//   } else {
//     if (
//       data[0] &&
//       data[1] &&
//       data[2] &&
//       data[0].latestMeasuredValue &&
//       data[1].latestMeasuredValue &&
//       data[2].latestMeasuredValue
//     ) {
//       return {
//         lastMeasuredDateTime: data[0].latestMeasuredDateTime,
//         lastMeasuredValue: getBloodPressureCheckValue(
//           data[0].latestMeasuredValue,
//           data[1].latestMeasuredValue,
//           data[2].latestMeasuredValue
//         )
//       };
//     } else {
//       return { lastMeasuredDateTime: null, lastMeasuredValue: null };
//     }
//   }
// };

export const getHeartRateLastMeasuredInfo = (
  heartRate: IHeartRateValue
  // googleFitHeartRate?: HeartRateResponse,
): {
  lastMeasuredTime: string | null;
  lastMeasuredValue: number | null;
  lastMeasuredNote: Annotation[] | undefined;
  lastMeasuredDevice: Device;
} => {
  const heartRateSources: ILastMeasuredInfo[] = [
    {
      value: heartRate.heartRate,
      dateTime: heartRate.lastMeasuredTime,
      device: heartRate.device,
      note: heartRate.note
    }
  ];
  // if (googleFitHeartRate && googleFitHeartRate.value) {
  //   heartRateSources.push({
  //     value: googleFitHeartRate.value,
  //     dateTime: googleFitHeartRate.endDate,
  //     device: {
  //       id: MeasureDataSourceId.ACTIMI_GOOGLE_FIT_CLOUD.toString() as string,
  //       resourceType: "Device",
  //     },
  //     note: heartRate.note,
  //   });
  // }
  const sortedHeartRateSources = heartRateSources.sort(
    (a, b) =>
      new Date(Number(a.dateTime)).getTime() -
      new Date(Number(b.dateTime)).getTime()
  );
  return {
    lastMeasuredValue:
      sortedHeartRateSources[0].value === 0
        ? null
        : (sortedHeartRateSources[0].value as number),
    lastMeasuredTime: sortedHeartRateSources[0].dateTime,
    lastMeasuredNote: sortedHeartRateSources[0].note,
    lastMeasuredDevice: sortedHeartRateSources[0].device
  };
};

// export const getMoodTrackerLatestMeasurementValue = (
//   moodLatestMeasurement: IMoodValue
// ): string => {
//   let moodTrackerValueText = '';
//   if (moodLatestMeasurement.lastMeasuredTime !== null) {
//     moodTrackerValueText = moodTrackerValueText + moodLatestMeasurement.mood;
//   } else {
//     moodTrackerValueText = '--';
//     return moodTrackerValueText;
//   }
//   if (
//     moodLatestMeasurement.feelings !== undefined &&
//     moodLatestMeasurement.feelings[0]
//   ) {
//     moodTrackerValueText =
//       moodTrackerValueText + '$' + moodLatestMeasurement.feelings[0].name;
//   }
//   if (
//     moodLatestMeasurement.activities !== undefined &&
//     moodLatestMeasurement.activities[0]
//   ) {
//     moodTrackerValueText =
//       moodTrackerValueText + '$' + moodLatestMeasurement.activities[0].name;
//   }
//   return moodTrackerValueText;
// };

export const getBloodPressureCheckValue = (
  systolic: number,
  diastolic: number,
  heartRate: number
): string => {
  if (systolic !== 0 && diastolic !== 0 && heartRate !== 0) {
    return `${systolic}/${diastolic}/${heartRate}`;
  } else {
    return '--';
  }
};

export const getLatestMeasurementDateText = (
  lastMeasureDateTime: string | number | null
): string | undefined => {
  let resultText = ' ';
  if (lastMeasureDateTime !== null) {
    resultText =
      resultText + formatLastMeasurementDateWithoutAMPM(lastMeasureDateTime);
    return resultText;
  }
  return undefined;
};
